@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ModalSidebar {
  top: 0 !important;
  height: 100% !important;
  margin-top: 0 !important;
  margin: 0 auto !important;
  margin-right: 0 !important;
  transform: none !important;
  margin-bottom: 0 !important;
  max-width: toRem(380px) !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important;
  @include mobile {
    width: 100%;
    padding: toRem(30px 30px 0);
    height: auto !important;
    margin: 0 auto !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: red;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .modal {
    &-right {
      background-color: rgba(0, 0, 0, 0.5);
    }
    &-content {
      min-height: 100% !important;
      border: 0 !important;
      border-radius: 0 !important;
      @include mobile {
        margin-bottom: toRem(30px);
        overflow-y: auto !important;
        border-radius: $radius !important;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      height: $headerHeight;
      padding: 1.75rem !important;
      @include mobile {
        height: auto;
        padding: toRem(20px) !important;
      }
      .close {
        padding: 0.7rem 1rem !important;
        font-size: toRem(35px) !important;
        font-weight: 400 !important;
        outline: 0 !important;
        opacity: 0.5 !important;
        svg {
          width: toRem(12px);
        }
      }
    }
    &-title {
      font-size: toRem(18px);
      font-weight: 400;
      line-height: 1em;
    }
    &-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: toRem(30px);
      @include mobile {
        padding: toRem(20px);
      }
    }
    &-footer {
      display: grid;
      padding: toRem(30px);
      column-gap: toRem(10px);
      height: $headerHeight;
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      @include mobile {
        height: auto;
        padding: toRem(20px);
      }
      .btn {
        margin: 0;
        height: toRem(40px);
        padding: toRem(7px);
        min-width: toRem(146px);
        border: toRem(1px) solid;
        @include mobile {
          min-width: auto !important;
        }
        &-secondary {
          color: $labelColor;
          border-color: $labelColor;
          background-color: transparent;
        }
      }
    }
  }

  &--contact {
    max-width: toRem(700px) !important;
  }

  &--middle {
    top: toRem(100px) !important;
    padding: 1.875rem 1.875rem 0;
    height: auto !important;
    margin: 0 auto !important;

    textarea {
      height: toRem(90px) !important;
    }

    .modal-content {
      margin-bottom: toRem(30px);
      overflow-y: auto !important;
      border-radius: $radius !important;
    }

    .modal-header {
      height: unset;
    }
  }

  &--plans {
    max-width: toRem(1700px) !important;
    padding: 1.875rem 1.875rem 0;
    height: auto !important;
    margin: 0 auto !important;
    top: toRem(10px) !important;

    .modal-content {
      margin-bottom: toRem(30px);
      overflow-y: auto !important;
      border-radius: $radius !important;
    }

    .modal-header {
      border: none;
      background-color: #f8f8f8;
      height: toRem(5px);

      .btn-close svg {
        cursor: pointer;
        width: toRem(15px);
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .modal-body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      grid-gap: 3rem;
      background-color: #f8f8f8;
      min-height: toRem(970px);

      @include mobile-tablet {
        grid-template-columns: unset;
        place-content: center;
      }
    }
  }

  .Switch {
    .form-check {
      max-width: 100%;
    }
  }
}
