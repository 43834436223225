@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.Capabilities {
  @include popupContent;
  @include dynamicScroller;

  .UserInfoRowItem {
    margin-bottom: toRem(10px);
    svg {
      width: toRem(20px);
    }

    span {
      font-size: toRem(16px);
      padding-left: toRem(7px);
    }
  }

  .Seatings,
  .Equipment {
    color: #161616;
    font-size: toRem(15px);

    input {
      margin-right: toRem(7px);
      cursor: pointer;
    }
  }
  .Seatings {
    input[type="radio"] {
      opacity: 0;
      position: absolute;
    }

    label {
      @include iconPrefix("radio-default");
    }

    input[type="radio"]:checked + label {
      @include iconPrefix("radio-checked");
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: toRem(8px) !important;
    }
  }

  .Equipment {
    text-transform: capitalize;

    &__input {
      position: relative;
    }

    &__checkbox {
      position: absolute;
      left: 0;
      cursor: pointer;
      z-index: 1;
      width: toRem(18px);
      height: toRem(18px);
      background: url("../../../../assets/icons/checkbox-empty.svg") no-repeat
        center;
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked {
        background: url("../../../../assets/icons/checkbox-full.svg") !important;
      }
    }
  }
}
