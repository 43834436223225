@import "../../../lib/scss/mixins";

.DeleteButton {
  cursor: pointer;
  svg {
    width: toRem(20px);
    @include mobile-tablet {
      width: toRem(16px);
    }
  }
}
