@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.EmptyWorkplaces {
  width: 100%;
  padding: toRem(80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include mobile-tablet {
    padding: toRem(40px 20px);
  }
  h4 {
    font-weight: 300;
    text-align: center;
    line-height: 1.167em;
    font-size: toRem(48px);
    max-width: toRem(720px);
    margin-bottom: toRem(30px);
    @include mobile-tablet {
      font-size: toRem(30px);
    }
  }

  &__guide {
    background-color: $businessColor;
    color: #fff;
    padding: toRem(12px 25px);
    border-radius: toRem(35px);
    line-height: 1em;
    font-size: toRem(15.5px);

    span {
      vertical-align: middle;
      padding-left: toRem(5px);
    }

    &::before {
      content: "";
      display: inline-flex;
      width: toRem(20px);
      height: toRem(20px);
      vertical-align: middle;
      background: url("../../../assets/icons/package-icon.svg") no-repeat center;
    }

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: #104d57;
      border-color: #104d57;
    }
  }
}
