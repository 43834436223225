@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.TimeSelector {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-top: toRem(13px);
    margin-bottom: toRem(5px);
    padding: toRem(0 8px);

    &--head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: toRem(18px);

      span {
        font-weight: 300;
      }
    }

    &--body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      svg {
        width: toRem(17px);
        cursor: pointer;

        path {
          stroke: $mainColor;
        }
      }

      input {
        border: none;
        background-color: $borderColor;
        color: $proColor;
        border-radius: toRem(5px);
        padding: toRem(1px 7px 3px 7px);
        margin-top: toRem(15px);
        margin-bottom: toRem(15px);
        font-size: toRem(16px);
        pointer-events: none;
        user-select: none;

        &:focus {
          outline: none;
        }
      }

      &--group {
        display: flex;
        flex-direction: column;
        align-items: center;

        input[type="number"] {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &--colon {
      font-size: toRem(18px);
      color: $labelColor;
      margin-bottom: toRem(6px);
    }
  }
}
