@import "../../../../lib/scss/mixins";

.CalendarSingle {
  flex: 1 1;
  width: 100%;
  display: flex;
  margin-top: toRem(20px);
  position: relative;
  flex-direction: column;
  @include mobile-tablet {
    padding: toRem(0 15px);
  }
  &__header {
    margin-bottom: toRem(16px);

    &--title {
      margin-bottom: toRem(20px);

      svg {
        width: toRem(22px);
        margin-bottom: toRem(3.5px);
      }
    }
  }
  &__sync {
    display: flex;
    margin-left: auto;
    align-items: center;
    @include mobile-tablet {
      top: 0;
      right: 15px;
      position: absolute;
    }
    h4 {
      font-size: toRem(16px);
      line-height: 1em;
    }
    .InformationBox {
      margin-left: toRem(8px);
    }
    .sync-icon {
      cursor: pointer;
      margin-left: toRem(10px);
      svg {
        width: toRem(22px);
      }
    }

    .InformationBox {
      &__content {
        top: 100%;
        transform: translateY(-40%);

        &--left {
          &::before {
            top: -30%;

            @include mobile-tablet {
              top: -95%;
            }
          }
        }

        @include mobile-tablet {
          transform: translateY(-7%);
        }
      }
    }
  }
  h1 {
    font-size: toRem(24px);
    line-height: 1em;
    padding-left: toRem(7px);
    @include mobile-tablet {
      font-size: toRem(20px);
      padding-right: toRem(100px);
    }
  }
  .ResourceNavigation {
    padding-bottom: 0;
    border-bottom: toRem(3px) solid #ebebeb;
    &__link {
      display: flex;
      margin-bottom: 0;
      @include mobile-tablet {
        padding-bottom: toRem(10px);
        justify-content: center;
      }
    }
    @include mobile-tablet {
      border-bottom: none;
    }
  }
}
