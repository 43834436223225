@import "../../../../lib/scss/mixins";

.GSuiteConnectButton {
  display: flex;
  place-items: center;
  place-content: center;
  // width: 100%;
  // text-align: center;
  // button {
  //   width: toRem(226px);
  //   height: toRem(52px);
  //   border: none !important;
  //   // background: url("../../../../assets/images/btn_google_signin_dark_normal_web.png")
  //   // no-repeat center;
  //   transition: all 0.3s ease-in-out;
  //   background-color: none !important;
  //   &:hover {
  //     background-image: url("../../../../assets/images/btn_google_signin_dark_focus_web.png");
  //   }
  //   &:active {
  //     background-image: url("../../../../assets/images/btn_google_signin_dark_pressed_web.png");
  //   }
  // }
  // &--disabled {
  //   button {
  //     background-image: url("../../../../assets/images/btn_google_signin_dark_disabled_web.png") !important;
  //   }
  // }
}
