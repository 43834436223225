@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.LabelWithInformationBox {
  display: flex;
  position: relative;
  margin-bottom: toRem(5px);
  align-items: center;
  label {
    font-weight: 300;
    line-height: 1em;
    color: $labelColor;
    margin-top: 3px;
    margin-bottom: 0 !important;
  }
  .InformationBox {
    margin-left: toRem(4px);
    margin-top: toRem(-4px);
  }

  &__subTitle {
    label {
      font-size: toRem(16px);
    }
  }
}
