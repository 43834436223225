@import "../../lib/scss/mixins";

.DownloadQRCode {
  .Icon svg {
    width: toRem(17px);
    height: toRem(17px);
    margin-left: toRem(10px);
    cursor: pointer;
  }
}
