@import "../../../lib/scss/mixins";

.FloorMarkersSettings {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  column-gap: toRem(24px);
  width: fit-content;
  padding-bottom: toRem(10px);
}

