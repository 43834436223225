@import "../../lib/scss/mixins";

.BillingsMain {
  .ResourceNavigation {
    padding-bottom: toRem(27.4px);

    @include mobile-tablet {
      padding-bottom: 0;
    }

    // @include mobile {
    //   flex-wrap: wrap;

    //   &__link {
    //     &:nth-child(4) {
    //       padding-top: 1rem;
    //     }
    //   }
    // }
  }

  .LoadingBox {
    margin-top: toRem(35px);
  }

  @include small-desktop {
    padding-bottom: toRem(24px);
  }

  .default-card {
    max-width: toRem(450px);
  }

  .Products {
    .BillingInnerCard {
      min-height: toRem(155px);
    }
  }
}
