@import "../../../lib/scss/variables";

.InputDescription {
  margin-top: toRem(8px);

  h5 {
    font-size: toRem(10px);
    color: #e24a1a;
    font-weight: 300;
    line-height: 110%;
  }
  p {
    font-size: toRem(10px);
    font-weight: 300;
    line-height: 110%;
    color: $labelColor;
  }
  &--theme {
    h5 {
      color: $mainColor;
    }
  }
  &--labelTitle {
    h5 {
      color: $labelColor;
    }
  }

  &--warning {
    h5 {
      color: #e7853f;
    }
  }

  &--info {
    h5 {
      color: $secondary;
    }
  }

  &.custom-font {
    h5 {
      font-size: toRem(14px);
    }
  }

  &--adjustable {
    position: absolute;
    bottom: toRem(-25px);
    left: 0;
  }

  &--switch {
    display: flex;

    h5 {
      margin-left: auto;
    }

    &--left {
      h5 {
        margin-left: 0 !important;
        margin-bottom: toRem(10px);
      }
    }
  }
}
