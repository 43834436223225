@import "../../../../../lib/scss/variables";
@import "../../../../../lib/scss/mixins";

.WorkHours {
  top: toRem(90px);

  .modal-header {
    align-items: center;
    border-bottom: unset;
    padding: toRem(20px 28px);
    margin-top: toRem(10px);

    h5 {
      font-weight: 400 !important;
      line-height: 1em !important;
      font-size: toRem(18px) !important;
    }

    button {
      svg {
        width: toRem(12px);
      }
    }
  }

  .modal-content {
    border-radius: $radius !important;
    border: none;
  }

  &__minor {
    font-size: toRem(12.5px);
    line-height: toRem(15px);
    color: $labelColor;
    font-weight: 300;
  }

  .WorkingHoursForm {
    margin-bottom: toRem(20px);

    &__value {
      flex: 0 0 55%;
      margin-right: toRem(20px);
    }

    &__currency {
      flex: 0 0 20%;

      select {
        appearance: none;
      }

      &--arrow {
        &::before {
          content: "";
          width: toRem(13px);
          height: toRem(13px);
          background: url("../../../../../assets/icons/chevron-down-label.svg")
            no-repeat center;
          position: absolute;
          z-index: 9;
          right: 5%;
          top: 68%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__selectors {
      &--deskController {
        .form-check {
          max-width: toRem(400px);
        }
      }
    }
  }

  &__info {
    font-size: toRem(14.5px);
    line-height: toRem(17.5px);
    padding: toRem(10px 28px 0 28px);
    font-weight: 300;

    color: $labelColor;
  }

  &__form {
    &--label {
      font-size: toRem(15.5px);
      line-height: toRem(17.5px);
      color: $labelColor;
      font-weight: 300;
    }
  }

  .modal-footer {
    height: unset;
    justify-content: space-around;
  }

  input[type="text"] {
    padding: toRem(6px);

    -webkit-appearance: none;
  }

  .WorkingHours {
    padding: toRem(0 28px 28px 28px);

    .StartIcon {
      left: toRem(2px);

      svg {
        width: toRem(18px);
      }
    }
  }

  .LoadingBox {
    width: toRem(180px);
    margin-right: toRem(10px);
    display: block;
  }

  &.WorkspaceCostModal {
    max-width: toRem(650px) !important;
  }

  .WorkspaceCostInfo {
    .WorkspaceCostInfoRow {
      display: flex;
      // justify-content: space-around;
      margin-left: toRem(28px);
      // gap: toRem(5px);

      span {
        font-weight: 400;
        font-size: toRem(13px);
        line-height: toRem(16px);
        color: #808080;
        padding-bottom: toRem(8px);

        .Icon {
          margin-right: toRem(3px);
          display: unset;
          svg {
            width: toRem(20px);
            display: unset !important;
          }
        }

        &:first-child {
          min-width: toRem(220px);
        }

        &:nth-child(2) {
          min-width: toRem(100px);
          margin-left: toRem(15px);
          margin-right: toRem(15px);
        }
      }

      &:last-child {
        span {
          color: #000;
        }
      }
    }
  }

  &__workweek {
    .LabelWithInformationBox {
      margin-bottom: 0 !important;
      padding-top: toRem(4px);
    }
    .form-group label {
      margin-bottom: 0 !important;
    }
  }
}
