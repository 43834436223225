@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.WorkplacesTreeLevel {
  display: flex;
  margin-bottom: toRem(8px);
  flex-direction: column;
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: toRem(10px);
    svg {
      width: toRem(18px);
      height: toRem(18px);
      transition: $transition1;
      path {
        transition: $transition1;
      }
    }
  }
  &__link {
    display: flex;
    text-decoration: none !important;
    overflow: hidden;

    &:hover {
      .WorkplacesTreeLevel {
        &__name {
          color: $mainColor;
        }
        &__icon {
          svg {
            path {
              stroke: $mainColor;
            }
          }
          .zone-icon {
            svg {
              path {
                fill: $mainColor;
              }
            }
          }
          .wayFinder_icon_18x18 {
            svg {
              rect {
                stroke: $mainColor;
              }
            }
          }
        }
      }
    }
  }
  &__name {
    color: black;
    font-size: toRem(15px);
    line-height: 1em;
    font-weight: initial;
    transition: $transition1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__toggle {
    opacity: 0;
    width: toRem(7px);
    height: toRem(9px);
    min-width: toRem(7px);
    cursor: pointer;
    margin-right: toRem(5px);
    transition: $transition1;
    background: url("../../../../../assets/icons/chevron.svg") no-repeat center;
    background-size: cover;
  }
  &__sub-level {
    display: none;
    padding-left: toRem(17px);
    .WorkplacesTreeLevel {
      &__name {
        color: #808080;
      }
      &__icon {
        svg {
          transition: $transition1;
          path {
            stroke: #808080;
            transition: $transition1;
          }
        }
        .zone-icon {
          svg {
            path {
              fill: #808080;
            }
          }
        }
        .wayFinder_icon_18x18 {
          svg {
            rect {
              stroke: #808080;
            }
          }
        }
      }
    }
    &--open {
      display: block;
    }
  }
  &--active {
    .WorkplacesTreeLevel {
      &__icon {
        svg {
          path {
            stroke: $mainColor;
          }
        }
        .zone-icon {
          svg {
            path {
              fill: $mainColor;
            }
          }
        }
        .wayFinder_icon_18x18 {
          svg {
            rect {
              stroke: $mainColor;
            }
          }
        }
      }
      &__name {
        color: $mainColor;
      }
    }
  }
  &--open {
    .WorkplacesTreeLevel {
      &__toggle {
        transform: rotate(90deg);
      }
    }
  }
  &--space {
    margin-left: toRem(12px);
  }
  .AddWorkplaceButton {
    opacity: 0;
    transition: $transition1;
  }

  .AddWorkplaceButton--open {
    opacity: 1;
  }

  &:hover {
    .AddWorkplaceButton {
      opacity: 1;
    }
  }
}

.PageSidebar {
  &:hover {
    .WorkplacesTreeLevel {
      &__toggle {
        opacity: 1;
      }
    }
  }
}
