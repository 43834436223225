@import "../../../../lib/scss/mixins";

.LocationPath {
  &.LocationPath__multiple {
    max-width: toRem(300px);
    text-align: center;

    span {
      &:not(:first-child) {
        &::before {
          height: 10px !important;
        }
        @include iconPrefix("arrow-right");
        margin: toRem(0 5px);
      }
    }
  }

  &.LocationPath__leftArrow {
    span {
      &:not(:first-child) {
        @include iconPrefix("arrow-left-basic");
      }
    }
  }
}
