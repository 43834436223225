@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.EnableMFAOption {
  padding-bottom: toRem(40px);

  .Status {
    color: white;
    min-width: unset;
    font-size: toRem(10px);
    padding: toRem(1px 6px);
    border: none;

    &--active {
      background-color: $mainColor;
    }

    &--inactive {
      background-color: $errorColor;
    }

    &--sso {
      background-color: #e7853f !important;
    }
  }

  &__name {
    line-height: 1em;
    font-weight: 300;
    color: grey;
    font-size: toRem(14px);
  }

  &__info {
    display: block;
    margin: toRem(10px 0);
    color: gray;
    font-weight: 300;
  }

  label {
    margin-bottom: 0 !important;
  }
}
