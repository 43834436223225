@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ModalMessage {
  font-weight: 300;
  margin-bottom: 0;
  text-align: center;
  color: $labelColor;
  font-size: toRem(16px);
  margin-top: toRem(30px);
  line-height: toRem(26px);
}
