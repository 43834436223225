@import "../../../lib/scss/mixins";

.RegisterConfirmation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  h2 {
    color: black;
    font-size: toRem(24px);
    line-height: 1em;
    font-weight: 300;
    margin-bottom: toRem(13px);

    @include mobile-tablet {
      margin-top: toRem(20px);
    }
  }
  p {
    color: black;
    font-size: toRem(16px);
    font-weight: 300;
    margin-bottom: toRem(15px);
    line-height: 1.188em;
  }
}
