@import "../../../lib/scss/mixins";

.PromotionCard {
  background-color: #fff;
  border-radius: toRem(10px);
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: toRem(40px 10px);
  margin-top: toRem(20px);
  min-width: toRem(300px);
  max-width: toRem(330px);
  max-height: toRem(630px);

  @include mobile {
    place-self: center;
    margin-bottom: toRem(20px);
  }

  &__phone {
    width: toRem(170px);
  }

  &__mobileApp {
    width: toRem(160px);
  }

  &__qrCode {
    width: toRem(110px);
    margin-right: toRem(20px);
  }

  h6 {
    padding: (20px 0);
    max-width: toRem(200px);
    margin: 0 auto;
  }

  &__store {
    img {
      width: toRem(80px);
    }
  }

  &__qr {
    img {
      width: toRem(65px);
    }
  }

  &__qr,
  &__store {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: toRem(20px);
  }
}
