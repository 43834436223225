$mq-small: 767px;
$d-small: 1024px;
$d-main: 1200px;
$d-main-custom: 1250px;
$d-medium: 1400px;
$d-medium-custom: 1500px;
$d-large: 1600px;

/**
Mixin for including styles for mobile
*/
@mixin mobile {
  @media (max-width: $mq-small) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin mobile-tablet {
  @media (max-width: $d-main) {
    @content;
  }
}

@mixin mobile-tablet-custom {
  @media (max-width: $d-main-custom) {
    @content;
  }
}

@mixin default-laptop {
  @media (min-width: $d-main) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $mq-small) and (max-width: $d-main) {
    @content;
  }
}

@mixin tablet-big {
  @media (min-width: $d-small) and (max-width: $d-main) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin small-desktop {
  @media (max-width: $d-small) {
    @content;
  }
}

@mixin medium-desktop {
  @media (max-width: $d-medium) {
    @content;
  }
}

/**
This is for Insights only:
*/
@mixin small-insights {
  @media (max-width: 1099px) {
    @content;
  }
}

/**
Mixin for including styles for tablet
*/
@mixin small-desktop-custom {
  @media (max-width: $d-medium-custom) {
    @content;
  }
}

@mixin large-desktop-custom {
  @media (min-width: $d-medium-custom) {
    @content;
  }
}

/**
Mixin for including styles for desktop
*/
@mixin desktop {
  @media (min-width: $d-small) {
    @content;
  }
}

/**
Mixin for including styles for retina type screens
*/
@mixin small-retina {
  @media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @content;
  }
}

@mixin mobile-retina {
  @media screen and (min-width: 400px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @content;
  }
}

/**
Mixin for including styles for non retina type screens
*/
@mixin non-retina {
  @media screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

/**
Mixin for including styles for large desktop
*/
@mixin large-desktop {
  @media (min-width: $d-large) {
    @content;
  }
}

@mixin bookings-search-customized {
  @media (max-width: 1650px) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin popupContent {
  margin-top: toRem(2px);
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: toRem(10px);
  box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
  padding: toRem(16px);
  position: absolute;
  z-index: 1;
  width: 80%;
  max-height: toRem(350px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: toRem(5px);
}

@mixin childContent {
  position: absolute;
  background: #fff;
  padding: toRem(10px);
  z-index: 9;
  box-shadow: toRem(0px 10px 8px) rgb(0 0 0 / 10%);
  border-radius: toRem(6px);
}

@mixin mainHeader {
  font-size: toRem(24px);
  margin-bottom: toRem(7px);
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;

  @include mobile-tablet {
    padding-right: toRem(50px);
  }
}

@mixin dynamicScroller {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &:hover {
    ::-webkit-scrollbar {
      width: toRem(5px);
      &:hover {
        background: #eee;
        border-radius: toRem(6px);
      }
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d7d7d7;
      border-radius: toRem(6px);
      &:hover {
        background-color: #999999;
      }
    }
  }

  @-moz-document url-prefix() {
    scrollbar-width: none;

    &:hover {
      scrollbar-width: thin;
    }

    * {
      scrollbar-width: none !important;

      &:hover {
        scrollbar-width: thin !important;
      }
    }
  }
}

@mixin circleBorder {
  height: toRem(30px);
  width: toRem(30px);
  display: flex;
  align-items: center;
  place-content: center;
}

@mixin availableRoomDesk {
  border-radius: toRem(10px);
  border: 1px solid $mainColor;
  // box-shadow: inset 0 0 0 2px $mainColor, 0 2px 2px 0 rgba(0, 0, 0, 0.08);

  @media (max-width: 1450px) and (min-width: 1200px) {
    padding-top: toRem(40px);
  }

  .workplace_icon {
    svg {
      background: rgba(44, 208, 175, 0.3);
    }
  }

  .Reserve__button {
    background-color: $mainColor !important;
    color: #fff !important;
  }

  .actions {
    .actions-icon {
      display: block;
      position: absolute;
      right: toRem(20px);

      @media (max-width: 1450px) and (min-width: 1200px) {
        right: toRem(9px);
      }

      @include mobile-tablet {
        top: toRem(15px);

        svg {
          width: toRem(5.5px) !important;
        }
      }
    }

    @media (max-width: 1450px) {
      // margin-bottom: toRem(55px);
      position: absolute;
      right: 0;
      top: toRem(8px);
      display: block;

      .Reserve__button {
        margin-right: toRem(32px);

        @media (max-width: 1400px) and (min-width: 1200px) {
          margin-right: toRem(32px);
        }
      }
    }

    @include mobile-tablet {
      position: static;
      margin: 0 auto;

      .DropdownList {
        top: toRem(40px);
      }

      .Reserve__button {
        margin-right: 0;
      }
    }
  }

  @include mobile-tablet {
    .seats {
      right: toRem(40px);
    }
    .bookingsTags {
      left: 0;
      transform: unset;
    }
    .equipment {
      right: unset;
      max-width: 100% !important;
      width: 100% !important;
      overflow: visible;

      span {
        max-width: 100% !important;
        width: 100% !important;
        overflow: visible;
        white-space: break-spaces;
      }
    }
    .nextReservation {
      margin-bottom: toRem(8px);
    }
    .nextReservation,
    .bookingsTags,
    .equipment {
      position: initial;
    }
  }
}

@mixin timePicker {
  .react-datepicker__input-container {
    position: relative;
    max-width: toRem(200px);

    input {
      padding: toRem(6px);
    }

    &::after {
      content: "";
      width: toRem(16px);
      height: toRem(16px);
      background: url("../../assets/icons/chevron-down-label.svg") no-repeat
        center;
      position: absolute;
      top: toRem(10px);
      right: toRem(55px);
    }
  }
}

@mixin iconPrefix($iconName, $width: 16px, $height: 16px) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: "";
    width: $width;
    height: $height;
    margin-top: -2px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/icons/#{$iconName}.svg") no-repeat center;
    background-size: contain;
  }
}

@mixin iconAfterPrefix($iconName) {
  &:after {
    content: "";
    width: toRem(20px);
    height: toRem(20px);
    margin-right: toRem(10px);
    position: absolute;
    left: toRem(30px);
    top: 55%;
    transform: translate(-50%, -50%);
    background: url("../../assets/icons/#{$iconName}.svg") no-repeat center;
  }
}

@mixin iconBeforePrefix($iconName) {
  &::before {
    content: "";
    width: toRem(16px);
    height: toRem(16px);
    margin-right: toRem(10px);
    background: url("../../assets/icons/#{$iconName}.svg") no-repeat center;
    position: absolute;
    z-index: 9;
    right: toRem(4px);
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

@mixin adjustableButton {
  top: 0;
  right: 0;
  position: absolute !important;

  .btn {
    padding: 0;
    width: toRem(38px);
    height: toRem(38px);
    min-width: auto;
    position: relative;
    border-radius: 100%;
    text-indent: -9999px;
    min-height: unset;
    &:before {
      top: 50%;
      left: 50%;
      content: "";
      width: toRem(17px);
      height: toRem(17px);
      position: absolute;
      display: inline-block;
      transform: translate(-50%, -50%);
      background: url("../../assets/icons/plus-icon.svg") no-repeat center;
      background-size: contain;
    }
  }

  span {
    display: none;
  }

  &--icon {
    .btn {
      min-height: auto;
      padding: 0 !important;
      &::before {
        display: none;
      }
    }
    .Icon {
      left: auto;
      margin-right: 0;
      position: relative;
      svg {
        width: toRem(13px);
      }
    }
  }
}

@mixin fullWidthTwoButtons {
  display: flex;
  margin: toRem(0 25px);
  justify-content: space-between;
  padding: 0.75rem 0;
  gap: 4rem;
  flex-wrap: nowrap;
  place-content: center;

  button {
    flex: 0 1 50%;
    max-width: unset;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    gap: 0.2rem;
  }
}

$root-font-size: 14px;

@media only screen and (max-width: 1500px) {
  $root-font-size: 16px !global;
}

@function toRem($values) {
  $rem-values: ();

  @each $value in $values {
    @if $value==0 or $value==0px {
      $rem-values: join($rem-values, 0);
    } @else if
      type-of($value)
      ==number and not
      unitless($value) and
      (unit($value) ==px)
    {
      $new-rem-value: calc($value / $root-font-size);
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else {
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  @return $rem-values;
}
