@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.FormSubSection {
  display: flex;
  flex-direction: column;
  &--heading {
    margin: toRem(15px 0);
    & > .LabelWithInformationBox {
      margin-bottom: toRem(3px);
      label {
        font-size: toRem(15px);
      }
    }
    .FormSubSection {
      &__content {
        display: flex;
        margin-top: 0;
        flex-wrap: wrap;
        padding: toRem(20px);
        border-radius: $radius;
        column-gap: toRem(20px);
        border: toRem(1px) solid $borderColor;
        @include mobile {
          flex-direction: column;
        }
        .form-group {
          &.flex {
            flex: 1;
            @include mobile {
              width: 100%;
            }
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
  &--column {
    .FormSubSection {
      &__content {
        .form-group {
          width: 100%;
          &.flex {
            flex: 1 1;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
}
