@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

[role="tooltip"].popup-content {
  background-color: #fff;
  box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05) !important;
  border-radius: toRem(11px) !important;
  padding: toRem(20px);
  border: 1px solid #ebebeb;
  min-width: 200px;
  width: fit-content !important;
}

.PopupHeader {
  padding-bottom: toRem(28px);

  &__title {
    color: #000;
    font-size: toRem(18px);
    font-weight: normal;
    padding-bottom: toRem(3px);
  }

  &__description {
    color: $secondary;
    font-weight: normal;
    font-size: toRem(13px);
    display: block;
    max-width: 95%;
  }
}

.PopupCloseButton {
  cursor: pointer;
  position: absolute;
  top: toRem(26px);
  right: toRem(20px);

  svg {
    width: toRem(13px) !important;
  }
}
