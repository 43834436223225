@import "../../../../lib/scss/mixins";

.InsightsFor {
  margin-top: toRem(30px);
  padding-bottom: toRem(10px);
  gap: toRem(10px);
  flex-wrap: wrap;

  @include mobile {
    padding-bottom: toRem(30px);
  }

  &__title {
    font-weight: 400;
    font-size: toRem(18px);
    line-height: toRem(25px);
    border: 1px solid #000;
    border-radius: toRem(10px);
    padding: toRem(5px 12px);

    @include mobile {
      font-size: toRem(16px);
    }
  }

  .InsightsLocationInfo {
    span {
      color: #808080;
      font-size: toRem(18px);
      font-weight: 400;
      padding-left: toRem(10px);
      word-break: break-word;

      @include mobile {
        font-size: toRem(16px);
      }
    }

    .desk_icon_18x18 {
      svg {
        width: toRem(23px);
        path {
          stroke: #808080;
        }
      }
    }
  }

  .location-reserve,
  .arrow-right {
    svg {
      path {
        fill: #808080;
      }
    }
  }

  .location-reserve {
    svg {
      width: toRem(17px);
    }
  }

  .rooms-multiple {
    svg {
      width: toRem(22px);
    }
  }

  svg {
    width: toRem(20px);
  }
}
