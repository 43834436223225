@import "../../../../lib/scss/mixins";

.CustomerSubscriptionsTable {
  .StructureTable {
    &__head {
      .next_renewal {
        max-width: toRem(90px);
      }

      .status {
        max-width: toRem(150px);
        margin-left: toRem(25px);
      }

      .InformationBox__content {
        text-align: start;

        p {
          color: #000;
        }
      }
    }

    &__content {
      .list-row {
        align-items: flex-start;
      }
      .next_renewal {
        max-width: toRem(90px);

        @include mobile-tablet {
          @include iconPrefix("triangle-grey");
        }
      }

      .customer_info {
        width: 70% !important;

        @include mobile-tablet {
          @include iconPrefix("company-icon");
        }
      }

      .subscription_plan {
        @include mobile-tablet {
          @include iconPrefix("billing-icon");
        }
      }

      .subscription_items {
        @include mobile-tablet {
          @include iconPrefix("dashboard-icon");
        }
      }

      .subscription_items {
        @include mobile-tablet {
          flex-direction: column;
          align-items: flex-start;
        }

        .SubscriptionItemsCount {
          display: flex;
          justify-content: space-between;
          min-width: toRem(110px);
        }
      }

      .status {
        max-width: toRem(110px);
        align-self: center;
        place-items: center;
        margin-right: toRem(65px);

        .SubscriptionStatus {
          width: auto;
          max-width: toRem(105px);
          text-align: center;
          padding: toRem(4.5px 20px);
          font-size: toRem(14px);
          margin-left: 0;
        }

        @include mobile-tablet {
          top: 15px;
          right: 15px;
          margin-bottom: 0;
          position: absolute;
          width: auto !important;
          margin-right: 0;
        }
      }
    }
  }
}
