@import "../../../lib/scss/mixins";

.Beta {
  background-color: #01ec96;
  font-size: toRem(12px);
  font-weight: 400;
  letter-spacing: 0.05em;
  border-radius: toRem(30px);
  padding: toRem(2px 18px);
  color: #000;
  margin-bottom: toRem(7px);
  display: inline-flex;
}
