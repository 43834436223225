@import "../../../../lib/scss/mixins";

.WorkspacePlatformMessage {
  max-width: toRem(300px);

  &__primary {
    h4 {
      color: #2cd0af !important;
    }
  }
  h4 {
    font-size: toRem(29px);
    color: #fff;
    font-weight: 400;
    margin-bottom: toRem(8px);
  }

  span {
    color: #fff;
    font-size: toRem(19px);
    font-weight: 300;
  }
}
