@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.TagFilter {
  @include popupContent;
  @include dynamicScroller;
  padding: toRem(24px 22px);
  justify-content: flex-start !important;

  &__head {
    svg {
      width: toRem(20px);

      path {
        fill: #000;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: toRem(12px);
    place-content: center;
  }

  &__item {
    color: $mainColor;
    border: 1px solid $mainColor;
    border-radius: toRem(30px);
    padding: toRem(2px 15px);
    font-size: toRem(13px);
    cursor: pointer;

    svg {
      width: toRem(14px);
      margin-right: toRem(5px);

      path {
        fill: $mainColor;
      }
    }

    &.active {
      background-color: $mainColor;
      color: #fff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
