@import "../../../../lib/scss/mixins";

.BookingsButtonsModal {
  .modal-header {
    border-bottom: unset;
  }

  &__info {
    display: flex;
    grid-gap: toRem(10px);
    border-bottom: 1px solid #808080;
    padding-bottom: toRem(18px);

    svg {
      background-color: #f8f8f8;
      border-radius: toRem(6px);
      padding: toRem(6.5px);
      width: toRem(35px) !important;
    }
  }

  .SearchWorkplaceForm {
    .form-group {
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }

  .BookingsReserveLoader {
    &__group {
      span {
        max-width: unset;
        display: flex;
        grid-gap: toRem(16px);
      }
    }
  }
}
