@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.DateRangeButton {
  &__content {
    position: absolute;
    margin-top: toRem(5px);
    padding: toRem(20px);
    width: toRem(265px);
    max-width: toRem(265px);

    background-color: #fff;
    border-radius: toRem(10px);
    z-index: 10;
    box-shadow: toRem(2px 5px 20px) rgba(0, 0, 0, 0.3);

    &--option {
      padding-bottom: toRem(15px);
      font-weight: 400 !important;
      font-size: toRem(16px);
      line-height: toRem(19px);

      &:hover {
        color: $mainColor;
      }
    }

    &--picker {
      label {
        margin-bottom: unset !important;
      }

      input {
        width: 100%;
        border: 1px solid #ebebeb;
        cursor: pointer;
        color: #adaeb9;
        padding: toRem(5px);
      }
    }
  }

  &__dialog {
    margin-top: toRem(18px);
    justify-content: space-around;

    Button {
      min-height: unset;
      min-width: toRem(55px);
      line-height: 0;
      span {
        padding: unset !important;
        font-size: toRem(10px);
      }
    }
  }

  &__active {
    color: $mainColor !important;
  }

  .react-datepicker__month-dropdown-container--scroll {
    margin-right: toRem(30px);
  }
}
