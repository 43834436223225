@import "../../../lib/scss/mixins";

.Reservations {
  &__content {
    display: flex;
    justify-content: space-between;

    &--reservations {
      flex: 70%;
    }

    @include mobile {
      flex-direction: column;
    }
    @include tablet {
      flex-direction: column;
    }
  }

  .StructureHeader__title h1 {
    font-weight: 400;
  }
  &__day {
    font-size: toRem(18px);
    font-weight: 400;
    margin-top: toRem(20px);
  }
}
