@import "../../../../lib/scss/mixins";

.CalendarsMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include mobile-tablet {
    padding: toRem(0 15px);
  }

  .PageHeader {
    @include desktop {
      display: none;
    }
  }
}
