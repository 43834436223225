@import "../../../../lib/scss/mixins";

.CalendarProviders {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 87%;
}

.Integrations {
  padding-left: 0;
  margin-top: toRem(13px);
  margin-bottom: toRem(7px);
}
