@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.OrganizationSettigs {
  &__root {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: toRem(22px);

    @include mobile-tablet {
      grid-template-columns: 1fr 1fr;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__cardContent {
    flex: 1;
    border: 1px solid $borderColor;
    padding: toRem(18px) toRem(25px);

    &_clickable {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.01);
        transition: $transition1;
      }
    }

    h3 {
      font-size: toRem(14px);
      line-height: 125%;
      color: $labelColor;
      font-weight: 300;
    }

    .Icon {
      margin-right: toRem(15px);
    }

    svg {
      width: toRem(30px);
    }

    .bookings-icon {
      svg {
        path {
          stroke: #808080;
        }
      }
    }
  }

  &__workHours {
    display: flex;
    align-items: center;
    column-gap: toRem(30px);

    @include mobile-tablet {
      row-gap: toRem(30px);
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__insignts {
    display: flex;
    align-items: center;
    column-gap: toRem(30px);
  }

  &__timeZone {
    display: flex;
    align-items: center;
  }

  &__debugInfo {
    position: relative;
    display: flex;
    align-items: center;
  }
}
