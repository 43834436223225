@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.RadioButton {
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;

    font: inherit;
    color: red;
    width: toRem(18px);
    height: toRem(18px);
    border: 2px solid $mainColor;
    border-radius: 50%;
    background-color: white !important;
    cursor: pointer;

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: toRem(9px);
    height: toRem(9px);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $mainColor;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  .LabelWithInformationBox {
    margin-bottom: 0;
  }
}
