@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.Switch {
  margin: toRem(5px 0);
  flex: 1 1 0px;
  display: flex;
  padding-left: 0;
  flex-direction: column;
  transition: $transition1;
  &__input {
    width: toRem(58px);
    height: toRem(27px);
    padding: toRem(3px);
    display: flex;
    overflow: hidden;
    margin-left: auto;
    position: relative;
    border-radius: toRem(36px);
    transition: $transition1;
    border: toRem(1px) solid $labelColor;
    .form-check-input {
      top: 0;
      left: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
    }
  }
  &__toggle {
    width: toRem(19px);
    height: 100%;
    margin-left: 0;
    max-width: toRem(19px);
    max-height: toRem(19px);
    border-radius: 100%;
    transform: translateX(0);
    transition: $transition1;
    background-color: $labelColor;
  }
  &__content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    border-radius: $radius;
    transition: $transition1;
    border: toRem(1px) solid $borderColor;
  }
  &__border {
    border-radius: $radius;
    transition: $transition1;
    border: toRem(1px) solid $borderColor;
    padding: toRem(10px 20px);
  }
  .form-check {
    max-width: toRem(300px);
    align-items: center;
    display: flex !important;
    padding-left: 0 !important;
  }
  .form-check-label {
    max-width: 58%;
    font-size: toRem(14px);
    font-weight: 300;
    line-height: 125%;
    color: $labelColor;
  }
  .LabelWithInformationBox {
    margin-bottom: 0;
  }
  &--selected {
    & > .form-check {
      & > .Switch {
        &__input {
          border-color: $mainColor;
          & > .Switch__toggle {
            background-color: $mainColor;
            transform: translateX(calc(100% + #{toRem(12px)}));
          }
        }
      }
    }
    .Switch {
      &__content {
        opacity: 1;
        padding: toRem(20px);
        overflow: unset;
        margin-top: toRem(20px);
        margin-bottom: toRem(5px);
        max-height: toRem(9999px);
      }
    }
  }
  &--left-aligned {
    .Switch {
      &__input {
        margin-left: 0;
      }
    }
  }
  &--disabled {
    opacity: 0.6;
  }
}
