@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.SidebarMainMenuItem {
  outline: none;
  cursor: pointer;
  padding: toRem(21px 0px);
  position: relative;
  border-bottom: toRem(1px) solid #f8f8f8;
  a {
    text-decoration: none !important;
    &:before {
      left: 0;
      opacity: 0;
      content: "";
      top: toRem(15px);
      width: toRem(6px);
      position: absolute;
      background: $mainColor;
      transition: $transition1;
      border-radius: toRem(4px);
      height: calc(100% - #{toRem(30px)});
      @include mobile {
        width: toRem(4px);
      }
    }
  }
  svg {
    rect,
    circle,
    path {
      stroke: #808080;
      transition: $transition1;
    }
  }
  svg,
  & > a {
    transition: $transition1;
  }
  &:hover:not(.SidebarMainMenuItem--disabled),
  .active {
    .SidebarMainMenuItem__title,
    & > a {
      color: $mainColor;
    }
    &:before {
      opacity: 1;
    }
    svg {
      rect,
      circle,
      path {
        stroke: $mainColor !important;
      }
    }
  }

  &--disabled {
    opacity: 0.6;
    &:after {
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      content: "";
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
    }
    cursor: unset !important;
  }

  &__title {
    color: #808080;
    text-align: center;
    font-size: 1rem !important;
    transition: all 0.3s ease-in-out;
    @include mobile-tablet {
      font-size: toRem(13px) !important;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: toRem(6px);
    .Icon {
      height: auto;
      width: toRem(30px);
      @include mobile {
        width: toRem(26px);
      }
    }
  }
  @include mobile {
    padding: toRem(18px 0px);
  }
}
