@import "../../../../lib/scss/mixins";

.CropImage {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 25;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  &__image {
    width: 100%;
    height: 90%;
    position: relative;
  }
  &__slider {
    flex: 1;
    width: 100%;
    position: relative;
    padding: toRem(10px 10px 0 10px);
  }
}
