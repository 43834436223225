@import "../../../lib/scss/mixins";

.FloorMapControlButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  border: none;
  width: toRem(30px);
  height: toRem(32px);
  background-color: #fff;
  box-shadow: toRem(0px 4px 10px) rgba(0, 0, 0, 0.1);
  border-radius: toRem(4px);
  z-index: 800;

  .Icon {
    display: flex;
    width: toRem(19px);
  }
}
