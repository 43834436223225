.SharedPieChart {
  width: 50%;
  height: 98%;
  position: relative;

  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
  }
}
