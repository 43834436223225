@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.Popup {
  &__head {
    border-bottom: 0.5px solid $proColor;
    margin-bottom: toRem(26px);
    padding-bottom: toRem(4px);
  }

  &__name {
    color: #000 !important;
    font-size: toRem(16px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: toRem(30px);
  }

  &__location {
    font-weight: 400;
    color: #808080;
  }

  &__user {
    color: $proColor;
    place-content: center;

    span {
      text-align: center;
    }

    svg {
      width: toRem(13px) !important;
      path {
        stroke: $proColor;
      }
    }
  }

  &__settings {
    justify-content: center;
    padding: toRem(5.5px 0px);
    border: 1px solid $successColor;
    border-radius: toRem(20px);
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    span {
      color: $successColor;
      cursor: pointer;
      padding-left: toRem(8px);
      font-size: toRem(16px);
    }

    svg {
      cursor: pointer;
      width: toRem(15px) !important;

      path {
        fill: $successColor;
      }
    }
  }
  svg {
    width: toRem(12px);
  }
}
