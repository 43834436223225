@import "../../../lib/scss/mixins";

.HomeCard {
  display: flex;
  flex-direction: column;
  &--disabled {
    filter: blur(1px);
    -webkit-filter: blur(1px);
  }
  &__icon {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: toRem(70px);
    justify-content: center;
    margin-bottom: toRem(24px);
    @include mobile-tablet {
      min-height: auto;
      margin-bottom: toRem(15px);
    }
    svg {
      max-width: toRem(70px);
      max-height: toRem(70px);
      @include mobile-tablet {
        max-width: toRem(50px);
        max-height: toRem(50px);
      }
    }
  }
  &__title {
    display: flex;
    line-height: 1em;
    font-weight: 400;
    align-items: center;
    font-size: toRem(24px);
    margin-bottom: toRem(11px);
    @include mobile-tablet {
      font-size: toRem(18px);
    }
    a {
      color: inherit !important;
      text-decoration: none !important;
    }
  }

  &__divider {
    width: toRem(1px);
    height: toRem(65px);
    position: absolute;
    background-color: #ebebeb;
    top: 55%;
    left: 50%;

    @include mobile-tablet {
      height: toRem(50px);
      top: 52%;
    }
  }
}
