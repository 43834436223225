@import "../../lib/scss/mixins";
@import "../../lib/scss/variables";

.Bookings {
  .BookingsSearch {
    &__layout {
      display: grid;
      grid-template-columns: toRem(470px) 1fr;
      grid-gap: toRem(60px);

      @include mobile-tablet {
        grid-template-columns: unset;
        margin-bottom: toRem(20px);
      }

      &--search {
        &--rooms {
          max-height: toRem(1100px);
        }

        &--desks {
          max-height: toRem(900px);
        }
      }

      &--pagination {
        place-content: center;
        &--back {
          display: none;
        }
        @include mobile {
          justify-content: space-between;
          &--back {
            display: inline-block;
          }
        }
      }

      .TimeSelector {
        span {
          padding-bottom: toRem(4px);
        }
      }
    }

    &__workplaces {
      .StructureTable {
        &__head {
          background-color: $secondary;
          border-radius: toRem(10px);
          padding-top: toRem(8px);
          padding-bottom: toRem(4px);
          margin-bottom: toRem(16px) !important;
          align-items: center;

          @include mobile-tablet {
            display: none;
          }

          &__item {
            color: #fff;

            span {
              padding-top: toRem(3px);
            }

            &.workplace_icon {
              max-width: toRem(25px);
            }

            &.name {
              flex: none;
              width: 22%;
              svg {
                width: toRem(22px);
              }
            }
            &.nextReservation {
              flex: none;
              width: 17.5%;
              margin-right: toRem(16px);
              svg {
                width: toRem(20px);
              }

              span {
                text-align: start;
              }
            }
            &.description {
              svg {
                width: toRem(12px);
                path {
                  fill: #fff;
                }
              }
            }
            &.seats {
              flex: none;
              width: 8%;

              svg {
                width: toRem(12px);
              }

              @include medium-desktop {
                width: 12%;
              }
            }
            &.bookingsTags {
              svg {
                width: toRem(18px);
              }
            }
            &.equipment {
              svg {
                width: toRem(17px);
              }
            }
          }

          @media (max-width: 1450px) {
            .actions {
              display: none;
            }
          }
        }

        &__content {
          @include mobile-tablet {
            grid-template-columns: 1fr;
          }
          .StructureTable {
            &__item {
              padding: toRem(16px);
              border-radius: toRem(10px);
              border: 1px solid $borderColor;

              @include mobile-tablet {
                flex-direction: column;
                position: relative;
                align-items: flex-start;
              }
              &__column {
                // @include bookings-search-customized {
                //   flex: unset !important;
                //   margin-bottom: 0.625rem;
                //   width: auto !important;
                //   max-width: none !important;
                // }
                &.name {
                  flex: none;
                  width: 23%;
                  overflow: hidden;

                  @include mobile-tablet {
                    position: absolute;
                    left: toRem(45px);
                    top: toRem(21px);
                    padding-left: toRem(5px);
                    overflow: visible;
                  }
                }

                &.workplace_icon {
                  max-width: toRem(25px);

                  svg {
                    width: toRem(32px);
                    background-color: #f8f8f8;
                    border-radius: toRem(6px);
                    padding: toRem(7px);
                    margin-left: toRem(5px);

                    @include mobile-tablet {
                      margin-left: 0;
                    }

                    // @include bookings-search-customized {
                    //   display: none;
                    // }
                  }
                }
                h4 {
                  color: #000;
                  font-weight: 400;
                  font-size: toRem(15px);
                  padding-left: toRem(16px);
                  margin-right: toRem(12px);
                }

                &.seats,
                &.description {
                  color: $secondary;
                }

                &.equipment,
                &.bookingsTags {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: $secondary;

                  span {
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    text-overflow: ellipsis;
                    width: toRem(120px);
                    max-width: toRem(120px);
                    line-height: toRem(18px);

                    // @include bookings-search-customized {
                    //   width: toRem(220px);
                    //   max-width: toRem(220px);
                    // }
                  }

                  div {
                    transition: all 0.3s ease-in;
                    visibility: hidden;
                    position: absolute;
                    background: #fff;
                    padding: toRem(16px);
                    border-radius: toRem(12px);
                    box-shadow: 0px 0px 27px rgb(0 0 0 / 10%);
                  }

                  &:hover div {
                    visibility: visible;
                    z-index: 1;
                  }
                }

                &.nextReservation {
                  flex: none;
                  width: 17%;
                  margin-right: toRem(16px);
                  color: $secondary;
                }

                &.seats {
                  flex: none;
                  width: 8%;

                  span {
                    white-space: nowrap;
                  }

                  @include medium-desktop {
                    width: 11%;
                  }
                }

                &.bookingsTags {
                  @include medium-desktop {
                    width: 25%;
                  }
                }

                @include mobile-tablet {
                  h4 {
                    font-size: toRem(17px);
                    padding-left: toRem(2.5px);
                  }
                  &.nextReservation {
                    @include iconPrefix("next-reservation-mobile", 21px, 21px);
                    margin-bottom: 0;

                    span {
                      padding-bottom: toRem(4px);
                    }

                    @media (max-width: 500px) {
                      margin-bottom: toRem(8px);
                    }
                  }

                  &.description {
                    @include iconPrefix("description-search-mobile");
                  }

                  &.seats {
                    position: absolute;
                    right: toRem(15px);

                    @include iconPrefix("seats-mobile", 22px, 22px);
                  }
                  &.bookingsTags {
                    @include iconPrefix("tags-mobile", 21px, 21px);
                    max-width: toRem(220px) !important;
                    position: absolute;
                    bottom: toRem(9px);
                    left: 50%;
                    transform: translateX(-50%);

                    span {
                      padding-bottom: toRem(2px);
                    }

                    @media (max-width: 500px) {
                      position: initial;
                      left: 0;
                      transform: unset;
                    }
                  }
                  &.equipment {
                    @include iconPrefix("equipment-mobile", 22px, 22px);

                    max-width: toRem(220px) !important;
                    padding-bottom: toRem(16px);
                    position: absolute;
                    bottom: toRem(6px);
                    padding-bottom: 0;
                    right: toRem(15px);

                    span {
                      padding-bottom: toRem(4px);
                    }

                    @media (max-width: 500px) {
                      position: initial;
                    }
                  }
                  &.assignedUser {
                    @include iconPrefix("people-icon");
                  }
                }
                &.actions {
                  align-items: self-end;

                  .btn-primary {
                    &:hover {
                      border-color: $mainColor;
                    }
                  }
                  .actions-icon {
                    display: none;
                  }

                  .DropdownList {
                    min-width: toRem(210px);
                    padding: toRem(12px 0);

                    li:not(:last-child) {
                      border-bottom: 1px solid $borderColor;
                      margin-bottom: toRem(5px);
                    }

                    li {
                      margin-left: toRem(20px);
                      margin-right: toRem(20px);
                      padding-left: 0;
                      width: unset;
                      h5 {
                        font-weight: 400;
                      }

                      &:hover {
                        h5 {
                          color: $mainColor;
                        }

                        .reserve-icon {
                          svg {
                            path {
                              fill: $mainColor;
                            }
                          }
                        }
                        .open-map-icon {
                          svg {
                            path {
                              stroke: $mainColor;
                            }
                          }
                        }
                      }
                    }
                  }

                  @media (max-width: 1450px) {
                    margin-right: toRem(10px);
                    display: none;
                  }
                }
              }

              &.clicked-row {
                @include availableRoomDesk;
              }
              //desktop devices only
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  @include availableRoomDesk;
                }
              }
            }
          }
          .Reserve__button {
            background-color: #fff !important;
            color: $mainColor !important;
            min-width: toRem(135px);
            padding: toRem(6px 3px) !important;
            margin-right: toRem(27px);
            border-width: 2px;
          }
          .actions-icon {
            svg {
              cursor: pointer;
              width: toRem(5px);
            }
          }
        }

        &--desks {
          .StructureTable {
            &__item {
              .bookingsTags {
                span {
                  max-width: toRem(300px);
                  width: toRem(300px);
                  line-height: toRem(18px);
                }
              }
              .actions {
                flex: none;
              }

              @include mobile-tablet {
                .bookingsTags {
                  max-width: toRem(320px) !important;
                  left: 61%;
                }
              }
            }
          }
        }
      }
    }

    &__loading {
      height: unset !important;
      margin-bottom: toRem(5px);
    }
  }

  .PageSidebar {
    @include mobile-tablet {
      z-index: 9999 !important;
    }
  }

  .PageSidebar__mobile_toggler {
    @include desktop {
      display: flex;
    }
  }

  &__empty {
    line-height: toRem(18px);
  }
}
