@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.DeviceForm {
  &__info {
    border-bottom: 1px solid #ebebeb;
    font-size: toRem(13px);
    font-weight: 400;
    padding-bottom: toRem(10px);
    margin: toRem(16px 0 10px 0);
  }

  .NameAndValueRow {
    span {
      color: #000;
    }
    color: #808080;
    font-size: toRem(14px);
    line-height: toRem(18px);
    padding: toRem(7px 0 7px 0);
    line-height: 1em;
    font-weight: 300;
  }

  &__debug {
    margin-top: toRem(10px);
    border-top: 1px solid #ebebeb;

    &.btn-small {
      font-size: toRem(8px);
      min-width: toRem(100px);
    }
  }

  .DebugDevice {
    cursor: pointer;

    span {
      color: $mainColor;
    }

    svg {
      width: toRem(20px);

      path {
        fill: $mainColor;
      }
    }
  }

  .DebugInfo {
    bottom: toRem(35px);

    @include mobile {
      left: 1%;
      transform: translateX(-5%);
    }

    &::before {
      left: 10%;
    }

    .InputDescription {
      p {
        margin-bottom: 0;
      }
    }
  }

  &__autoUpdate {
    .form-check {
      display: initial !important;

      .Switch__input {
        margin-left: 0;
        margin-top: toRem(5px);
      }
    }
  }
}
