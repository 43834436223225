@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.Status {
  width: auto;
  min-width: toRem(80px);
  color: $labelColor;
  border: toRem(1px) solid;
  box-sizing: border-box;
  padding: toRem(1px 10px);
  border-radius: toRem(45px);
  text-align: center;
  &--active {
    color: $mainColor;
  }
  &--inactive {
    color: $errorColor;
  }
}
