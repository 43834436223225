@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CenteredBox {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  &__modal {
    width: toRem(400px);
    max-width: 100%;
    border-radius: $radius;
    background-color: white;
  }

  &__disableHeight {
    min-height: unset;
  }
}
