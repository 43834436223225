@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.IntegrationsItem {
  cursor: pointer;
  margin-bottom: toRem(12px);
  color: #3a3a3a !important;
  &:last-child {
    margin-bottom: toRem(5px);
  }
  svg {
    width: toRem(16px);
    margin-right: toRem(10px);
  }
  &__header {
    display: flex;
    margin-bottom: toRem(12px);
    align-items: center;
  }
  &__title {
    font-size: toRem(16px);
    line-height: 1em;
    margin-bottom: toRem(-5px);
    text-transform: capitalize !important;
  }
  &__calendars {
    padding-left: toRem(29px);
  }
  &__link {
    font-size: toRem(16px);
    line-height: 1em;
    font-weight: 300;
    color: $labelColor;
    padding-left: toRem(22px);
    position: relative;
    transition: $transition1;
    text-decoration: none !important;
    svg {
      left: 0;
      top: toRem(-3px);
      width: toRem(7px);
      content: "";
      height: toRem(14px);
      position: absolute;
      path {
        transition: $transition1;
      }
    }
    &--error {
      color: $errorColor !important;
    }
    &:not(:last-child) {
      margin-bottom: toRem(14px);
    }
    &.active,
    &:hover {
      color: $mainColor;
      svg {
        path {
          stroke: $mainColor;
        }
      }
    }
  }
  &__enter {
    &--error {
      svg {
        path {
          stroke: $errorColor !important;
        }
      }
    }
  }
}
