@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.SettingsProfiles {
  .ManageProfiles {
    background-color: white;
    border: 1px solid $borderColor;
    border-radius: toRem(10px);
    padding: toRem(20px);
    margin-top: toRem(10px);

    &__content {
      .BillingInnerCard {
        border: 1px solid $borderColor;
        border-radius: toRem(10px);
        padding: 0;
        max-width: 60%;
        padding-right: toRem(20px);
        margin-top: toRem(10px);

        &__content {
          margin-bottom: 0;
        }

        @include mobile-tablet {
          max-width: 100%;
        }
        @include mobile {
          padding-right: 0;
          padding-bottom: toRem(10px);
        }
      }
    }

    img {
      width: toRem(175px);
      height: toRem(125px);
      border-top-left-radius: toRem(10px);
      border-bottom-left-radius: toRem(10px);

      @include mobile {
        border-top-left-radius: toRem(10px);
        border-top-right-radius: toRem(10px);
        border-bottom-left-radius: 0;
        width: 100%;
        height: toRem(160px);
      }
    }
  }

  .SettingsProfilesContent {
    max-width: 70%;

    @include small-desktop {
      margin-bottom: toRem(32px);
    }

    @include small-desktop-custom {
      max-width: 100%;
    }
  }
}
