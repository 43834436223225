@import "../../../lib/scss/mixins";

.Contacts {
  &__loading {
    span {
      .LoadingBox {
        min-height: toRem(40px) !important;

        @include small-desktop {
          min-height: toRem(275px) !important;
        }
      }
      margin-top: toRem(15px);
      row-gap: toRem(15px);
      display: grid;
      column-gap: toRem(20px);
      grid-template-columns: 1fr;
      @include small-desktop {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mobile {
        grid-template-columns: 1fr;
      }
    }
  }
}
