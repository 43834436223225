@import "../../../../../lib/scss/mixins";

.UtilizationOccupancy {
  .SharedPieChart {
    width: unset;
    height: 65%;
  }

  &__info {
    position: absolute;
    bottom: toRem(10px);
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: toRem(16px);
    line-height: toRem(25px);
    color: #808080;
  }

  .UtilizationOccupancyByDayOfWeek {
    &__values {
      position: absolute;
      top: toRem(90px);
      right: toRem(23px);

      div {
        color: #808080;
        font-weight: 400;
      }

      &--5 {
        div {
          margin-bottom: toRem(20px);
        }
      }

      &--7 {
        top: toRem(85px);

        @include mobile-tablet {
          top: toRem(80px);
        }

        div {
          margin-bottom: toRem(8px);

          @include mobile-tablet {
            margin-bottom: toRem(10px);
          }
        }
      }
    }
  }

  .UtilizationOccupancyMostLeast {
    &__values {
      position: absolute;

      div {
        color: #808080;
        font-weight: 400;
      }
      &--6 {
        top: toRem(102px);
        right: toRem(23px);

        div {
          margin-bottom: toRem(14px);
        }
      }
      &--5 {
        top: toRem(110px);
        right: toRem(23px);

        div {
          margin-bottom: toRem(20px);
        }
      }
      &--4 {
        top: toRem(120px);
        right: toRem(23px);

        div {
          margin-bottom: toRem(27px);
        }
      }
      &--3 {
        top: toRem(130px);
        right: toRem(23px);

        div {
          margin-bottom: toRem(40px);
        }
      }
      &--2 {
        top: toRem(155px);
        right: toRem(23px);

        div {
          margin-bottom: toRem(60px);
        }
      }
      &--1 {
        top: toRem(195px);
        right: toRem(23px);
      }
    }
  }

  .UtilizationOccupancyRoomSeating {
    &__values {
      fill: #808080;
    }

    &__info {
      font-size: toRem(13px);
      color: #adaeb9;
      width: 100%;
      display: flex;
      align-items: center;
      place-content: center;
    }
  }

  .RealizedMeetingsFunnel {
    position: absolute;
    top: 88%;
    left: 47%;
    transform: translateX(-50%);

    &__legend {
      width: toRem(18px);
      height: toRem(18px);
      border-radius: 50%;
      margin-left: toRem(25px);

      &--Released {
        background-color: #2cd0af;
      }
      &--Booked {
        background-color: #156c79;
      }
      &--Reclaimed {
        background-color: #00ec97;
      }
    }

    &__name {
      margin-left: toRem(10px);
      font-size: toRem(13px);
    }
  }

  .RoomMeetingBehavior {
    &__value {
      top: 53%;
      left: 51%;
    }
  }
}
