@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.InsightsExpandedRooms {
  .StructureTable {
    &__head {
      .room_name,
      .desk_name {
        flex-grow: 1.5;
      }
      @include mobile-tablet {
        display: none;
      }
    }
    &__item {
      .room_name,
      .desk_name {
        flex-grow: 1.5;

        h6 {
          color: $mainColor;
        }
      }
      @include mobile-tablet {
        flex-direction: column;
        align-items: flex-start;

        .room_name,
        .desk_name {
          padding-bottom: toRem(10px);
          h6 {
            font-size: toRem(18px);
          }
        }

        .room_name {
          @include iconPrefix("rooms-icon");
        }

        .desk_name {
          @include iconPrefix("desk_icon_18x18");
        }

        .room_meetings,
        .avg_daily_meetings,
        .avg_meetings_duration,
        .occupancy,
        .no_shows,
        .desk_reservations,
        .avg_daily_reservations,
        .avg_reservations_duration {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          &::before {
            margin-top: toRem(-4px);
            margin-right: toRem(15px);
            display: inline-block;
          }
        }

        //roms:
        .room_meetings {
          &:before {
            content: "Meetings:";
          }
        }

        .avg_daily_meetings {
          &:before {
            content: "Avg. Daily Meetings:";
          }
        }

        .avg_meetings_duration {
          &:before {
            content: "Avg. Meeting Duration (min):";
          }
        }

        .occupancy {
          &:before {
            content: "Occupancy:";
          }
        }

        .no_shows {
          &:before {
            content: "No-shows:";
          }
        }

        //desks:
        .desk_reservations {
          &:before {
            content: "Reservations:";
          }
        }

        .avg_daily_reservations {
          &:before {
            content: "Avg. daily reservations:";
          }
        }

        .avg_reservations_duration {
          &:before {
            content: "Avg. reservation duration (min):";
          }
        }
      }
    }
  }
}
