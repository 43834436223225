@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ProfilesList {
  background-color: white;
  border: 1px solid $borderColor;
  border-radius: toRem(10px);
  padding: toRem(20px);

  h5 {
    font-weight: 400;
  }

  &__subTitle {
    font-size: toRem(14px);
    font-weight: 400;
    color: $secondary;
    display: block;
    margin: toRem(10px 0);
  }

  .StructureTable {
    &__head {
      display: none;
    }
    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: toRem(20px);

      @include mobile {
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      }

      @include small-desktop {
        margin-bottom: toRem(25px);
      }
      // @include mobile-tablet {
      //   grid-template-columns: unset;
      // }

      .list-row {
        flex-direction: column;
        align-items: flex-start;
        min-width: toRem(250px);
        border: 2px solid $borderColor;
      }

      .icon {
        .room {
          @include iconPrefix("roomTemplate");
        }

        .desk {
          @include iconPrefix("deskTemplate");
        }

        .room,
        .desk {
          &::before {
            width: toRem(35px);
            height: toRem(35px);
          }
        }

        .template {
          svg {
            width: toRem(35px);
          }
        }
      }

      .title {
        h6 {
          color: $mainColor;
          font-weight: 400;
          font-size: toRem(18px);
        }
        .desk {
          color: #6b32d5;
        }
      }

      .type {
        font-size: toRem(14px);
        font-weight: 400;
        color: $secondary;
      }
      .description {
        @include iconPrefix("description-icon");

        span {
          font-size: toRem(14px);
          font-weight: 400;
        }
      }
      .actions {
        place-self: end;
        position: absolute;
      }

      .addTemplate {
        svg {
          width: toRem(35px);
        }

        h6 {
          color: $secondary;
          font-weight: 400;
          font-size: toRem(18px);
        }
      }
    }

    .actions-icon {
      svg {
        width: toRem(4.5px);
      }
    }
  }

  .AddNewTemplate {
    display: flex;
    flex-direction: column;
    place-items: center;
    border: 1.5px solid $secondary;
    border-radius: toRem(10px);

    h3 {
      color: $secondary;
      font-weight: 400;
    }

    svg {
      width: toRem(40px);
    }
  }

  .ProfileTable {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
    grid-gap: toRem(20px);

    @include mobile {
      grid-gap: toRem(12px);
      // grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    }

    @include small-desktop {
      margin-bottom: toRem(25px);
    }

    &__item {
      min-width: toRem(250px);
      border: 2px solid $borderColor;
      border-radius: toRem(10px);
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      width: 100%;
      display: flex;
      cursor: pointer;
      background-color: white;
      padding: 1.25rem 1.875rem;
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.05);

      h6 {
        color: $mainColor;
        font-weight: 400;
        font-size: toRem(18px);
      }
      .desk {
        color: #6b32d5;
      }

      span {
        font-size: toRem(14px);
        font-weight: 400;
        color: $secondary;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ActionList {
        align-self: end;
        position: absolute;

        ul {
          min-width: toRem(220px);
        }

        svg {
          width: toRem(4px);
        }

        li {
          width: 80%;
          margin: 0 auto;
          // padding-left: 0;
          padding: toRem(8px 0 8px 0);

          &:not(:last-child) {
            border-bottom: 1px solid $borderColor;
          }

          h5 {
            font-size: toRem(16px);

            @include mobile-tablet {
              font-size: toRem(14px);
            }
          }

          svg {
            width: toRem(20px);
          }

          .edit-icon {
            svg {
              width: toRem(17px);

              path {
                fill: #808080;
              }
            }
          }
        }
      }
      svg {
        width: toRem(42px);
      }

      &.template {
        display: flex;
        flex-direction: column;
        place-content: center;
        place-items: center;
        gap: toRem(13px);
        border-color: #808080;
        border-width: 1px;

        h6 {
          color: $secondary;
        }

        .DropdownList {
          width: 100%;

          svg {
            width: toRem(22px);

            path {
              stroke: #808080;
            }
          }

          li {
            width: 80%;
            margin: 0 auto;
            padding: toRem(8px 0 8px 0);

            &:first-child {
              border-bottom: 1px solid $borderColor;
            }

            h5 {
              font-size: toRem(16px);

              @include mobile-tablet {
                font-size: toRem(14px);
              }
            }
          }

          &:hover {
            h6,
            svg {
              opacity: 1;
            }
          }
        }

        &:hover {
          h6,
          svg {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
