@import "../../../../lib/scss/mixins";

.EmptyContacts {
  width: 100%;
  padding: toRem(80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .InvitePeopleButton {
    position: relative;
    top: unset;
  }

  @include mobile-tablet {
    padding: toRem(40px 20px);
  }
  h4 {
    font-weight: 300;
    text-align: center;
    line-height: 1.167em;
    font-size: toRem(48px);
    max-width: toRem(720px);
    margin-bottom: toRem(30px);
    @include mobile-tablet {
      font-size: toRem(30px);
    }
  }
}
