@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ReserveCard {
  background-color: #fff;
  max-width: toRem(430px);
  height: 100%;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
  border-radius: toRem(10px);
  padding: toRem(25px);

  @include small-desktop {
    margin-top: toRem(30px);
  }

  @include mobile {
    // max-width: toRem(375px);
    width: 100%;
    place-self: center;
  }

  &__main {
    height: 80vh !important;

    @media (max-height: 450px) {
      overflow-y: scroll;
    }

    // @include mobile {
    //   // max-width: toRem(335px);
    //   width: 100%;
    //   max-width: unset;
    //   place-self: center;
    // }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
    position: relative;
  }

  .ReserveFooter {
    display: flex;
    justify-content: space-around;
    grid-gap: toRem(32px);
    flex-wrap: wrap;

    margin-top: toRem(10px);

    span {
      font-size: toRem(17px);
      font-weight: 400;
      line-height: toRem(29px);
      max-width: toRem(290px);
      word-break: break-word;
    }
  }

  .PageHeader {
    padding-bottom: toRem(22px);
    margin-top: unset;
    justify-content: flex-start;

    .StructureHeader {
      margin-top: 0;

      &__title {
        padding-bottom: 0;
      }
    }

    h1 {
      font-weight: 400;
      padding-left: toRem(8px);

      @include mobile {
        font-size: toRem(23px);
      }
    }

    svg {
      background-color: #f8f8f8;
      border-radius: toRem(6px);
      padding: toRem(6.5px);
      width: toRem(35px) !important;
    }
  }
}
