@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.UploadImage {
  &__modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto !important;
    .filepond {
      &--root {
        margin-bottom: 0;
      }
      &--wrapper {
        transition: $transition1;
      }
      &--credits {
        display: none !important;
      }
      &--panel-root {
        border-radius: 0;
      }
    }
    .modal-content {
      overflow: hidden;
      border-radius: toRem(6px);
    }
    &--crop {
      .UploadImage {
        &__content {
          height: toRem(300px);
        }
      }
      .filepond {
        &--wrapper {
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }
  &__header {
    padding: toRem(15px);
    background-color: $mainColor;
    h3 {
      color: white;
      font-size: toRem(20px);
      font-weight: 300;
      line-height: 1em;
    }

    svg {
      width: toRem(12px);
      cursor: pointer;
      height: auto;
      path {
        fill: #fff;
      }
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__field {
    width: toRem(80px);
    height: toRem(80px);
    display: flex;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    &::after {
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      content: "";
      width: 100%;
      height: 100%;
      visibility: hidden;
      position: absolute;
      transition: $transition1;
      background: url("../../../assets/images/edit-pencil-icon.svg") no-repeat
        center rgba(0, 0, 0, 0.6);
      background-size: toRem(30px);
    }
    &:hover {
      &::after {
        z-index: 5;
        opacity: 1;
        visibility: visible;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--empty {
      position: relative;
      border: toRem(1px) dashed $labelColor;
      &:before {
        top: 50%;
        left: 50%;
        content: "";
        width: toRem(30px);
        height: toRem(30px);
        position: absolute;
        transform: translate(-50%, -50%);
        background: url("../../../assets/images/camera.svg") no-repeat center;
      }
      img {
        display: none;
      }
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    padding: toRem(10px);
    justify-content: center;
  }
}
