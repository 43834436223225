@import "../../../lib/scss/mixins";

.ContactPasswordProcessed {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: toRem(60px);
  .Icon {
    margin-bottom: toRem(13px);
  }
  svg {
    width: toRem(62px);
  }
  h4 {
    line-height: 1em;
    font-weight: 400;
    font-size: toRem(24px);
    margin-bottom: toRem(45px);
  }
  h5 {
    text-align: center;
    font-weight: 400;
    line-height: 1.625em;
    font-size: toRem(16px);
    max-width: toRem(350px);
    margin-bottom: toRem(90px);
  }
  .btn {
    width: 100%;
  }
}
