@import "../../../lib/scss/mixins";

.tags {
  display: flex;
  flex-wrap: wrap;
  min-height: toRem(38px);
  height: auto !important;
  overflow: hidden !important;
  border: toRem(1px) solid #ebebeb;
  background-clip: padding-box;
  padding: toRem(6px 0 5px 6px) !important;
  &__item {
    font-size: toRem(14px);
    color: #495057;
    margin-bottom: 0;
    margin-right: toRem(2px);
    margin-bottom: toRem(2px);
    border-radius: toRem(15px);
    white-space: nowrap;
    background: #01ec96;
    display: inline-block;
    padding: toRem(1px 7px 1px 10px);
    span {
      cursor: pointer;
      margin-top: toRem(-1px);
      margin-left: toRem(5px);
      vertical-align: top;
      display: inline-block;
      &:after {
        content: "x";
        padding: toRem(0px 2px);
      }
    }
  }
  &__input.form-control {
    flex: 1;
    margin: 0;
    float: left;
    border: none;
    height: auto;
    min-width: auto;
    max-width: inherit;
    border: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
