@import "../../../lib/scss/mixins";

.SidebarDifferential {
  width: 0;
  height: 100%;
  display: none;
  padding-left: 0;
  transition: all 0.3s ease-in-out;
  @include desktop {
    display: block;
    padding-left: toRem(120px);
  }
  &--active_submenu {
    padding-left: toRem(380px);
  }
}
