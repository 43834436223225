@import "../../../lib/scss/mixins";

.TagList {
  width: auto !important;
  overflow-y: auto;
  overflow-x: hidden;

  .TagListContent {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__buttons {
      border-bottom: 1px solid #ebebeb;
      padding-bottom: toRem(10px);
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: toRem(5px);
      margin-top: toRem(15px);
    }
  }
}
