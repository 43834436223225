@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.PageSidebar {
  flex: 1;
  left: 100%;
  position: fixed;
  min-width: toRem(250px);
  max-width: toRem(280px);
  transition: $transition1;
  padding: toRem(25px 20px);
  background-color: #ffffff;
  border-left: toRem(1px) solid #ddd;
  height: calc(100% - #{toRem(40px)});
  max-height: calc(100vh - 10.5rem);
  z-index: 9;
  box-shadow: 0 0 toRem(27px) rgba(0, 0, 0, 0.1);

  @include dynamicScroller;

  &--mainWorkpalces {
    @include tablet-big {
      max-height: calc(100vh - 7.5rem);
    }
  }

  @include small-desktop {
    max-height: calc(100dvh - 4.5rem);
    z-index: 10;
  }
  &--default {
    .PageSidebarHeader {
      margin-bottom: toRem(25px);
      margin-top: toRem(5px);
    }
  }
  &__mobile_toggler {
    top: calc(
      #{toRem(140px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );
    width: toRem(25px);
    left: -25px;
    height: toRem(50px);
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    border-right: none;
    flex-direction: column;
    justify-content: center;
    border: toRem(1px) solid #ddd;
    border-right: none;
    background-color: white;
    border-top-left-radius: toRem(10px);
    border-bottom-left-radius: toRem(10px);
    @include desktop {
      display: none;
    }
    &--show {
      @include desktop {
        display: flex !important;
      }
    }
    span {
      width: toRem(9px);
      height: toRem(9px);
      border-radius: 100%;
      background-color: #ddd;
      &:not(:last-child) {
        margin-bottom: toRem(3px);
      }
    }
  }
  &__items {
    width: 100%;
    height: 100%;
    // overflow-y: scroll;
    // overflow-x: hidden;
    @include small-desktop {
      // overflow-y: scroll;
      padding-bottom: toRem(20px);
    }

    .FloorMapPicker {
      justify-content: space-evenly;
      padding-top: toRem(10px);
      button {
        font-size: toRem(11px) !important;
        min-width: toRem(70px);
      }
    }
  }

  &--toggled {
    transform: translateX(-100%);
  }

  &--middle {
    transform: translate(-100%, -20%);

    @include small-retina {
      transform: translate(-100%, -29%);
    }

    @include mobile-retina {
      transform: translate(-100%, -33%);
    }
  }

  &--floors {
    @include default-laptop {
      max-height: calc(100vh - 7.5rem);
    }
  }

  &--Wayfinder {
    min-width: toRem(350px) !important;
    max-width: toRem(350px) !important;

    .WayFinderWorkspaceFooterPicker {
      border-top: 1px solid #ebebeb;
      margin-top: toRem(10px);

      &__buttons {
        @include mobile-tablet {
          flex-direction: column;
          gap: toRem(10px);
        }
      }
    }

    .WorkplacesTreeLevel {
      &__status {
        margin-left: auto;
        margin-right: toRem(10px);
        svg {
          cursor: pointer;
          width: toRem(18px);
        }
      }
    }

    &--items {
      max-height: 62%;
      min-height: 62%;
      overflow-y: auto;
      overflow-x: hidden;

      @include mobile-tablet {
        max-height: 52%;
        min-height: 52%;
      }
    }

    &--desc {
      font-weight: 400;
      font-size: toRem(14px);
      color: #808080;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: toRem(20px);
      padding-bottom: toRem(15px);
    }
    &--closer {
      position: absolute;
      top: toRem(20px);
      right: toRem(20px);
      cursor: pointer;

      svg {
        width: toRem(12px);
      }
    }

    .PageSidebar__items {
      @include mobile-tablet {
        padding-bottom: unset !important;
      }
    }
  }

  &--Custom {
    // overflow: scroll;
    top: calc(
      #{toRem(120px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );

    max-height: calc(100dvh - 7.5rem);

    @include mobile-tablet {
      top: calc(
        #{toRem(81px)} + (var(--providers-count) + var(--directory-count)) * 56px
      );

      max-height: calc(100dvh - 4.5rem);
    }

    @media (max-height: 500px) {
      overflow-y: scroll;
    }
  }

  &--fixedHeight {
    .workspace__items {
      // height: 95%;
      height: calc(
        95% - (var(--providers-count) + var(--directory-count)) * 56px
      );
    }
  }

  &--desktop {
    @include tablet-big {
      margin-top: toRem(20px) !important;
    }
    @include desktop {
      width: auto;
      border: none;
      transform: none;
      position: relative;
      left: auto !important;
      border-radius: $radius;
      margin-top: toRem(20px);
      margin-right: toRem(80px);
    }
  }

  @include mobile-tablet {
    margin: 0;
    width: toRem(250px);
  }
}
