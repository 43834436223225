@import "../../../lib/scss/mixins";

.InformationBox {
  cursor: pointer;
  position: relative;

  &__top {
    transform: translateY(-10%) !important;
    &::before {
      bottom: unset !important;
      top: unset !important;
    }
  }

  &--z10 {
    z-index: 1000;

    @include mobile {
      z-index: unset;
    }
  }

  &__content {
    top: -50%;
    opacity: 0;
    width: toRem(345px);
    margin-top: toRem(17px);
    margin-left: toRem(22px);
    position: absolute;
    visibility: hidden;
    padding: toRem(16px 11px);
    align-items: center;
    left: calc(100% + #{toRem(10px)});
    background-color: white;
    transform: translateY(-50%);
    border: toRem(1px) #dddddd solid;
    transition: 0.2s ease-in-out;
    @include mobile {
      display: none;
    }

    p {
      font-size: toRem(12px);
      margin-bottom: toRem(12px);
      line-height: toRem(18px);
      &:last-child {
        margin-bottom: 0;
      }
    }
    &::before {
      top: 0;
      bottom: 0;
      left: toRem(-8px);
      content: "";
      width: toRem(15px);
      height: toRem(15px);
      margin: auto 0;
      text-align: center;
      position: absolute;
      background: white;
      transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      border-right: toRem(1px) solid #dddddd;
      border-bottom: toRem(1px) solid #dddddd;
    }
    &--visible {
      opacity: 1;
      z-index: 1000;
      margin-left: toRem(5px);
      visibility: visible;
    }
    &--left {
      left: auto;
      right: calc(100% + #{toRem(15px)});
      &::before {
        top: 0;
        bottom: 0;
        left: auto;
        right: toRem(-8px);
        content: "";
        width: toRem(15px);
        height: toRem(15px);
        margin: auto 0;
        text-align: center;
        position: absolute;
        background: white;
        transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        border-right: toRem(1px) solid #dddddd;
        border-bottom: toRem(1px) solid #dddddd;
      }
    }

    &--adjustLeft {
      @include mobile-tablet {
        left: calc(100% + #{toRem(10px)});

        &::before {
          left: toRem(-8px);
          transform: rotate(135deg);
          -o-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
    }

    &--adjustRight {
      @include mobile-tablet {
        left: auto;
        right: calc(100% + #{toRem(15px)});

        &::before {
          left: auto;
          right: toRem(-8px);
          transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
  }
  &--left-diff {
    margin-top: toRem(6px);
    margin-left: toRem(10px);
  }
  svg {
    width: toRem(17px);
  }
}
