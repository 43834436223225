@import "./mixins";
@import "./variables";

$theme-colors: (
  "primary": $mainColor,
  "secondary": $secondary,
);

@import "../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
@import "node_modules/bootstrap/scss/bootstrap";
@import "../../assets/fonts/stylesheet.css";

html {
  // @include large-desktop {
  //   font-size: toRem(14px);
  // }

  @include non-retina {
    font-size: 87%;
  }

  @include small-retina {
    font-size: 85%;
  }

  // @include small-desktop {
  //   font-size: unset;
  // }
}

body {
  font-family: "Sofia Pro", "Open Sans";
  font-size: toRem(14px);
  font-weight: 400;
  color: #3a3a3a;
  background: #f8f8f8;
  transition: all 0.3s ease-in;
}

input,
textarea,
select,
option {
  font-family: "Sofia Pro", "Open Sans";
  opacity: 1;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main_content {
  flex: 1;
  display: flex;
  position: relative;
  &--header {
    padding-top: $headerHeight;
  }
  @include mobile-tablet {
    padding-top: toRem(80px);
  }
}

.MainApp {
  width: 100%;
  display: flex;
  position: relative;

  &--adjustable {
    margin-top: toRem(56px);

    .PageSidebar {
      &--Custom {
        margin-top: toRem(56px);
        max-height: calc(100dvh - 10.5rem);

        @include mobile-tablet {
          max-height: calc(100dvh - 8.5rem);
        }
      }
    }

    .Bookings,
    .Floors {
      .PageSidebar {
        margin-top: toRem(56px);

        @include mobile-tablet {
          max-height: calc(100vh - 6.5rem);
        }
      }
    }

    .Calendars {
      .CalendarProviders {
        max-height: 83%;
      }
    }
  }

  @include desktop {
    width: calc(100% - 7.5rem);
  }

  @include mobile-tablet {
    z-index: 6;
  }
  &__content {
    flex: 1;
    width: 100%;
    padding: toRem(0 20px);
    transition: $transition1;
    padding-top: calc(
      56px * (var(--providers-count) + var(--directory-count))
    ) !important;

    // @include default-laptop {
    //   padding-top: calc(56px * (var(--providers-count) + var(--directory-count))) !important;
    // }

    @include mobile {
      padding: 0;
    }
  }
}

.secondary-font-family {
  font-family: "Open Sans";
}

.top-10 {
  top: 20%;
}

.center {
  text-align: center;
}

.font-weight-300 {
  font-weight: 300;
}

.min-vh-25 {
  min-height: 25vh;
}

.btn {
  line-height: 1em;
  font-size: toRem(16px);
  padding: toRem(13px 20px);
  border-radius: toRem(36px);
  &-large {
    min-width: toRem(230px);
  }
  &-medium {
    min-width: toRem(180px);
  }
  &-primary {
    color: white !important;
  }
  &-small {
    min-height: auto;
    min-width: toRem(150px);
    padding: toRem(10px) !important;
    font-size: toRem(14px) !important;
  }
  &-outline {
    color: $mainColor;
    border-color: $mainColor;
    transition: $transition1;
    &:hover {
      color: white;
      background: $mainColor;
    }
  }
  &-outline-primary {
    &:hover {
      color: white !important;
    }
  }
}

.list-row {
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: $radius;
  background-color: white;
  padding: toRem(20px 30px);
  box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05);
  &:not(:last-child) {
    margin-bottom: toRem(20px);
  }
  @include mobile {
    padding: toRem(20px);
  }
}

.small-font-size {
  font-size: 0.9rem;
}

.form-group-image {
  overflow: hidden;
  min-height: toRem(185px);
  border-radius: $radius;
  margin-bottom: toRem(10px);
  @include mobile {
    min-height: toRem(150px);
  }
  img {
    max-width: 100%;
  }
}

.form-check-input {
  margin-left: 0;
}

.form-group {
  .checkbox_label {
    z-index: 0;
    cursor: pointer;
    position: relative;
    padding-left: toRem(26px);
    color: black !important;
    font-size: toRem(14px) !important;
  }
  .checkbox_box {
    left: 0;
    top: toRem(-4px);
    z-index: 1;
    width: toRem(19.2px);
    height: toRem(19.2px);
    cursor: pointer;
    position: absolute;
    background: url("../../assets/icons/checkbox-empty.svg") no-repeat center;
  }
  .checkbox_name {
    font-weight: 400;
    color: #000;
    font-size: toRem(14px);
  }
  .form-check-input {
    &[type="checkbox"] {
      opacity: 0;
      visibility: hidden;
      &:checked {
        & + .checkbox_label {
          .checkbox_box {
            background-image: url("../../assets/icons/checkbox-full.svg") !important;
          }
        }
      }
    }
  }
}

.p-modal {
  padding: toRem(10px 28px 0 28px);
}

.pb-min {
  padding: toRem(16px 0);
}

.pt-min {
  padding-top: toRem(16px);
}

.pl-min {
  padding-left: toRem(8px);
}

.pb-min {
  padding-bottom: toRem(10px);
}

.pb-1x {
  padding-bottom: toRem(16px);
}

.pb-12 {
  padding-bottom: toRem(12px) !important;
}

.p-x-main {
  padding: toRem(0 30px);
}

.mt-main {
  margin-top: toRem(16px);
}

.mt-6 {
  margin-top: toRem(64px);
}

.m-x-main {
  margin: 0 toRem(10px);
}

.ml-auto {
  margin-left: auto !important;
}

.m-w-25 {
  min-width: toRem(400px);
}

.w-5 {
  width: toRem(80px) !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.max-w-none {
  max-width: unset;
}

.max-w-none-important {
  max-width: unset !important;
}

.flex-1 {
  flex: 1;
}

.max-w-350-content {
  max-width: toRem(370px);
}

.scale-105 {
  transform: scale(1.5);
}

.shadow_overlay {
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 5;
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.35);
}

.cursor-pointer {
  cursor: pointer !important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.workspace__items {
  height: calc(85% - (var(--providers-count) + var(--directory-count)) * 56px);

  @include mobile-tablet {
    height: calc(
      90% - (var(--providers-count) + var(--directory-count)) * 56px
    );
  }
}

.default-page {
  @include desktop {
    margin: $headerDistance toRem(60px);
  }
  @include mobile {
    margin: toRem(0 20px);
  }
}

.w-20-svg svg {
  width: toRem(20px);
}

.default-paragraph {
  line-height: 1.2em;
  font-size: toRem(16px);
  color: $labelColor !important;
}

.heading-empty {
  font-weight: 300;
  font-size: toRem(48px);
  margin-bottom: toRem(25px);
  max-width: toRem(600px);
  text-align: center;

  @include small-desktop {
    max-width: toRem(400px);
    font-size: toRem(30px);
  }
}

.error-message {
  color: $errorColor;
}

.default-paragraph {
  line-height: 1.2em;
  font-size: toRem(16px);
  color: $labelColor !important;
}

.default-list {
  padding-left: toRem(10px);
  li {
    color: $labelColor;
    list-style-type: initial;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.pro-list {
  padding-left: toRem(16px);
  padding-top: toRem(3px);
  li {
    color: $proColor;
    list-style-type: initial;
    &:not(:last-child) {
      margin-bottom: toRem(2px);
    }
  }
}

.invalid-feedback {
  display: block !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-black {
  color: #000 !important;
}

.text-pro {
  color: $proColor;
}

.text-secondary {
  color: $secondary;
}

.text-14 {
  font-size: toRem(14px) !important;
}

.text-16 {
  font-size: toRem(16px) !important;
}

.lh-0 {
  line-height: 0 !important;
}

.word-break {
  word-break: break-all;
}

.link {
  color: $mainColor !important;
  text-decoration: none;
  background-color: transparent;
}

.rotate-top {
  transform: rotate(180deg);
}

.active-icon {
  svg {
    path {
      stroke: $mainColor;
    }
  }
}

.default-box {
  padding: toRem(30px);
  border-radius: $radius;
  background: #ffffff;
  box-shadow: $defaultBoxShadow;
  @include mobile-tablet {
    padding: toRem(20px);
  }
}

.d-block {
  display: block;
}

.d-initial {
  display: initial;
}

.btn-third {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #eeeded;
    border-color: #eeeded;
  }
}

.btn-business {
  color: #fff;
  background-color: #166c79;
  border-color: #166c79;
  &:hover {
    color: #fff;
    background-color: #104d57;
    border-color: #104d57;
  }
}

.btn-pro {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
  &:hover {
    background-color: #333333;
    border-color: #333333;
    color: #fff;
  }
}

.btn-outline-red {
  color: $errorColor;
  border-color: $errorColor;
  &:hover {
    color: $errorColor;
    opacity: 0.8;
  }
}

.btn-simple {
  color: $secondary;
}

.btn-link {
  color: $mainColor !important;
  background-color: none;
  border: none;
  white-space: nowrap;
  font-size: toRem(14px) !important;

  &:hover {
    opacity: 0.8;
  }
}

.text-underline {
  text-decoration: underline;
}

.btn-underline {
  color: $labelColor;
  text-decoration: underline;
  background-color: none;
  border: none;
  white-space: nowrap;
  font-size: toRem(14px) !important;
  &:hover {
    text-decoration: underline;
    color: $labelColor;
    opacity: 0.8;
  }
}

.btn-outline-primary {
  color: $mainColor;
  background-color: #fff;
  border-color: $mainColor;
  .Icon path {
    fill: $mainColor;
  }

  &:hover {
    .Icon path {
      fill: #fff;
    }
  }
}

.label__main {
  color: #808080;
  font-weight: 300;
  line-height: toRem(16px);
}

.label__below {
  font-size: toRem(12px) !important;
  color: $secondary;
  font-weight: 300;
  line-height: toRem(16px);
  opacity: 0.7;
}

.gap-min {
  gap: toRem(5px);
}

.gap-10 {
  gap: toRem(10px);
}

.gap-1 {
  gap: toRem(16px);
}

.gap-2 {
  gap: toRem(32px);
}

.gap-3 {
  gap: toRem(48px);
}

.background__shadow {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
}

.adjustable-button {
  @include mobile-tablet {
    @include adjustableButton;
  }
}

.primary {
  &--text {
    color: $mainColor;
    &:hover {
      color: #1f917a;
      text-decoration: underline;
    }
  }
}

.form-group {
  label {
    line-height: 1em;
    font-weight: 300;
    color: grey !important;
    font-size: toRem(14px) !important;
    margin-bottom: toRem(3px) !important;
  }
  input {
    @include placeholder {
      color: rgba(0, 0, 0, 0.2);
    }
  }
  &.disabled {
    z-index: 0;
    user-select: none;
    position: relative;
    &:before {
      top: 0;
      left: 0;
      z-index: 5;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      transform: scale(1.1);
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.form-control {
  color: #000 !important;
  line-height: 1em !important;
  font-weight: 300 !important;
  border-radius: toRem(1px) !important;
  padding: toRem(4px 0 0 9px) !important;
  border-color: #ebebeb !important;
}

.form-error {
  border-color: #ea1d25 !important;
}

.inactive {
  color: $labelColor;
}

.Toastify__toast--error {
  border-radius: toRem(5px) !important;
  background: $errorColor !important;
}
.Toastify__toast--success {
  border-radius: toRem(5px) !important;
  background: $mainColor !important;
}

.no-min-height {
  input {
    min-height: unset !important;
  }
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border {
    width: toRem(20px);
    height: toRem(20px);
    margin-right: toRem(10px);
  }
}

.disabled {
  opacity: 0.6;
  cursor: default;
  user-select: none;
}

.flex-a-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  flex-direction: column;
}

.flex-100 {
  flex: 100%;
}

.flex-basis-0 {
  flex-basis: 0%;
}

.main-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-50-large {
  width: 50%;

  @include mobile {
    width: 100%;
  }
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50% !important;
}

.w-25-large {
  width: 25%;

  @include mobile {
    width: 100%;
  }
}

.page-border-bottom {
  border-bottom: 2px solid $borderColor;
}

.default-scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(90% - (var(--providers-count) + var(--directory-count)) * 56px);

  @media (max-height: 400px) {
    height: calc(
      75% - (var(--providers-count) + var(--directory-count)) * 56px
    );
  }
}

.child-element {
  position: absolute;
  background-color: #fff;
  padding: toRem(20px);
  z-index: 10;
  border-radius: toRem(6px);
  box-shadow: toRem(0px 10px 8px) rgb(0 0 0 / 10%);
  cursor: default;
  min-width: toRem(220px);
  top: toRem(25px);
  right: toRem(-5px);

  @include mobile {
    right: 0;
    left: toRem(-50px);
  }

  &--head {
    font-size: toRem(16px);
    border-bottom: 1px solid #ebebeb;
    display: block;
    // text-align: center;
    color: $secondary;
    min-width: 110px;
  }

  .LabelWithInformationBox {
    label {
      font-size: toRem(13px);
    }
  }

  .btn {
    display: flex;
  }
  &--clear {
    justify-content: space-around;

    height: 22px;
    min-height: 22px;
    min-width: toRem(55px);

    span {
      padding: unset !important;
      font-size: toRem(12px);

      @include mobile-tablet {
        font-size: toRem(10px);
      }
    }
  }
}

.TokenToast {
  background-color: $errorColor;
  display: flex;
  flex-direction: column;
  place-items: center;
  padding: toRem(10px 0);
  transition: all 0.7s ease;
  display: none;
  visibility: hidden;
  transform: translateY(-100%);
  height: 56px;
  max-height: 56px;

  svg {
    width: toRem(20px);
  }

  span {
    color: #fff;
    white-space: nowrap;
  }

  &__show {
    transform: translateY(0);
    display: flex;
    visibility: visible;
  }

  &__buttons {
    button {
      background: none;
      border: none;
      color: #fff;
      text-decoration: underline;
    }

    &--fix {
      font-weight: 800;
      font-style: italic;
    }

    .divider {
      width: toRem(1px);
      height: toRem(12px);
      background-color: white;
      padding-top: toRem(2px);
      transform: translateY(1px);
    }
  }
  &__admins {
    place-items: flex-start;
    padding-left: toRem(70px);

    a {
      color: #fff;
    }

    .TokenToast__buttons {
      margin-left: toRem(20px) !important;
    }

    @include mobile {
      padding-left: toRem(5px);
    }
  }

  &__close {
    opacity: 1;
    right: toRem(20px);
    top: toRem(15px);
    background-color: #e24a1a !important;

    svg {
      width: toRem(13px);

      path {
        fill: white;
      }
    }
  }
}

.CalculatorProRate {
  max-width: toRem(600px);
}

.LineDivider {
  width: 100%;
  height: toRem(1px);
  border-radius: toRem(10px);
  background-color: $labelColor;
}

.ReservationListCard {
  &__icon {
    .rooms-icon,
    .room_icon_18x18,
    .desk_icon_18x18,
    .people-icon-bold {
      svg {
        background-color: #f8f8f8;
        border-radius: toRem(6px);
        padding: toRem(6.5px);
        width: toRem(35px) !important;
      }
    }
  }
}

.SuccessToast {
  svg {
    width: toRem(20px);

    path {
      fill: #fff;
    }
  }
}

.AddDeviceButton {
  .Icon {
    left: 20px;
  }

  &__icon {
    button {
      span {
        margin-left: toRem(20px);
        margin-top: unset;
        color: #000;
      }

      svg {
        path {
          fill: unset !important;
        }
      }
    }
  }
}

.MainStatus {
  &--active {
    color: $mainColor;
  }

  &--inactive {
    color: $errorColor;
  }
}

.AddDeviceButton.adjustable-button {
  .btn {
    @include mobile-tablet {
      &::before {
        background: url("../../assets/icons/plus-icon-black.svg") no-repeat
          center;
      }
    }
  }
  @include mobile-tablet {
    button {
      .Icon {
        display: none;
        visibility: hidden;
      }
    }
  }
}

.DefaultTemplate {
  background-color: #fff;
  border-radius: toRem(10px);
  box-shadow: $defaultBoxShadow;
}

.EWSServiceForm {
  &__switch {
    .form-check {
      max-width: toRem(370px);
    }
  }
}

.Profile__active {
  .css-wpyx0x-control,
  .css-g1d714-ValueContainer,
  .css-1hb7zxy-IndicatorsContainer,
  .css-imjbp4-control {
    background-color: #adaeb9;
  }

  .css-1uccc91-singleValue {
    color: #fff;
  }
}

.TimeSelector__popup-content {
  z-index: 10000 !important;
  min-width: toRem(172px) !important;

  .PopupTitle {
    text-align: center;
    padding-bottom: 0;
    padding-right: 0;
    color: $proColor;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  @-moz-document url-prefix() {
    scrollbar-width: none;

    * {
      scrollbar-width: none !important;
    }
  }
}

.center-content {
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: toRem(48px);

  h4 {
    font-size: toRem(35px);

    @include mobile-tablet {
      font-size: toRem(27px);
      text-align: center;
    }
  }

  * {
    z-index: 10;
  }
  @include mobile-tablet {
    font-size: toRem(30px);
  }

  button {
    margin-top: toRem(20px);
  }
}

.main-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 500px));
  grid-gap: toRem(25px);

  @include mobile-tablet {
    place-content: center;
  }

  @include mobile {
    grid-template-columns: repeat(auto-fit, minmax(315px, 500px));
    grid-gap: 0;
  }
}

.SelectedFloor,
.NoFloorSelected {
  svg {
    width: toRem(15px);
  }
}

.ReAuthEWS {
  transform: translateY(100px) !important;

  @include mobile {
    height: 100%;
    display: flex;
    align-items: center;
    transform: translateY(0) !important;
  }

  .modal-content {
    padding: toRem(20px);
  }

  .exchange_ews {
    svg {
      width: toRem(25px);
    }
  }
}

.DeviceApp {
  border-radius: toRem(30px);
  padding: toRem(8px 15px);
  width: toRem(160px);
  place-content: center;
  gap: toRem(7px);

  h6 {
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 0px;
  }

  svg {
    width: toRem(20px);
  }

  &__room {
    background: rgba(22, 108, 121, 0.2);
    color: #166c79;
    h6 {
      padding-bottom: toRem(3px);
    }
    svg {
      path,
      rect {
        stroke: #166c79;
      }
    }
  }

  &__wayfinder {
    background: #f0ebfb;
    color: #6b32d5;
  }
}

.UserModalLoading {
  &__rounded {
    border-radius: 50% !important;
    width: toRem(110px) !important;
    height: toRem(110px) !important;
    min-height: toRem(100px) !important;
  }
}

.UserModalLoading {
  margin-bottom: toRem(25px);
  width: toRem(400px) !important;
}

.leaflet-popup-content {
  min-width: toRem(148px) !important;
}

.leaflet-container {
  width: 100%;
  height: 75vh;
  background-color: #ffffff;
  border-radius: toRem(10px);

  @include small-desktop-custom {
    height: 70vh !important;
  }
}

.leaflet-touch .leaflet-bar {
  box-shadow: toRem(0px 4px 10px) rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: unset;
  margin-left: toRem(20px);
}

.leaflet-bar {
  border-radius: toRem(10px);
}

.leaflet-bar a {
  border-bottom: 1px solid #ebebeb;
}

.leaflet-bar a:first-child {
  border-top-left-radius: toRem(10px);
  border-top-right-radius: toRem(10px);
}

.leaflet-control-attribution a {
  display: none;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 999;
}

.leaflet-right .leaflet-control-attribution {
  display: none !important;
}

.leaflet-touch .leaflet-bar a {
  width: toRem(30px);
  height: toRem(35px);
}

.leaflet-container a.leaflet-popup-close-button {
  top: toRem(2px);
  right: toRem(5px);
}

.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switch-container {
  display: inline-block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3.125rem; // 50px -> 3.125rem
  height: 1.5625rem; // 25px -> 1.5625rem

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 50rem; // Ensures it remains circular at all sizes

    &::before {
      position: absolute;
      content: "";
      height: 1.25rem; // 20px -> 1.25rem
      width: 1.25rem; // 20px -> 1.25rem
      left: 0.25rem; // 4px -> 0.25rem
      bottom: 0.15625rem; // 2.5px -> 0.15625rem
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: #4ce2af;
  }

  input:checked + .slider::before {
    transform: translateX(1.5625rem); // 25px -> 1.5625rem
  }
}

.NoOption {
  .css-gg45go-NoOptionsMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: toRem(11px);
    height: toRem(37px);
    color: #808080;
  }

  &__hide {
    .css-gg45go-NoOptionsMessage {
      display: none;
      visibility: hidden;
    }
  }
}

.SearchingDirectory {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: toRem(11px);
  height: toRem(37px);
  color: #808080;
}

.SearchDirectory {
  margin-left: toRem(12px);
  margin-right: toRem(12px);
  padding: toRem(0 5px);
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  align-content: center;
  height: toRem(37px);

  &:hover {
    color: $mainColor;
  }
}

.css-1pahdxg-control {
  border-radius: toRem(10px) !important;
  border-color: $mainColor !important;
  box-shadow: none !important;
  min-height: toRem(50px) !important;
}

.css-9gakcf-option {
  background-color: #ebebeb !important;
  color: inherit !important;
}

.css-1n7v3ny-option {
  background-color: #ebebeb !important;
}

.css-26l3qy-menu {
  box-shadow: 0 2px 8px 6px hsla(0, 0%, 0%, 0.1),
    0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
}

.room-permanent-tooltip {
  box-shadow: none;
  border: unset;
  background-color: initial;
  padding: 0;
  padding-top: toRem(20px);

  &::before {
    display: none;
  }

  text {
    stroke-linejoin: round;
    text-anchor: middle;
    fill: #037048;
    stroke: #fff;
    stroke-width: 3px;
    font-size: toRem(18px);
    font-weight: 400;
    paint-order: stroke fill;
  }
  .booked {
    fill: #ea1d25;
  }
}

.admin-tooltip {
  text {
    fill: #276d75;
  }
}

.btn-close svg {
  cursor: pointer;
  width: toRem(12px);
  position: absolute;
  top: 15px;
  right: 15px;
}

.css-26l3qy-menu {
  z-index: 10 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  &::before {
    left: -45px !important;
  }
  &::after {
    left: -45px !important;
  }
}

.react-datepicker__input-container input:focus {
  border: 1px solid $mainColor !important;
  outline: none !important;
  border-width: 1px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #ebebeb !important;
}

.HistoryFilters-content .css-2b097c-container {
  max-width: toRem(220px);
}
