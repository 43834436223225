@import "../../../lib/scss/mixins";

.WorkplaceList {
  width: 100%;
  height: 100%;
  .StructureTable {
    margin-bottom: toRem(30px);
    &__head {
      &__item {
        @include mobile-tablet {
          display: none;
          &.workplace_icon,
          &.workplace_icon_device {
            display: block;
          }
        }
      }
      .workplace_name {
        max-width: toRem(170px);
      }
      .device_app {
        text-align: center;
      }
      .device_connection {
        max-width: toRem(80px);
      }
      .workplace_icon_device {
        max-width: toRem(75px) !important;
      }
      .workplace_icon,
      .workplace_icon_device {
        color: black;
        cursor: pointer;
        max-width: toRem(115px);
        position: relative;
        margin-left: toRem(-20px);
        padding-left: toRem(20px);
        margin-right: toRem(30px);
        &:before {
          left: 0;
          top: 50%;
          height: toRem(9px);
          width: toRem(13px);
          content: "";
          position: absolute;
          transform: translateY(-50%);
          background: url("../../../assets/icons/chevron-down.svg") no-repeat
            center;
        }
      }
      .workplace_license {
        text-align: center;
        max-width: toRem(150px);
      }
      .workplace_delete {
        flex: none;
        width: toRem(18px);
        margin-left: auto;
      }
    }
    &__item {
      margin-bottom: 0 !important;
      overflow: hidden;
      @include mobile-tablet {
        padding: toRem(15px);
        flex-direction: column;
        align-items: flex-start;
        .workplace_icon,
        .workplace_icon_device {
          display: none;
        }
      }
      .workplace_icon {
        max-width: toRem(115px);
        margin-right: toRem(11px);
      }
      .workplace_icon_device {
        max-width: toRem(75px);
        margin-left: toRem(-20px);
        padding-left: toRem(20px);
        margin-right: toRem(30px);
      }
      .workplace_name {
        max-width: toRem(170px);

        h6 {
          overflow-y: hidden;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        @include default-laptop {
          overflow: hidden;

          a {
            width: 90%;
          }
        }

        @include mobile-tablet {
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: toRem(20px);
          width: 100% !important;

          a {
            width: 100%;
          }

          h6 {
            font-size: toRem(18px);
          }
        }
      }
      .workplace_description {
        h6 {
          overflow-y: hidden;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 90%;
        }
        @include mobile-tablet {
          h6 {
            display: flex;
          }
          @include iconPrefix("description-icon");
        }
      }
      .device_app {
        display: flex;
        align-items: center;
      }
      .workplace_hardware {
        @include mobile-tablet {
          @include iconPrefix("hardware-icon");
        }
      }
      .workplace_app_version {
        @include mobile-tablet {
          @include iconPrefix("settings_profiles_icon");
        }
      }
      .device_app {
        @include mobile-tablet {
          @include iconPrefix("apps-icon");
        }
      }
      .workplace_user {
        @include mobile-tablet {
          h6 {
            display: flex;
            @include iconPrefix("single-user-icon");
          }
        }
      }
      .workplace_calendar {
        @include mobile-tablet {
          @include iconPrefix("calendar-icon");
        }
      }
      .workplace_license {
        align-items: center;
        max-width: toRem(150px);
        @include mobile-tablet {
          @include iconPrefix("subscribe-icon");
          &:after {
            content: "License";
            color: gray;
            margin-left: toRem(10px);
          }
        }
      }
      .workplace_device {
        @include mobile-tablet {
          flex-wrap: wrap;
          @include iconPrefix("device_icon_18x18");
          span {
            &:not(:last-child)::after {
              display: inline-block;
              white-space: pre;
              content: ", ";
            }
          }
        }
      }
      .workplace_tags {
        @include mobile-tablet {
          h6 {
            &:before {
              content: "";
              width: toRem(14px);
              height: toRem(14px);
              margin-right: toRem(5px);
              display: inline-block;
              background: url("../../../assets/icons/tag-icon.svg") no-repeat
                center;
            }
          }
        }
      }
      .workplace_delete {
        flex: none;
        margin-left: auto;
        @include mobile-tablet {
          right: 15px;
          bottom: 15px;
          margin-bottom: 0;
          position: absolute;
        }
      }
      .device_connection {
        max-width: toRem(80px);

        .offline-device {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-size: toRem(13.5px);

            @include mobile-tablet {
              font-size: toRem(11px);
            }
          }
        }

        .approve-icon {
          margin-left: toRem(9px);
        }

        svg {
          width: toRem(23px);
        }
        @include mobile-tablet {
          top: 15px;
          right: 15px;
          position: absolute;
          svg {
            width: toRem(18px);
          }
        }
      }
    }
  }

  .btn-small {
    min-width: toRem(116px);
  }

  &.SearchWorkplaces {
    .StructureTable {
      &__head {
        .workplace_description {
          max-width: 12%;
        }

        .workplace_device {
          max-width: 17%;
        }

        .workplace_calendar {
          max-width: 24%;
        }
      }
      &__item {
        .workplace_description {
          max-width: 12%;
        }
        @include mobile-tablet {
          .workplace_icon {
            display: block;

            svg {
              width: toRem(25px);
            }
          }
        }
      }
    }
  }
}
