@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.CalendarsWizardHeader {
  display: grid;
  position: relative;
  column-gap: toRem(20px);
  margin-bottom: toRem(20px);
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);

  &__three {
    grid-template-columns: repeat(3, 1fr);
  }

  &:after {
    left: 0;
    z-index: 0;
    content: "";
    height: toRem(1.2px);
    width: 100%;
    bottom: toRem(11.5px);
    position: absolute;
    background-color: $mainBgColor;
  }
}
