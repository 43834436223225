@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.CalendarsWizardHeaderItem {
  cursor: pointer;
  text-align: center;
  position: relative;
  &:after {
    left: 0;
    z-index: 1;
    opacity: 0;
    content: "";
    height: toRem(1.2px);
    width: 100%;
    position: absolute;
    bottom: toRem(11.5px);
    transition: $transition1;
    background-color: $mainColor;
  }
  &--active {
    h5,
    p {
      color: $mainColor;
    }
    .indicator {
      background-color: $mainColor !important;
    }
    &:after {
      opacity: 1;
    }
  }
  h5 {
    margin-bottom: 0;
    font-size: 1.1rem;
    transition: $transition1;
  }
  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    transition: $transition1;
  }
  .indicator {
    margin: 0;
    z-index: 1;
    width: toRem(15px);
    height: toRem(15px);
    margin-top: toRem(10px);
    position: relative;
    border-radius: 100%;
    display: inline-block;
    transition: $transition1;
    background-color: $mainBgColor;
  }
}
