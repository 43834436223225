@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.DropdownList {
  right: 0;
  z-index: 6;
  padding: toRem(6px 0);
  min-width: toRem(180px);
  overflow: hidden;
  position: absolute;
  background: white;
  border-radius: toRem(11px);
  top: calc(100% + #{toRem(5px)});
  border: toRem(1px) solid $borderColor;
  box-shadow: toRem(0px 10px 8px) rgb(0 0 0 / 10%);
  li {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: toRem(7px 16px);
    transition: $transition1;
    &.disabled {
      opacity: 0.6;
      user-select: none;
      cursor: not-allowed;
    }
    &:hover:not(.disabled) {
      background-color: #f8f8f8;
    }
    svg {
      width: toRem(18px);
    }
  }
  h5 {
    font-size: toRem(16px);
    line-height: 1em;
    margin-left: toRem(10px);
    font-weight: normal;
  }
  &--gray {
    h5 {
      color: #808080;
    }
  }
  &--extended {
    min-width: toRem(200px);
    h5 {
      font-size: toRem(14px);
    }
  }
  &--large {
    min-width: toRem(210px);
    padding: toRem(12px 0);

    li {
      margin-left: toRem(20px);
      margin-right: toRem(20px);
      padding: toRem(10px 10px);
      padding-left: 0;
      width: unset;
      color: #808080;

      h5 {
        font-weight: 400;
      }

      &:hover {
        h5 {
          color: $mainColor;
        }

        svg {
          path {
            stroke: $mainColor;
          }
        }
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid $borderColor;
    }
  }
}
