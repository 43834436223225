@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CalendarSystem {
  &__list {
    display: grid;
    row-gap: toRem(10px);
  }

  &__item {
    display: flex;
    cursor: pointer;
    transition: $transition1;
    @include mobile {
      width: 100%;
    }
    &--disabled {
      opacity: 0.5;
      cursor: default;
      border: none !important;
    }
    &--active {
      border-radius: $radius;
      border-color: $mainColor !important;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
    }

    &__icon {
      height: toRem(80px);
      margin-right: toRem(20px);
      @include mobile {
        height: auto;
      }
      svg {
        width: toRem(70px);
        @include mobile {
          width: toRem(40px);
        }
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__title {
      font-size: 1.1rem;
      @include mobile {
        font-size: toRem(16px);
      }
    }
    &__desc {
      margin-bottom: 0;
      @include mobile {
        font-size: toRem(12px);
      }
    }
    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      height: toRem(16px);
      padding: 0 toRem(8px);
      border-radius: 8px;
      background-color: $labelColor;
      font-size: toRem(10px);
      color: #fff;
      margin-left: toRem(8px);
      margin-bottom: toRem(4px);
    }
  }
  .row {
    @include mobile {
      flex-direction: column;
    }
  }
}
