@import "../../../../lib/scss/mixins";

.O365ConnectButton {
  &__button {
    width: 100%;
    text-align: center;
    button {
      width: toRem(255px);
      height: toRem(48px);
      border: none !important;
      background: url("../../../../assets/images/ms_signin_light.svg") no-repeat
        center;
      transition: all 0.3s ease-in-out;
      background-color: none !important;
      &:focus,
      &:hover {
        opacity: 0.7;
      }
    }
    &--disabled {
      button {
        opacity: 0.2;
      }
    }
  }

  .CloudDeployment {
    .form-check {
      max-width: unset;
    }

    .Switch {
      &__input {
        margin-left: toRem(10px);
      }

      &__content {
        select {
          width: 50%;
          appearance: none;
          cursor: pointer;
          color: #adaeb9 !important;

          option {
            color: #adaeb9 !important;
          }
        }
      }
    }
  }
}
