@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.WorkplaceInsights {
  .PageHeader {
    @include mobile-tablet-custom {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .WorkspaceValueInsights {
    margin-top: toRem(20px);

    &__statistics {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      grid-gap: toRem(20px);

      @include mobile {
        grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
      }

      &--main {
        @media (max-width: 1150px) and (min-width: 800px) {
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
      }

      &--desks {
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

        @media (max-width: 650px) {
          grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
        }
      }

      .divider {
        width: toRem(1px);
        height: 100%;
        background-color: #ebebeb;

        @include mobile {
          height: toRem(1px);
          width: 100%;
        }
      }

      .RecuperationItem {
        border-radius: 50%;
        width: toRem(100px);
        height: toRem(100px);
        flex-direction: column;

        span {
          font-weight: 400;
          font-size: toRem(16px);
          line-height: toRem(22px);
        }

        svg {
          width: toRem(45px);
        }

        @include mobile {
          width: toRem(75px);
          height: toRem(75px);

          svg {
            width: toRem(30px);
          }

          span {
            font-size: toRem(14px);
          }
        }

        &__time {
          background-color: $mainColor;
          color: #156c79;

          svg {
            path {
              fill: #156c79;
            }
          }
        }

        &__estimate {
          background-color: #156c79;
          color: #fff;
        }
      }

      .ChartCard {
        &__adjustable {
          @include mobile {
            flex-direction: column;
            gap: toRem(15px);
          }
        }
      }
    }

    .UtilizationDensity,
    .RoomReleaseSankey {
      height: toRem(500px) !important;

      min-height: toRem(500px);

      &__max {
        height: toRem(1200px) !important;
      }
    }

    .RoomReleaseSankey {
      position: relative;
      &__text {
        font-weight: 400;
        position: absolute;
        line-height: toRem(16px);
        font-size: toRem(12px);

        &--flow {
          top: 10%;
          color: #808080;

          &--max {
            top: 4%;
          }
        }

        &--info {
          left: 50%;
          transform: translateX(-50%);
          bottom: 5%;
          color: #adaeb9;

          &--max {
            bottom: 1%;
          }
        }
      }

      &__chart {
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
        border-radius: toRem(5px);
        padding: toRem(5px 10px);

        &--square {
          width: toRem(15px);
          height: toRem(15px);
          border-radius: 50%;
          margin: toRem(0 5px);
        }
      }
    }

    &.DeskValueInsights {
      .MeetingsAverageRow {
        &.flex-align-start {
          align-self: flex-start;

          @include mobile {
            align-self: unset;
          }
        }

        @include small-insights {
          min-width: toRem(200px);
        }
      }
    }

    .BarChart {
      background-color: #fff;
      border-radius: toRem(3px);
      padding: toRem(5px 10px);
      box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;

      &__square {
        width: toRem(12px);
        height: toRem(12px);
        margin: toRem(0 5px);
      }

      &__name {
        overflow-wrap: break-word;
        white-space: pre-wrap;
        max-width: toRem(270px);
      }

      &__value {
        margin-left: toRem(5px);
        margin-bottom: toRem(-5px);
        font-weight: 500;
      }
    }
  }

  .ChartCard {
    background-color: #fff;
    box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05);
    border-radius: toRem(12px);
    padding: toRem(20px);

    h5 {
      font-weight: 400;
      font-size: toRem(20px);
      line-height: 25px;

      @include mobile {
        font-size: toRem(18px);
      }
    }

    &__occupancy {
      position: relative;
      max-height: toRem(330px);
      min-height: toRem(330px);
      height: toRem(330px);

      .ChartCard__header {
        margin-bottom: toRem(18px);
      }
    }
  }

  .MeetingsAverageRow {
    h6 {
      font-weight: 400;
      font-size: toRem(20px);
      line-height: 25px;

      @include mobile {
        font-size: toRem(18px);
      }
    }

    &__value {
      &--percentage {
        span {
          font-size: toRem(12px);
          font-weight: 400;
          padding-left: toRem(2px);
        }
        svg {
          width: toRem(22px);
        }
      }
    }

    &__positive {
      color: $mainColor;
    }

    &__negative {
      color: #e24a1a;
    }

    &__description {
      color: #adaeb9;
      line-height: 14px;
      font-size: toRem(12px);
    }

    &__card {
      &--description {
        &--active {
          span {
            color: $mainColor;
          }
        }
        &--inactive {
          span {
            color: #e7853f;
          }
        }
        span {
          font-weight: 400;
          font-size: toRem(12px);
        }

        svg {
          width: toRem(22px);
        }
      }
    }
  }

  @include small-desktop {
    padding-bottom: toRem(16px);
  }

  .SearchBox {
    position: absolute;
    top: toRem(5px);
    right: 0;

    @include mobile {
      top: toRem(50px);
    }
  }

  .InsightsHistoryTable {
    .StructureTable {
      &__item {
        @include mobile-tablet {
          // flex-direction: row;
          // align-items: center;
          // justify-content: flex-start;

          &__column {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &::before {
              // margin-top: toRem(-4px);
              margin-right: toRem(10px);
              // display: inline-block;
            }

            h4 {
              font-weight: 500;
              line-height: 1.2;
              margin-top: toRem(5px);
            }
          }

          .organizer {
            &:before {
              content: "Organizer:";
            }
          }

          .reservationDate {
            &:before {
              content: "Reservation Date:";
            }
          }

          .from {
            &:before {
              content: "From:";
            }
          }

          .until {
            &:before {
              content: "Until:";
            }
          }

          .duration {
            &:before {
              content: "Duration:";
            }
          }

          .room {
            &:before {
              content: "Room:";
            }
          }

          .desk {
            &:before {
              content: "Desk:";
            }
          }

          .checkedIn {
            &:before {
              content: "Checked in:";
            }
          }
        }
      }
    }
  }

  .RoomHistory {
    .HistoryFilters {
      position: absolute;
      top: toRem(10px);
      right: 0;
      cursor: pointer;

      svg {
        width: toRem(17px);
        margin-right: toRem(8px);
      }

      h4 {
        margin: 0;
        font-size: toRem(16px);
        color: $labelColor;
      }

      @include mobile {
        top: toRem(-20px);
      }
    }
  }
}
