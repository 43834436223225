@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.CalendarsWizardFooter {
  display: grid;
  column-gap: toRem(20px);
  grid-template-columns: repeat(2, 1fr);
  &__button {
    &--prev {
      grid-column-start: 1;
    }
    &--next {
      grid-column-start: 2;
    }
  }
  .btn {
    width: 100%;
    &-secondary {
      color: $labelColor;
      border-color: $labelColor;
      background-color: transparent;
    }
  }
}
