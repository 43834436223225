@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.WorkplaceHeader {
  margin-bottom: toRem(16px);
  &__information {
    padding-left: 0;
    color: $labelColor;
    margin-bottom: toRem(14px);
    list-style-type: none;

    @include mobile-tablet {
      padding-top: toRem(5px);
    }
    li {
      line-height: 1em;
      margin-bottom: toRem(4px);
    }
    span {
      margin-right: toRem(4px);
    }
  }
  &__tag {
    &:not(:last-child):after {
      content: ",";
    }
  }
  h1 {
    @include mainHeader;
  }
}
