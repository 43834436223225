@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.BookingOptions {
  display: flex;
  flex-direction: column;
  gap: toRem(18px);

  h4 {
    font-size: toRem(18px);
    font-weight: 400;
    // padding: toRem(15px 0);
  }

  .BookingOption {
    border-radius: $radius;
    cursor: pointer;

    svg {
      width: toRem(25px);

      path {
        stroke: #fff;
        // fill: #fff;
      }
    }

    .floor-map-2 {
      svg {
        path {
          fill: #fff;
          stroke: initial;
        }
      }
    }

    span {
      color: #fff;
      font-size: toRem(16px);
      white-space: nowrap;

      @include mobile {
        padding-left: toRem(10px) !important;
      }
    }

    &--room {
      background-color: #156c79;
    }
    &--desk {
      background-color: $mainColor;
    }
    &--map {
      background-color: #936cde;
      text-decoration: none;
    }
  }

  .Button {
    margin: toRem(7px 0);
  }

  &__map {
    cursor: pointer;
    margin-top: toRem(15px);
    svg {
      width: toRem(55px);
    }
  }
}
