@import "../../../lib/scss/mixins";

.ResourceActions {
  .InformationBox,
  &__template {
    position: relative;
    margin-right: toRem(11px);
    padding-right: toRem(11px);
    &:after {
      top: 50%;
      right: 0;
      width: toRem(1px);
      content: "";
      height: toRem(17px);
      position: absolute;
      background: #ebebeb;
      transform: translateY(-50%);
    }
  }

  &__template {
    cursor: pointer;

    svg {
      // 1.7rem width
      width: toRem(27.2px);
    }
  }
}
