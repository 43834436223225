@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.ApplyProfilesSteps {
  max-width: 80%;

  @include small-desktop-custom {
    max-width: 100%;
  }

  .CalendarsWizardForm {
    padding: 0;
    position: relative;
    &__profiles {
      .SelectItemsStep {
        &__selected {
          color: $errorColor;
          line-height: toRem(16px);
          font-weight: 300;
        }

        &__items {
          height: calc(
            82% - (var(--providers-count) + var(--directory-count)) * 56px
          );
          // max-height: 80%;
          // min-height: 62%;

          @include mobile-tablet {
            height: calc(
              70% - (var(--providers-count) + var(--directory-count)) * 56px
            );
          }
        }
      }

      .SelectSettingsStep {
        &__subTitle {
          color: $secondary;
          border-bottom: 1px solid $secondary;
        }

        h6 {
          color: $proColor;
          font-size: toRem(16px);
        }

        &__selector {
          display: block;
          margin: 0 auto;
          margin-top: toRem(30px);
          padding: toRem(5px) !important;
        }

        &__submit {
          position: absolute;
          bottom: 0;
          right: 0;

          // @include mobile {
          //   // bottom: 15%;
          //   // right: 13%;
          // }
        }
      }

      // .PageSidebar {
      //   position: initial;
      //   border-radius: toRem(10px);
      //   border-left: none;
      //   max-height: toRem(430px);
      //   min-width: unset !important;

      //   &__items {
      //     overflow-y: auto;
      //   }
      // }

      .PageSidebarHeader {
        // padding-bottom: toRem(15px);
        margin-bottom: toRem(5px);
        // border-bottom: 1px solid #ebebeb;
      }

      .checkbox_name {
        color: #4d4d4d !important;
      }

      .SettingsChecks {
        &__name {
          color: $labelColor;
          font-size: toRem(17.5px);
        }
      }

      &--footer {
        @include mobile {
          flex-direction: column;
          gap: toRem(40px);
          place-items: center;
        }

        &--group {
          align-self: flex-end;

          @media (max-width: 400px) {
            display: flex;
            flex-direction: column;
            gap: toRem(10px);
            place-items: center;

            button {
              margin-left: 0 !important;
            }
          }
        }
      }
    }

    .CalendarsWizardHeaderItem {
      @media (max-width: 480px) {
        p {
          width: 70%;
          margin: 0 auto;
        }
      }
    }
  }
}
