@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.WayFinderFrom {
  &__workspace {
    &--opener {
      min-width: toRem(250px) !important;
      .Icon {
        position: unset;
      }
    }
  }
  &__slider {
    .Icon {
      svg {
        width: toRem(32px);
      }
    }
    input[type="range"] {
      width: toRem(180px);
      -webkit-appearance: none;
      height: toRem(4px);
      border-radius: toRem(12px);
      outline: none;
    }

    input[type="range"]::-webkit-slider-thumb {
      box-shadow: 0.4px 0.4px 3px #000031;
      height: toRem(15px);
      width: toRem(15px);
      border-radius: toRem(15px);
      background: #fff;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }
    // Mozilla:
    input[type="range"]::-moz-range-thumb {
      box-shadow: 0.4px 0.4px 3px #000031;
      height: toRem(15px);
      width: toRem(15px);
      border-radius: toRem(15px);
      background: #fff;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }
    input[type="range"]::-ms-thumb {
      box-shadow: 0.4px 0.4px 3px #000031;
      height: toRem(15px);
      width: toRem(15px);
      border-radius: toRem(15px);
      background: #fff;
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
    }

    .pricing-slider-value {
      position: absolute;
      bottom: toRem(-25px);
      border: 1px solid #ebebeb;
      border-radius: toRem(5px);
      background-color: #fff;
      padding: toRem(3px 15px);
      color: #808080;
      font-weight: 400;
      font-size: toRem(12px);
      // transform: translatex(35px);
    }
  }

  &__workspace {
    &--picker {
      .PageSidebar {
        height: unset;
      }
      .PageSidebarHeader {
        margin-bottom: toRem(8px) !important;
      }
    }
  }
}
