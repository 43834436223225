@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.Button {
  display: flex;
  line-height: 1em;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: toRem(44px);
  max-width: toRem(180px);
  transition: $transition1;
  padding: toRem(8px) !important;
  font-size: toRem(18px) !important;
  .Icon {
    left: 25px;
    width: auto;
    position: absolute;
    display: inline-block;
    margin-right: toRem(5px);
    svg {
      width: toRem(20px);
    }
    path {
      fill: white;
    }
  }
  span {
    line-height: 1em;
  }
  &--icon {
    display: flex !important;
    align-items: center !important;
    padding-left: toRem(15px) !important;
    span {
      margin-top: toRem(-3px);
    }
  }
  &--low {
    padding: toRem(9px 8px) !important;
    @include mobile {
      padding: toRem(5px) !important;
    }
  }
  @include mobile {
    font-size: toRem(16px) !important;
  }

  &__extra-small {
    min-width: toRem(50px) !important;
    font-size: toRem(12px) !important;
    min-height: auto;
    padding: toRem(7px 12px) !important;
  }
}
