@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.ProvidersLogin {
  &__line {
    height: toRem(1px);
    width: 100%;
    background-color: $borderColor;
  }

  &__text {
    color: #808080;
    font-size: toRem(14px);
    line-height: toRem(23px);
    padding: toRem(0 15px);
  }

  .ProvidersButtons {
    .GSuiteConnectButton,
    .O365ConnectButton {
      flex: 1;
    }

    @include mobile {
      flex-direction: column;
    }
  }
}
