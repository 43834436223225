@import "../../../../lib/scss/mixins";

.InsightsHeaderButtons {
  margin-left: auto;
  z-index: 9;

  &__large {
    gap: toRem(15px);

    &--active {
      @include mobile-tablet-custom {
        visibility: visible !important;
      }
    }

    @include mobile-tablet-custom {
      display: none;
      visibility: hidden;
      flex-direction: column;
      background: #fff;
      position: absolute;
      right: 0;
      top: toRem(45px);
      padding: toRem(15px);
      border-radius: toRem(6px);
      box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.1);
    }

    .Button {
      .Icon {
        position: unset;
      }
    }
  }

  &__small {
    display: none !important;
    visibility: hidden;

    @include mobile-tablet-custom {
      display: unset !important;
      visibility: visible;
      @include adjustableButton;
    }
  }

  &__date {
    min-width: toRem(265px) !important;

    rect,
    path {
      stroke: #fff;
    }
  }

  &__location {
    min-width: toRem(265px) !important;

    svg {
      width: toRem(15px) !important;
    }
  }

  &__export {
    @include mobile-tablet {
      min-width: toRem(265px) !important;
    }
    svg {
      width: toRem(15px) !important;
    }
  }
}
