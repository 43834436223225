@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CalendarError {
  background-color: #fce9e4;
  border-radius: toRem(10px);
  justify-content: space-between;
  align-items: center;
  padding: toRem(20px 20px);
  gap: toRem(20px);

  @include mobile {
    flex-direction: column;
  }

  svg {
    width: toRem(24px);
  }

  &__content {
    color: #000;
    font-size: toRem(15px);

    &--provider {
      font-weight: 700;

      &--message {
        overflow-wrap: anywhere;
        white-space: pre-wrap;
      }
    }

    &--expand {
      font-weight: 500;
      color: $secondary;
      cursor: pointer;
    }
  }

  &__buttons {
    flex-direction: column;

    button {
      &:focus {
        box-shadow: none;
      }
    }

    @include mobile {
      flex-direction: row;
      align-self: flex-end;
      margin-top: toRem(10px);
    }
  }
}
