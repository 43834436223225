@import "../../../lib/scss/mixins";

.Pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: toRem(30px);

  @include small-desktop {
    margin-bottom: toRem(25px);
  }
}
