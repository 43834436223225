@import "../../../lib/scss/mixins";

.GoGetLoader {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  animation: fadingEffect 2s infinite;
  img {
    width: toRem(250px);
  }
}

@-webkit-keyframes fadingEffect {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes fadingEffect {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes fadingEffect {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes fadingEffect {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
