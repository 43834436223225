@import "../../../lib/scss/variables";

.CalendarModalWizard {
  @include mobile {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .modal-content {
    border-radius: $radius;
  }
}
