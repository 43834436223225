@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.WorkplaceRow {
  &__toggle {
    opacity: 0;
    width: toRem(39px);
    visibility: hidden;
    margin-left: toRem(-39px);
    transition: $transition1;
    svg {
      width: toRem(39px);
    }
  }
  &__icon {
    margin-right: toRem(18px);
    @include mobile-tablet {
      margin-right: toRem(10px);
    }
    svg {
      width: toRem(48px);
      @include mobile-tablet {
        width: toRem(35px);
      }
    }
  }
  h4 {
    font-size: toRem(18px);
    line-height: 1em;
    @include mobile-tablet {
      font-size: toRem(16px);
    }
  }
  &__count {
    margin-left: auto;
  }

  &:hover {
    .WorkplaceRow {
      &__toggle {
        opacity: 1;
        margin-left: 0;
        margin-right: toRem(30px);
        visibility: visible;
      }
    }
  }
}
