@import "./mixins";

// Transitions
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);

// Colors
$tertiary: #69bc58;
$mainColor: #2cd0af;
$secondary: #808080;
$mainBgColor: #dee2e6;
$labelColor: #808080;
$errorColor: #e24a1a;
$borderColor: #ebebeb;
$warningColor: #ffc107;
$businessColor: #166c79;
$proColor: #4d4d4d;
$successColor: #156c79;

// General
$radius: toRem(10px);
$headerHeight: toRem(119px);
$sidebarWidth: toRem(139px);
$headerDistance: toRem(38px);
$sidebarDistance: toRem(84px);
$defaultBoxShadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05);
