@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.BookingsMapModal {
  .modal-header {
    border: none;
  }

  .modal-body {
    width: 60%;
    max-width: 60%;
    margin: 0 auto;

    @include mobile {
      width: 80%;
      max-width: 80%;
    }
  }

  h6 {
    margin-bottom: toRem(20px);
  }

  .SelectTimeModal {
    .react-datepicker-wrapper {
      margin-top: toRem(20px);
    }
  }

  .FindPeople {
    // box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);
    box-shadow: $defaultBoxShadow;
    cursor: pointer;

    .css-1wa3eu0-placeholder {
      // position: relative;
      // top: 0;
    }

    .css-1wa3eu0-placeholder,
    .css-tlfecz-indicatorContainer {
      color: hsl(0, 0%, 50%);
    }
  }

  .NoMatchFound {
    background-color: #e7b3bc;
    color: #b00020;
    border-radius: toRem(30px);
    font-weight: 500;
    font-size: toRem(12px);
    margin-top: toRem(10px);
    padding: toRem(3px 5px);
    width: 50%;
    display: flex;
    place-content: center;
  }
}
