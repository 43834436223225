@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.BookingsModal {
  @include mobile {
    top: 10%;
  }

  .UserViewType {
    font-size: toRem(12px);
    position: absolute;
    top: toRem(26px);
    left: toRem(25px);
  }

  .modal-content {
    align-items: center;
    padding-bottom: toRem(20px);
    grid-gap: toRem(7px);
    border: none;
    border-radius: toRem(15px);

    .modal-header {
      place-self: flex-end;
      border: none;
      svg {
        margin-right: toRem(10px);
        margin-top: toRem(10px);
        width: toRem(27px);
        border-radius: toRem(5px);
        padding: toRem(6.5px);
        cursor: pointer;

        path {
          fill: #4d4d4d;
        }

        &:hover {
          background-color: rgba(#adaeb9, 0.15);
        }
      }
    }

    .modal-body {
      padding: toRem(16px 0);
    }

    span {
      font-size: toRem(17px);
      font-weight: 400;
      line-height: toRem(29px);
      max-width: toRem(290px);
      word-break: break-word;
    }

    h5 {
      font-weight: 400;
      font-size: toRem(22px);
    }

    .LineDivider {
      width: 90%;
    }

    .location-reserve {
      svg {
        filter: invert(1);
      }
    }
    .desk-reserve {
      svg {
        // filter: invert(1);
        width: toRem(25px);
      }
    }
  }

  &__body {
    border: 1px solid $borderColor;
    border-radius: $radius;
    width: 90%;
    background-color: #f8f8f8;
    padding-top: toRem(10px);
    box-shadow: $defaultBoxShadow;

    &__subject {
      border-top: 1px solid;
    }

    .ModalRow:nth-child(2n) {
      background-color: #ffffff;
    }

    .ModalRow:last-child {
      border-radius: 0 0 $radius $radius;
    }

    .calendar-bookings {
      svg {
        width: toRem(26px);
      }
    }
    .users-icon-rounded {
      svg {
        width: toRem(22.5px);
      }
    }
    .desk_icon_18x18 {
      svg {
        width: toRem(22px);
      }
    }
    .location-rounded {
      svg {
        width: toRem(17px);
      }
    }
  }

  &__mainIcon {
    margin-bottom: toRem(15px);
    svg {
      width: toRem(105px) !important;
    }
  }

  svg {
    width: toRem(20px);
  }

  &__locations {
    max-width: 50%;
    text-align: center;
    span {
      &:not(:first-child) {
        @include iconPrefix("arrow-right");
        margin: toRem(0 5px);
      }
    }
  }

  .modal-footer {
    border-top: none;
    flex-direction: column;
    padding: 0;
  }

  .modal-footer-row {
    flex-direction: row !important;
    width: 100%;
    place-content: center;
    justify-content: space-around;
  }

  .modal-footer-row-reverse {
    flex-direction: row-reverse !important;
  }

  .react-datepicker-wrapper {
    text-align: center;
    position: relative;
    // width: unset;

    @include iconAfterPrefix("clock-icon");

    @include iconBeforePrefix("chevron-down");

    &::before {
      top: 58%;
    }
  }

  &__required {
    font-size: toRem(15px) !important;
  }
}
