@import "../../../lib/scss/mixins";

.PeopleTable {
  margin-top: toRem(15px);
  @include mobile-tablet {
    margin-top: toRem(5px);
  }
  .StructureTable {
    &__head {
      &__item {
        &.emailId {
          flex: none;
          width: 30%;
        }
        &.role {
          flex: none;
          width: 12%;
        }
        &.actions {
          flex: none;
          width: toRem(15px);
        }
        &.status {
          flex: none;
          width: 9%;
        }
        &.licensed {
          flex: none;
          width: 7%;
        }
      }
    }
    &__item {
      @include mobile-tablet {
        padding: toRem(15px);
      }
      &__column {
        &.emailId {
          flex: none;
          width: 30%;
        }
        &.role {
          flex: none;
          width: 12%;
        }
        &.actions {
          flex: none;
          width: toRem(15px);
        }
        &.status {
          flex: none;
          width: 9%;
        }
        &.licensed {
          flex: none;
          width: 7%;
        }
      }
    }
    h4 {
      line-height: 1em;
      font-weight: 400;
      font-size: toRem(14px);
    }
    .actions {
      @include mobile-tablet {
        right: 15px;
        bottom: 15px;
        margin-bottom: 0;
        position: absolute;
        width: auto !important;
      }
    }
    .status {
      @include mobile-tablet {
        top: 15px;
        right: 15px;
        margin-bottom: 0;
        position: absolute;
        width: auto !important;
        background: #fff;
        padding-left: toRem(5px);
      }
    }
    .emailId {
      @include mobile-tablet {
        @include iconPrefix("at-icon");
      }
    }
    .name {
      @include mobile-tablet {
        @include iconPrefix("people-icon");
      }
    }
    .teams {
      @include mobile-tablet {
        @include iconPrefix("teams-icon");
      }
    }
    .role {
      @include mobile-tablet {
        @include iconPrefix("users-icon");
      }
    }
    .licensed {
      @include mobile-tablet {
        margin-bottom: 0;
        @include iconPrefix("subscribe-icon");
        .Switch {
          margin: 0;
        }
        &:after {
          content: "License";
          color: gray;
          margin-left: toRem(10px);
        }
      }
    }

    .PeopleRole {
      &__info {
        position: relative;

        svg {
          width: toRem(17px);
          margin-left: toRem(5px);
        }
        &--text {
          display: none;
          visibility: hidden;
        }

        &:hover {
          .PeopleRole__info--text {
            z-index: 1;
            display: block !important;
            visibility: visible !important;
            background-color: #fff;
            border: 1px solid #dddddd;
            position: absolute;
            box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
            width: toRem(200px);
            max-width: toRem(200px);
            padding: toRem(6px);
            border-radius: toRem(6px);
            font-size: toRem(13px);
            text-align: center;
            bottom: toRem(27px);
            // left: toRem(20px);

            &::before {
              bottom: toRem(-5.5px);
              left: 5%;
              content: "";
              width: toRem(10px);
              height: toRem(10px);
              margin: auto 0;
              text-align: center;
              position: absolute;
              background: white;
              transform: rotate(45deg);
              -o-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              border-right: toRem(1px) solid #dddddd;
              border-bottom: toRem(1px) solid #dddddd;
            }
          }
        }
      }
    }
  }
}
