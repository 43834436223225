@import "../../../lib/scss/mixins";

.PaymentMethod {
  @include mobile {
    &--loading {
      grid-gap: toRem(25px) !important;
    }
  }

  .BillingInnerCard {
    min-height: toRem(120px);

    .Icon {
      width: toRem(65px);
    }

    &__content {
      justify-content: unset;
    }
  }
}
