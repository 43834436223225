@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.SearchWorkplaceForm {
  margin-top: toRem(20px);

  .time-divider {
    svg {
      width: toRem(20px);
      margin-top: toRem(30px);
    }
  }

  &--text.form-group {
    label {
      color: #161616 !important;
      font-weight: 400;
      font-size: toRem(16px) !important;
    }
  }

  .form-group {
    p {
      color: grey;
      margin: 0;
    }
  }

  input[type="time"],
  input[type="date"],
  input[type="text"] {
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid $borderColor;
    border-radius: toRem(10px) !important;
    background-color: #ffffff;
    min-height: toRem(50px);
    width: 100%;
    margin: 0 auto;
    padding-left: toRem(40px);
    margin-top: toRem(8px);

    &::placeholder {
      color: $labelColor;
    }
  }
  input[type="time"],
  input[type="text"] {
    &::before {
      content: "";
      width: toRem(16px);
      height: toRem(16px);
      margin-right: toRem(10px);
      background: url("../../../assets/icons/chevron-down.svg") no-repeat center;
    }
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);
    -webkit-appearance: none;
  }

  input[type="date"] {
    background-color: $mainColor !important;
    margin-bottom: toRem(12px);
    color: #fff;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      filter: invert(1);
      margin-right: toRem(7px);
    }
  }

  &--time {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    max-height: toRem(90px);
    overflow-x: scroll;

    span {
      font-size: toRem(16px);
    }
  }

  &__subject {
    input {
      padding-left: toRem(22px) !important;
    }
  }

  &__switch {
    .Switch,
    .Notify {
      flex: unset;
      border: 1px solid $borderColor;
      padding: toRem(6px 17px);
      border-radius: toRem(30px);

      @media (max-width: 435px) {
        padding: toRem(6px 7px);
      }

      .form-check {
        gap: toRem(10px);
      }

      &__input {
        background-color: #adaeb9;
        width: toRem(35px);
        height: toRem(22px);
        border-color: transparent;
      }

      &--selected {
        .Switch__input {
          background-color: $mainColor;
        }
      }

      &__toggle {
        background-color: #ebebeb;
        width: toRem(14px);

        &--active {
          background-color: #fff !important;
          transform: translateX(100%) !important;
        }
      }
    }

    label {
      color: #000;

      @media (max-width: 500px) {
        font-size: toRem(12px);
      }
    }
  }

  .TimeSelector {
    background-color: #fff;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);
    border: 1px solid $borderColor;
    border-radius: toRem(10px) !important;
    min-height: toRem(50px);
    width: 100%;
    margin: 0 auto;
    padding-left: toRem(40px);
    margin-top: toRem(8px);
    position: relative;

    span {
      color: $labelColor;
    }

    @include iconAfterPrefix("duration-icon");

    @include iconBeforePrefix("chevron-down");

    &::after {
      top: 51%;
    }
    &::before {
      top: 52%;
    }
  }

  .BookOnBehalf {
    label {
      color: #161616 !important;
      font-weight: 400;
      font-size: toRem(16px) !important;
    }

    .Switch {
      padding: 0;
      border: none;

      .form-check {
        max-width: unset;
      }
    }

    .FindPeople {
      margin-top: toRem(5px);
    }

    .Notify {
      padding: toRem(12px 15px 3px 15px);

      width: unset !important;

      label {
        margin-bottom: toRem(5px) !important;
      }

      p {
        font-size: toRem(14px);

        @media (max-width: 500px) {
          font-size: toRem(12px);
        }
      }
    }
  }

  .react-datepicker-wrapper {
    position: relative;
    input {
      cursor: pointer;
      padding-left: toRem(47px);
      color: $labelColor;
    }

    @include iconAfterPrefix("clock-icon");

    @include iconBeforePrefix("chevron-down");

    &::before {
      top: 58%;
    }
  }

  &__couple {
    display: flex;
    justify-content: space-evenly;

    .Button {
      padding: toRem(8px) !important;
    }

    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: toRem(10px);
    }
  }

  &__button {
    background-color: #fff !important;
    border-color: $mainColor !important;
    width: toRem(100px);
    border-radius: toRem(30px) !important;
    min-height: toRem(2px) !important;

    &--tags {
      width: toRem(90px) !important;
      min-width: toRem(90px) !important;

      @include mobile {
        width: toRem(150px) !important;
        min-width: toRem(150px) !important;
      }

      svg {
        width: toRem(16px) !important;
      }
    }

    // &.btn-small {
    //   min-width: toRem(120px) !important;
    //   width: toRem(120px) !important;
    // }

    &.Button .Icon {
      position: unset;
    }

    &.active {
      background-color: $mainColor !important;
      span {
        color: #fff !important;
      }
      svg {
        filter: brightness(0) invert(1);
      }
    }

    svg {
      path {
        fill: $mainColor !important;
      }
    }
    span {
      font-size: toRem(14px) !important;
      color: $mainColor !important;
    }
  }

  &__location {
    text-align: start !important;
    justify-content: flex-start;
    padding-left: toRem(50px) !important;
    min-width: 100%;
    min-height: toRem(50px);
    border-radius: toRem(10px) !important;
    border: 1px solid $borderColor !important;

    span {
      font-size: toRem(16px);
      color: $labelColor;
      padding-top: toRem(3px);
    }

    svg {
      width: toRem(12px) !important;

      path {
        fill: #000 !important;
      }
    }

    &::before {
      content: "";
      width: toRem(20px);
      height: toRem(10px);
      margin-right: toRem(10px);
      background: url("../../../assets/icons/chevron-simple-right.svg")
        no-repeat center;
      position: absolute;
      z-index: 9;
      right: toRem(4px);
      top: 52%;
      transform: translate(-50%, -50%);
    }
  }

  &__capabilities {
    span {
      margin-left: auto;
    }
  }

  &__people {
    cursor: pointer;
    place-content: center;
    padding-top: toRem(20px) !important;
    padding-bottom: toRem(10px);
    position: relative;

    &--counter {
      span {
        position: absolute;
        right: toRem(25px);
        font-size: toRem(16px);
        border: 1px solid #000;
        border-radius: 50%;
        width: toRem(28px);
        height: toRem(28px);
        display: flex;
        place-content: center;

        @include mobile {
          right: 0;
        }
      }
    }

    &--toggler {
      padding-right: toRem(4px);

      svg {
        width: toRem(42px);
      }
    }

    .css-1uccc91-singleValue {
      color: #000;
    }
  }

  // custom style for Select:
  .css-i0syzg-menu {
    z-index: 10;
  }
  .css-wpyx0x-control,
  .css-imjbp4-control,
  .SelectTimeModal .css-yk16xz-control,
  .SelectTimeModal .css-1pahdxg-control {
    background-color: $mainColor !important;
    border-radius: toRem(10px) !important;
    position: relative !important;
    border-color: $mainColor !important;
    height: toRem(50px) !important;
    padding: toRem(0 10px);
    cursor: pointer;
    &::before {
      content: "";
      width: toRem(20px);
      height: toRem(20px);
      margin-left: toRem(10px);
      background: url("../../../assets/icons/calendar-white-icon.svg") no-repeat
        center;
    }
  }

  // .css-tlfecz-indicatorContainer {
  //   color: #4d4d4d;
  // }

  .css-17ppq69-indicatorContainer,
  .css-1wa3eu0-placeholder,
  .css-1uccc91-singleValue {
    color: #fff;
  }

  .css-145vlm6-indicatorSeparator {
    display: none;
  }

  .css-g1d714-ValueContainer {
    height: toRem(50px);
    padding: toRem(2px 4px);
  }

  .css-b8ldur-Input {
    margin: unset;
    padding-bottom: unset;
    padding-top: unset;

    input {
      min-height: unset !important;
    }
  }

  .MultipleVal {
    &__value-container {
      height: unset !important;
    }
    &__control {
      min-height: toRem(50px) !important;
      height: unset !important;
    }
  }

  &__createableOptions {
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);
    border-radius: toRem(10px);

    input[type="text"] {
      min-height: unset;
    }

    .css-1wa3eu0-placeholder {
      color: #b8b8b8;
    }

    .css-26l3qy-menu {
      margin-top: toRem(40px);
    }
  }
  .css-1uccc91-singleValue {
    top: 50% !important;
  }

  .css-yk16xz-control {
    height: toRem(50px);
    border-radius: toRem(10px);
    border-color: $borderColor;
  }

  .css-1hwfws3 {
    height: 80%;
  }

  .MultipleVal__indicator {
    color: #4d4d4d;
  }

  .PersonSelectItem {
    img {
      width: toRem(35px);
      border-radius: 50%;
    }

    &__creds {
      width: toRem(35px);
      height: toRem(35px);
      border-radius: 50%;
      background-color: #c6c6c6;
      display: flex;
      place-content: center;
      align-items: center;
      color: #fff;
    }

    &__fullName {
      color: #000;
    }
  }

  .FindPeople {
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);

    &.FindPeopleSearchDesk {
      border-radius: toRem(10px);

      .css-1uccc91-singleValue {
        transform: translateY(-50%);
      }
      .css-tlfecz-indicatorContainer {
        color: hsl(0, 0%, 80%);
      }
      .css-1wa3eu0-placeholder {
        color: #b8b8b8;
      }
    }

    .css-1uccc91-singleValue {
      color: #000;
      transform: unset;

      //firefox mozilla only:
      @-moz-document url-prefix() {
        transform: translateY(-50%);
      }
    }
  }
}
