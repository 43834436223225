@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.ReservationListCard {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: toRem(550px);
  background-color: #fff;
  border-radius: toRem(10px);
  border: 1px solid #ebebeb;
  padding: toRem(12px 20px);
  margin: toRem(15px 0);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &__time {
    gap: toRem(16px);
  }

  &__name {
    max-width: 70%;
    overflow: hidden;
    gap: toRem(8px);
  }

  &__icon {
    &--attendee {
      visibility: hidden;
      position: absolute;
      background-color: #fff;
      transition: all 0.2s ease-in;
      position: absolute;
      padding: toRem(5px 10px);
      border-radius: toRem(12px);
      box-shadow: 0px 0px 27px rgb(0 0 0 / 10%);

      span {
        font-size: toRem(12px);
      }

      &::before {
        bottom: toRem(24.5px);
        left: 10%;
        content: "";
        width: toRem(10px);
        height: toRem(10px);
        margin: auto 0;
        text-align: center;
        position: absolute;
        background: white;
        transform: rotate(225deg);
        -o-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        border-right: toRem(0.5px) solid #fff;
        border-bottom: toRem(0.5px) solid #fff;
      }
    }

    &:hover div {
      visibility: visible;
      z-index: 10;
    }
  }

  .actions-icon {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    svg {
      width: toRem(4px);
    }
  }

  svg {
    width: 20px;
  }

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__warning {
    h6 {
      opacity: 0.65;
    }
  }

  .DropdownList {
    &--extended {
      min-width: toRem(210px);
      padding: toRem(12px 0);
    }

    li:not(:last-child) {
      border-bottom: 1px solid $borderColor;
      margin-bottom: toRem(5px);
    }

    li {
      margin-left: toRem(20px);
      margin-right: toRem(20px);
      padding-left: 0;
      width: unset;

      &:hover {
        h5 {
          color: $mainColor;
        }

        .modify-reserve,
        .checkout-small,
        .show-extend-icon {
          svg {
            path {
              fill: $mainColor;
            }
          }
        }

        .open-map-icon,
        .delete-default,
        .checkin-small {
          svg {
            path {
              stroke: $mainColor;
            }
          }
        }
      }
    }
  }

  .InputDescription {
    position: absolute;
    bottom: toRem(10px);
  }

  &:hover {
    border-color: $mainColor;

    .actions-icon {
      display: block !important;
    }

    .rooms-icon,
    .room_icon_18x18,
    .desk_icon_18x18,
    .people-icon-bold {
      svg {
        background: rgba(44, 208, 175, 0.3);
      }
    }
  }

  .AttendeeRoomInfo {
    .InputDescription {
      position: relative;
      display: flex;
      align-items: center;
      bottom: toRem(4px);
      margin-top: toRem(1px);

      h5 {
        margin-right: toRem(11px);
        font-size: toRem(11.5px);
        padding-top: toRem(3.2px);
      }

      p {
        margin-bottom: 0;
        font-size: toRem(11.5px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: toRem(3.2px);
      }

      svg {
        border-radius: unset;
        background-color: unset;
        padding: 0;
        width: toRem(13px);
        margin-right: toRem(3px);

        path {
          stroke: $secondary;
        }
      }

      @media (max-width: 530px) {
        flex-direction: column;
        align-items: flex-start;

        .Icon {
          display: none;
          visibility: hidden;
        }

        p {
          @include iconPrefix("rooms-icon");
          display: flex;
          margin-top: 2px;

          &::before {
            width: toRem(13px);
            height: toRem(19px);
          }
        }
      }
    }
  }
}
