@import "../../lib/scss/mixins";
@import "../../lib/scss/variables";

.Error404 {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: toRem(70px);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  p {
    color: black;
    font-size: toRem(24px);
    line-height: 1em;
    font-style: normal;
    margin-bottom: toRem(15px);
    font-weight: normal;
    margin-bottom: toRem(30px);
    &.code {
      font-size: toRem(128px);
      margin-bottom: toRem(58px);
    }
  }
  span {
    font-size: toRem(16px);
    color: $labelColor;
    font-style: normal;
    font-weight: normal;
    margin-bottom: toRem(-16px);
    line-height: 1.1875em;
  }
}
