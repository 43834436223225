@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.SidebarSubMenu {
  left: 0;
  opacity: 0;
  z-index: -1;
  height: 100%;
  cursor: auto;
  width: toRem(260px);
  position: fixed;
  overflow: hidden;
  top: calc(
    #{$headerHeight} + (var(--providers-count) + var(--directory-count)) * 56px
  );
  visibility: hidden;
  background: white;
  padding-bottom: toRem(120px);
  transition: $transition1;

  @include mobile-tablet {
    top: calc(
      #{toRem(80px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );
  }

  &--adjustable {
    top: calc(
      #{toRem(185px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );

    @include mobile-tablet {
      top: calc(
        #{toRem(145px)} + (var(--providers-count) + var(--directory-count)) * 56px
      );
    }
  }

  &__title {
    margin-bottom: 0;
    line-height: 1em;
    font-size: toRem(16px);
    // white-space: nowrap;
    @include mobile {
      font-size: toRem(14px);
    }
  }
  &__item {
    a {
      color: $labelColor;
      position: relative;
      align-items: center;
      transition: $transition1;
      text-decoration: none !important;
      &:before {
        top: 50%;
        opacity: 0;
        left: toRem(10px);
        width: toRem(4px);
        height: toRem(4px);
        content: " ";
        position: absolute;
        border-radius: $radius;
        background: $mainColor;
        transition: $transition1;
        transform: translateY(-50%);
      }
      &.active,
      &:hover {
        color: $mainColor;
        svg {
          color: $mainColor;
          rect {
            stroke: $mainColor;
          }
          path {
            stroke: $mainColor;
          }
        }
        .teams-icon,
        .people-icon,
        .settings-main,
        .floor-map-2,
        .reserved-icon,
        .wayFinder_icon_18x18,
        .directory-icon,
        .binoculars-icon {
          svg {
            rect,
            path {
              stroke: none !important;
              fill: $mainColor !important;
            }
          }
        }
      }
      &.active {
        &:before {
          opacity: 1;
        }
      }
      svg {
        color: black;
        rect,
        path {
          transition: $transition1;
        }
      }
    }
  }

  .Reserve.active {
    color: inherit;

    &:hover {
      color: $mainColor;

      svg {
        color: $mainColor;

        path {
          color: $mainColor;
          stroke: $mainColor;
        }
      }
    }

    svg {
      color: #000;

      path {
        color: #000;
        stroke: #000;
      }
    }

    &::before {
      opacity: 0;
    }
  }
}
