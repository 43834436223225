@import "../../../lib/scss/mixins";

.ErrorMessage {
  padding-top: toRem(32px);
  font-size: toRem(30px);
  max-width: 375px;
  margin: 0 auto;

  @include mobile {
    font-size: toRem(22px);
  }
}
