@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.ModalMFA {
  max-width: toRem(700px) !important;

  .modal-header {
    border-bottom: none;
    padding: toRem(0 24px);
    padding-top: toRem(20px);

    h5 {
      font-weight: 400;
    }
  }

  .modal-body {
    padding: toRem(10px 24px);
  }

  .modal-content {
    border: none;
  }

  .modal-footer {
    @include fullWidthTwoButtons;
  }

  &__key {
    svg {
      width: toRem(15px);
      margin-top: toRem(2.5px);
    }

    span {
      padding-left: toRem(5px);
      font-size: toRem(14px);
      color: $proColor;
      font-weight: 300;
    }

    a {
      font-weight: 300;
    }
  }

  .ModalResponse {
    &__content {
      display: flex;
      flex-direction: column;
      place-items: center;
      gap: toRem(10px);
      margin: toRem(40px 0);

      h6 {
        font-weight: 400;
        font-size: toRem(17px);
      }

      svg {
        width: toRem(55px);
      }

      &--disable {
        svg {
          path {
            fill: #808080;
          }
        }
      }
    }
  }

  .MfaSecondaryEmail {
    &__secondaryEmail {
      max-width: 70%;

      @include mobile {
        max-width: 100%;
      }
    }
    &__success {
      gap: toRem(8px);
      margin-top: toRem(7px);

      span {
        color: $mainColor;
        font-size: toRem(13px);
      }

      svg {
        width: toRem(18px);
      }
    }
  }

  &.MfaReAuthUser {
    .form-group {
      margin-top: toRem(35px);
      margin-bottom: toRem(60px);
    }
    .invalid-feedback {
      font-size: toRem(14px) !important;
    }

    input {
      max-width: 70%;

      @include mobile {
        max-width: 100%;
      }
    }

    .PasswordInput__icon {
      right: 32%;

      @include mobile {
        right: 3%;
      }
    }
  }

  .MfaTypePicker {
    &__option {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 1px solid $borderColor;
      border-radius: toRem(6px);
      margin-bottom: toRem(25px);
      padding: toRem(16px 0);
      cursor: pointer;
      max-width: 70%;

      @include mobile {
        max-width: 100%;
      }

      h6 {
        font-weight: 400;
      }

      input[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: toRem(20px);
        height: toRem(20px);
        border: 2px solid black;
        border-radius: 50%;
        position: relative;
      }

      input[type="radio"]:checked::before {
        content: "";
        display: block;
        width: toRem(11px);
        height: toRem(11px);
        background-color: black;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--totp {
        background-color: rgb($mainColor, 30%);
        border-color: $mainColor;
      }

      span {
        color: $proColor;
        font-size: toRem(13px);
        width: toRem(330px);
        display: block;
      }

      svg {
        width: toRem(35px);
      }

      &.disabled {
        margin-bottom: toRem(50px);
      }
    }
  }

  &__secret {
    letter-spacing: 0.1em;
    margin-top: toRem(5px);
    margin-bottom: toRem(40px);
  }

  .form-info {
    color: $labelColor;
    position: absolute;
    font-weight: 300;
    bottom: 12%;
  }

  .invalid-feedback {
    font-size: toRem(14px) !important;
  }
}
