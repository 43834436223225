@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.SidebarOverlay {
  opacity: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  visibility: visible;
  transition: $transition1;
  background: rgba(255, 255, 255, 0.5);
  &--visible {
    z-index: 7;
    opacity: 1;
    visibility: visible;
  }

  &--showBackground {
    @include desktop {
      display: unset !important;
    }
  }
  // @include desktop {
  //   display: none;
  // }
}
