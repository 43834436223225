@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.DirectoryIntegrationsHeader {
  h1 {
    @include mainHeader;
  }

  &__content {
    padding-bottom: toRem(10px);
    border-bottom: 3px solid #ebebeb;
  }

  .DirectoryIntegrationsHeaderButtons {
    &__auto {
      gap: toRem(5px);
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      padding: toRem(0px 10px);

      span {
        color: #808080;
        font-size: toRem(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &--on {
        color: $mainColor !important;
      }

      &--off {
        color: $errorColor !important;
      }
    }

    svg {
      width: toRem(20px);
    }

    .adjustable-button {
      position: relative !important;
    }
  }
}
