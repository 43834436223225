@import "../../../lib/scss/mixins";

.TeamList {
  &__teams {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-gap: toRem(20px);

    @include mobile {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }

    @include small-desktop {
      margin-bottom: toRem(25px);
    }
  }
}
