@import "../../../lib/scss/mixins";

.CalendarAccountsList {
  flex: 1 1;
  margin-top: toRem(15px);
  .StructureTable {
    h4 {
      font-size: toRem(14px);
    }
    &__head {
      @include mobile-tablet {
        display: none;
      }
      &__item {
        &.name {
          max-width: toRem(300px);
        }
        &.email {
          max-width: toRem(280px);
        }
      }
    }
    &__item {
      @include mobile-tablet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &__column {
        &.name {
          max-width: toRem(300px);
        }
        &.email {
          max-width: toRem(280px);
          @include mobile-tablet {
            @include iconPrefix("calendar-icon");
          }
        }
        &.room {
          @include mobile-tablet {
            @include iconPrefix("rooms-icon");
          }
        }
      }
    }
  }
  &__name {
    @include mobile-tablet {
      font-size: toRem(18px) !important;
    }
  }
}
