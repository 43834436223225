@import "../../lib/scss/mixins";
@import "../../lib/scss/variables";

.Header {
  top: 0;
  position: fixed;
  background: white;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3);
  z-index: 999;
  @include mobile-tablet {
    z-index: 6;
    max-width: 100vw;
    padding-bottom: 1rem;
    border-bottom: toRem(1px) solid;
  }
  .main_navigation {
    &__item {
      transition: $transition1;
      &:hover,
      &:focus,
      &--active {
        color: $mainColor !important;
      }
      @include mobile-tablet {
        margin-bottom: 1rem;
        margin-right: 0 !important;
      }
    }
  }
  &__navbar {
    display: flex;
    position: relative;
    height: $headerHeight;
    padding: toRem(24px) !important;
    justify-content: space-between !important;
    @include mobile-tablet {
      height: auto;
      padding: toRem(15px) !important;
    }
  }
  &__hamburger {
    display: block;
    cursor: pointer;
    width: toRem(20px);
    height: toRem(13px);
    background: url("../../assets/icons/hamburger.svg") no-repeat center;
    background-size: contain;
    @include desktop {
      display: none;
    }
  }
  &__logo {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    @include mobile-tablet {
      top: auto;
      left: auto;
      transform: none;
      margin-left: auto;
      position: relative;
    }

    .Beta {
      left: toRem(135px);

      @include mobile-tablet {
        left: toRem(103px);
        top: toRem(-17px);
        padding: toRem(1px 16px);
        font-size: toRem(11px);
      }
    }

    img {
      max-width: toRem(146px);
      @include mobile-tablet {
        max-width: toRem(110px);
      }
    }
  }
  svg {
    &.fa-bars {
      cursor: pointer;
    }
  }

  .HelpCenter {
    background-color: $secondary;
    color: #fff;
    padding: toRem(5px 25px);
    border-radius: toRem(35px);
    line-height: 1em;
    font-size: toRem(16px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    transition: $transition1;
    position: relative;

    svg {
      display: none;
    }

    span {
      vertical-align: middle;
      padding-left: toRem(5px);
    }

    &::before {
      content: "";
      display: inline-flex;
      width: toRem(24px);
      height: toRem(24px);
      vertical-align: middle;
      background: url("../../assets/icons/help-icon.svg") no-repeat center;

      @include mobile {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      text-decoration: none;
      opacity: 0.9;
    }

    @include mobile-tablet {
      padding: toRem(5px 20px);

      font-size: toRem(14px);
    }

    @include mobile {
      width: 2.375rem;
      height: 2.375rem;
      min-width: auto;
      border-radius: 100%;

      span {
        display: none;
        visibility: hidden;
      }
    }
  }
}
