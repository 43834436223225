@import "../../../lib/scss/mixins";

.UnLinkButton {
  cursor: pointer;
  svg {
    width: toRem(18px);
  }
  path {
    fill: #adaeb9;
  }
}
