@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.TotpCodeInput {
  gap: toRem(10px);
  flex-wrap: nowrap;

  &__group {
    input {
      width: toRem(37px);
      max-width: toRem(37px);
      height: toRem(50px);
      border: 1px solid #ebebeb;
      font-size: toRem(22px);
      text-align: center;
      padding-bottom: toRem(5px);

      &:focus {
        outline: none;
        border-color: $mainColor;
      }
    }

    &--index-1 {
      border-top-left-radius: toRem(4px);
      border-bottom-left-radius: toRem(4px);

      //need to specify it for mobile devices:
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &--index-2 {
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;

      &:focus {
        border-color: $mainColor;
        border-left: 1px solid $mainColor !important;
        border-right: 1px solid $mainColor !important;
      }
    }
    &--index-3 {
      border-bottom-right-radius: toRem(4px);
      border-top-right-radius: toRem(4px);

      //need to specify it for mobile devices:
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__divider {
    width: toRem(10px);
    height: toRem(2px);
    background-color: #ebebeb;
    border-radius: toRem(10px);
  }
}
