@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.BookingsMapPopUp {
  @include dynamicScroller;

  &__head {
    border-bottom: 0.5px solid $proColor;
    padding-bottom: toRem(10px);
  }
  &__content {
    text-align: center;
    min-width: toRem(150px);
    margin-top: toRem(25px);
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: toRem(14px);

    &--equipment {
      grid-gap: toRem(8px);

      svg {
        width: toRem(30px) !important;
      }
    }

    &--seats {
      span {
        color: $proColor;
      }
      svg {
        path {
          stroke: $proColor;
        }
      }
    }

    &--user {
      img {
        width: toRem(32px);
        height: toRem(32px);
        object-fit: cover;
        border-radius: 100%;
      }

      span {
        color: $proColor;
      }

      .user-initials {
        width: toRem(32px);
        height: toRem(32px);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user-initials,
      svg {
        background-color: #ebebeb;
        border-radius: 100%;
      }

      svg {
        padding: toRem(7.5px);
        width: toRem(29.5px) !important;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 0.5px;
    background-color: $proColor;
    margin: toRem(16px) 0;
  }

  &__title {
    font-size: toRem(18px);
    padding-right: toRem(12px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: start;
  }

  svg {
    width: toRem(15px);
  }

  &__ModalRow {
    width: 100%;
    border-radius: toRem(10px);
    background-color: #cce0f1;

    svg {
      margin-bottom: toRem(5px);
      width: toRem(25px);
      height: toRem(25px);

      path,
      rect {
        stroke: $proColor;
      }
    }

    span {
      font-size: toRem(14px);
      color: $proColor;
    }
  }

  &__button {
    &--reserve {
      width: 100%;
      max-width: unset;
      min-height: unset;
      padding: toRem(10px 0) !important;
      font-size: toRem(14px) !important;
      min-width: toRem(130px);

      span {
        margin-top: unset !important;
        font-size: toRem(14px);
      }

      .Icon {
        position: initial;

        svg {
          width: toRem(9px);
          path {
            fill: unset;
          }
        }
      }
    }
  }

  .BookingsMapPopUp {
    &__availability {
      svg {
        width: toRem(10px);
      }
    }
  }

  .PopUpTags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: toRem(5px);
    max-height: toRem(60px);
    overflow-y: scroll;
    width: 100%;

    &__tag {
      border: 1px solid $proColor;
      border-radius: toRem(30px);
      color: $proColor;
      padding: toRem(0px 15px);
      line-height: 19px;
      margin-right: toRem(3px);
      margin-bottom: toRem(3px);

      svg {
        path {
          fill: $proColor;
        }
      }
    }
  }
}
