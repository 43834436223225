@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.FormSection {
  margin-bottom: toRem(16px);
  @include mobile {
    width: 100% !important;
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__title {
    color: #808080;
    font-weight: 300;
    line-height: 1.2em;
    font-size: toRem(18px);
    margin-bottom: toRem(10px);
  }
  .Icon.chevron {
    width: toRem(15px);
    cursor: pointer;
    margin-left: toRem(5px);
    transition: $transition1;
  }
  &--flex {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    .FormSection {
      &__content {
        flex: 1 1;
      }
    }
  }
  &--switch,
  &--toggleable {
    overflow: hidden;
    .FormSection {
      &__content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: $transition1;
      }
      &__title {
        cursor: pointer;
        margin-bottom: 0;
      }
    }
  }
  &--toggled {
    overflow: unset;
    .FormSection {
      &__content {
        opacity: 1;
        overflow: unset;
        margin-top: toRem(30px);
        max-height: toRem(9999px);
      }
    }
    .Icon.chevron {
      transform: rotate(90deg);
    }
  }
}
