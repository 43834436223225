@import "../../../lib/scss/mixins";

.PasswordInput {
  position: relative;
  &__icon {
    top: 50%;
    right: toRem(15px);
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
    svg {
      width: toRem(20px);
    }
  }
  &--invalid {
    .PasswordInput {
      &__icon {
        right: toRem(40px);
      }
    }
  }
}
