@import "../../../lib/scss/mixins";

.FloorMapActionsGroup {
  position: absolute;
  top: toRem(100px);
  left: toRem(20px);
  z-index: 800;
  display: flex;
  flex-direction: column;
  row-gap: toRem(16px);

}
