@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ResourceNavigation {
  display: flex;
  align-items: center;
  padding-bottom: toRem(10px);
  border-bottom: toRem(3px) solid #ebebeb;
  &--content {
    min-height: toRem(57px);
    @include mobile-tablet {
      min-height: auto;
    }
  }
  @include mobile-tablet {
    border-bottom: none;
    align-items: flex-end;
  }
  &__link {
    height: 100%;
    outline: none;
    color: black;
    line-height: 1.1em;
    text-align: center;
    position: relative;
    align-items: flex-end;
    display: inline-block;
    font-size: toRem(18px);
    min-width: toRem(108px);
    transition: $transition1;
    margin-bottom: toRem(-28px);
    padding: toRem(0 18px 17px);
    text-decoration: none !important;
    &:after {
      left: 0;
      opacity: 0;
      content: "";
      height: toRem(3px);
      width: 100%;
      bottom: toRem(-3px);
      position: absolute;
      visibility: hidden;
      background: $mainColor;
      transition: $transition1;
    }
    &:hover,
    &.active {
      color: $mainColor;
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
    &.active {
      pointer-events: none;
      cursor: pointer;
    }
    @include mobile-tablet {
      flex: 1;
      font-size: toRem(16px);
      margin-bottom: 0;
      padding: toRem(0 5px 15px);
      border-bottom: toRem(3px) solid #ebebeb;
    }
  }
  .AddWorkplaceDropdown {
    top: calc(100% - 5px);
    @include mobile-tablet {
      top: calc(100% + 3px);
    }
  }

  @include mobile {
    overflow-x: scroll;
  }
}
