@import "./../../../lib/scss/mixins";
@import "./../../../lib/scss/variables";

.CalendarsList {
  &__actions {
    align-self: flex-end;
  }
  &__sync {
    span {
      width: toRem(30px);
      height: toRem(30px);
      cursor: pointer;
      border-radius: 100%;
      display: inline-block;
      transition: $transition1;
      background-color: white;
      border: toRem(1px) solid #ebebeb;
      &:not(:last-child) {
        margin-right: toRem(15px);
      }
      &.full {
        background: #adaeb9;
      }
      @include small-desktop {
        width: toRem(18px);
        height: toRem(18px);
        &:not(:last-child) {
          margin-right: toRem(5px);
        }
      }
    }
    @include small-desktop {
      line-height: 1em;
    }
  }
  &__name {
    a {
      line-height: 1em;
      font-size: toRem(16px);
      transition: $transition1;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__type {
    font-weight: 400;
    font-size: toRem(14px);
    padding-left: toRem(7px);
  }
  .StructureTable {
    &__item {
      .type {
        svg {
          width: toRem(19px);
        }
      }
    }
    @include mobile-tablet {
      &__head {
        display: none;
      }
      &__item {
        flex-direction: column;
        align-items: flex-start;
        .actions {
          margin: 0;
          right: 20px;
          bottom: 20px;
          position: absolute;
        }
        .sync_status {
          @include iconPrefix("sync-icon");
        }
        &__column {
          margin-bottom: toRem(10px);
        }
      }
    }
    h4 {
      line-height: 1em;
      @include small-desktop {
        font-size: toRem(18px);
      }
    }
  }
  .list-row {
    padding: toRem(32px 30px);
    @include small-desktop {
      padding: toRem(20px 20px 10px);
    }
  }
}
