@import "../../../lib/scss/mixins";

.ChartBox {
  width: 100%;
  overflow: hidden;
  .default-box {
    // overflow-y: scroll;
    height: toRem(525px) !important;

    min-height: toRem(525px);
  }
  h3 {
    font-size: toRem(24px);
    line-height: 1em;
    font-weight: 400;
    margin-bottom: toRem(30px);
    @include mobile {
      font-size: toRem(18px);
      margin-bottom: toRem(20px);
    }
  }

  &__tooltip {
    background-color: #fff;
    padding: toRem(10px);
    border-radius: toRem(12px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    &--box {
      display: block;
      width: 12px;
      height: 12px;
      background: rgb(21, 108, 121);
      margin-right: 7px;
    }
  }
}
