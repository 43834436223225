@import "../../lib/scss/variables";
@import "../../lib/scss/mixins";

.RecoverAccountMFA {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: toRem(30px);

  &__info {
    color: #4d4d4d;
  }

  .ModalResponse {
    &__content {
      display: flex;
      flex-direction: column;
      place-items: center;
      gap: toRem(10px);
      padding-top: toRem(30px);

      svg {
        width: toRem(55px);
      }

      &--disable {
        svg {
          path {
            fill: #808080;
          }
        }
      }
    }
  }

  &__button {
    display: flex;
    width: 100%;
    place-content: center;
    border-top: 1px solid $borderColor;
    padding-top: toRem(10px);
    transform: translateY(10px);

    button {
      max-width: unset;
      flex: 0 1 50%;
    }
  }
}
