@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Regular.woff2") format("woff2"),
    url("SofiaPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Bold.woff2") format("woff2"),
    url("SofiaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Light.woff2") format("woff2"),
    url("SofiaPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Medium.woff2") format("woff2"),
    url("SofiaPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-SemiBold.woff2") format("woff2"),
    url("SofiaPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-UltraLight.woff2") format("woff2"),
    url("SofiaPro-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-LightItalic.woff2") format("woff2"),
    url("OpenSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Light.woff2") format("woff2"),
    url("OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-ExtraBold.woff2") format("woff2"),
    url("OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Regular.woff2") format("woff2"),
    url("OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Italic.woff2") format("woff2"),
    url("OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-BoldItalic.woff2") format("woff2"),
    url("OpenSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Bold.woff2") format("woff2"),
    url("OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("OpenSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-SemiBold.woff2") format("woff2"),
    url("OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-ExtraBoldItalic.woff2") format("woff2"),
    url("OpenSans-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

/* futura ef */

@font-face {
  font-family: "FuturaEF";
  src: url("futura-ef/FuturaEF-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
