@import "../../../../lib/scss/mixins";

.FloorMain {
  .PageSidebar__mobile_toggler {
    display: flex;
  }
  .adjustable-button {
    @include mobile-tablet {
      .btn::before {
        filter: invert(1);
      }

      svg {
        display: none;
      }
    }
  }

  .FloorPickerButton {
    .floors-icon {
      position: unset;
    }
  }
}
