@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.ConfirmationModal {
  @include mobile-tablet {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto !important;
    padding: toRem(0 15px) !important;
  }
  &__header {
    background: $mainColor;
    border: none !important;
    @include mobile-tablet {
      padding: toRem(10px) !important;
    }
    h5 {
      color: white;
      font-size: toRem(20px);
      font-weight: 300;
      @include mobile-tablet {
        font-size: toRem(16px);
      }
    }
  }
  &__body {
    border-top: toRem(1px) solid #dee2e6;
    border-bottom: toRem(1px) solid #dee2e6;
    .form-group {
      margin-bottom: 0 !important;
    }
  }
  &__footer {
    border: none !important;
    .btn {
      flex: 1;
      padding: toRem(9px);
      font-size: toRem(14px);
    }
  }
  .modal-content {
    overflow: hidden;
    border-radius: $radius;
  }
}
