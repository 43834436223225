@import "../../../../lib/scss/mixins";

.AutoSyncModal {
  &__list {
    margin-bottom: toRem(10px);
    li {
      list-style-type: disc;
      margin-left: toRem(25px);
      padding: 0;
      padding-bottom: toRem(2px);
    }
  }

  .Loaders {
    width: 85%;
    margin: auto;
    margin-bottom: toRem(10px);
  }
}
