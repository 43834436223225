@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.BookingsSettings {
  &__form {
    margin-top: toRem(25px);
    .FormSection {
      padding: toRem(30px);
      border-radius: $radius;
      margin-bottom: toRem(20px);
      background-color: white;

      .LocationSidebar {
        top: 20%;
      }

      h6 {
        color: #808080;
        font-weight: 400;
        font-size: toRem(14px);
      }

      .Button {
        .Icon {
          position: relative;
          left: auto;

          svg {
            width: toRem(14px);
          }
        }
      }

      .GeneralSettings {
        &__desks {
          display: flex;
          padding-left: 0;
          position: relative;
          margin-top: toRem(15px);

          &--label {
            min-width: toRem(245px);
          }

          @include mobile {
            flex-direction: column;
          }
        }

        &__list {
          color: #808080;
          line-height: toRem(17px);

          &--desk {
            &:not(:last-child)::after {
              display: inline-block;
              white-space: pre;
              content: ", ";
            }
          }
        }
      }

      .Calendar__info {
        margin-bottom: toRem(15px);
        max-width: toRem(150px);
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          margin-bottom: toRem(10px);
          width: toRem(30px);
        }
      }
    }

    .underline {
      color: $labelColor;
      text-decoration: underline;
      border: none;
      white-space: nowrap;
      font-size: toRem(14px) !important;
      padding: 0;
      background: none;
      &:hover {
        text-decoration: underline;
        color: $labelColor;
        opacity: 0.8;
      }
    }
  }

  .FormSection {
    &__generalSettings {
      .Switch {
        margin: unset;
      }
    }
  }
}
