@import "../../../../lib/scss/mixins";

.UserModal {
  text-align: center;

  .modal-content {
    // align-items: unset !important;
    min-height: toRem(400px);
  }

  .UserInfo {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: toRem(21px);
      padding: toRem(15px 0);
    }

    h6 {
      font-weight: 500;
      font-size: (15.5px);
      padding-left: toRem(5px);
      max-width: toRem(300px);
      word-break: break-word;
    }

    img,
    &__creds {
      width: toRem(110px);
      height: toRem(110px);
      border-radius: 50%;
    }

    &__creds {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #c6c6c6;
      margin: auto;
      color: #fff;
      font-size: toRem(30px);
      text-transform: uppercase;
    }

    &__desk {
      position: relative;
      padding: toRem(20px 0);
      gap: toRem(8px);

      &--counter {
        position: absolute;
        top: toRem(15px);
        right: toRem(-12px);
        text-align: center;
        text-align: -webkit-center;

        color: #166c79;

        &--value {
          span {
            border: 2px solid #166c79;
            border-radius: 50%;
            width: toRem(35px);
            height: toRem(35px);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: toRem(20px) !important;
          }
          .Icon {
            position: absolute;
            top: 0;
            right: toRem(12px);
          }
        }

        &--text {
          font-size: toRem(14px) !important;
        }
      }
    }

    &__empty {
      color: #adaeb9;
      font-size: toRem(17px);
      font-weight: 400;
      padding: toRem(20px 0);
    }

    .UserInfoRowItem {
      span {
        padding-left: toRem(5px);
      }
    }

    .Icon {
      &.location-reserve {
        svg {
          width: toRem(14px);
        }
      }
    }
  }
}
