@import "../../../../../../lib/scss/mixins";

.WayFinderFormWorkspaces {
  &__counter {
    color: #6b32d5;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 19px;
    padding-left: toRem(13px);
  }

  &__list {
    border: 1px solid #ebebeb;
    border-radius: 1px;
    padding: toRem(7px);
    min-height: toRem(38px);

    .WayFinderFormWorkspacesItem {
      background: #ebebeb;
      border-radius: toRem(15px);
      white-space: nowrap;
      padding: toRem(1px 7px 1px 10px);
      margin-right: toRem(5px);

      span {
        cursor: pointer;
        margin-top: toRem(-1px);
        margin-left: toRem(5px);
        vertical-align: top;
        display: inline-block;

        &:after {
          content: "x";
          padding: toRem(0px 2px);
        }
      }
    }
  }
}
