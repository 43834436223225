@import "../../../../lib/scss/mixins";

.InvitePeopleButton {
  margin-left: auto;
  position: relative;
  @include mobile-tablet {
    position: absolute;
    top: -7px !important;
  }
  &--no-margin {
    margin-left: 0;
  }

  .directory-icon {
    svg {
      path {
        fill: gray;
      }
    }
  }
}
