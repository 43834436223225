@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.AddCalendarConnections {
  color: #3a3a3a !important;
  box-shadow: none !important;
  svg {
    width: toRem(17px);
    margin-right: toRem(9px);
    circle {
      fill: $mainColor;
    }
  }
  &--small {
    margin-top: toRem(13px);
    padding-top: toRem(15px);
    border-top: toRem(1px) solid $borderColor;
  }
  &--large {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .AddCalendarConnections {
      &__toggler {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  &__content {
    display: flex;
  }
  &__toggler {
    color: black;
    cursor: pointer;
    transition: $transition1;
    outline: none !important;
    &:active,
    &:hover {
      color: $mainColor !important;
    }
    span {
      font-size: toRem(16px);
      line-height: 1em;
      font-weight: 500;
    }
  }
  &__large-icon {
    svg {
      width: toRem(200px);
      @include desktop {
        width: toRem(307px);
      }
    }
  }
}
