@import "../../../lib/scss/mixins";

.InfoCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05);
  border-radius: toRem(10px);
  margin-top: toRem(25px);
  padding: toRem(20px) toRem(30px) toRem(30px);

  h4 {
    color: #808080;
    font-style: normal;
    font-weight: 400;
    font-size: toRem(18px);
    line-height: toRem(21px);
    padding-right: toRem(7px);
  }

  button {
    margin-top: toRem(20px);
  }
}
