@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.SubscriptionStatus {
  width: auto;
  line-height: 1em;
  border: toRem(1px) solid;
  color: $labelColor;
  margin-top: toRem(1px);
  box-sizing: border-box;
  font-size: toRem(12px);
  margin-left: toRem(10px);
  padding: toRem(2px 10px);
  border-radius: toRem(45px);
  &--active {
    color: $mainColor;
  }
  &--inactive {
    color: $errorColor;
  }
  &--paused {
    color: $warningColor;
  }
}
