@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.LineTextDivider {
  display: flex;
  align-items: center;

  .divider {
    width: 100%;
    height: toRem(1px);
    background-color: $borderColor;
    border-radius: toRem(4px);
  }

  span {
    padding: toRem(0 16px);
    color: #adaeb9;
  }

  .Button {
    min-height: unset;
    height: toRem(25px);
    width: toRem(25px);
    margin-left: toRem(25px);
    margin-right: toRem(25px);

    .Icon {
      left: 3.5px;

      svg {
        width: toRem(16px);
      }
    }
  }
}
