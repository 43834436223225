@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CustomerSubscriptions {
  &__info {
    border: 1px solid $secondary;
    border-radius: toRem(8px);
    padding: toRem(10px);
    gap: toRem(15px);
    margin-bottom: toRem(10px);

    @include default-laptop {
      max-width: 50%;
    }

    svg {
      margin-top: toRem(5px);
      width: toRem(25px);
    }

    span {
      display: block;
      margin-bottom: toRem(10px);
      color: $secondary;
    }
  }

  &__content {
    gap: toRem(25px);

    .CustomerSubscriptionsTable {
      flex: 1 1 65%;
    }

    .InfoCard {
      flex: 1 1 35%;

      @include small-desktop-custom {
        max-width: 50%;
      }

      @include mobile-tablet {
        max-width: 100%;
      }
    }

    @include small-desktop-custom {
      flex-direction: column-reverse;
    }
  }
}
