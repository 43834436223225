@import "../../../../../../lib/scss/mixins";
@import "../../../../../../lib/scss/variables";

.ProRate {
  &__final {
    border-bottom: 1px solid $borderColor;
    margin-bottom: toRem(15px);
    justify-content: space-around;
    padding: toRem(0 35px);
    padding-bottom: toRem(7px);

    @media (max-width: 480px) {
      flex-direction: column;
      gap: toRem(10px);
    }

    .divider {
      width: toRem(1px);
      height: toRem(105px);
      background-color: $borderColor;
      padding-top: toRem(2px);
      transform: translateY(7px);

      @media (max-width: 480px) {
        display: none;
        visibility: hidden;
      }
    }
  }

  &__result {
    border: 1px solid $borderColor;
    border-radius: toRem(2px);
    text-align: center;
    padding: toRem(10px 20px);

    h6 {
      color: #adaeb9;
      text-align: center;
      font-family: FuturaEF;
      font-size: toRem(24px);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__data {
    border-bottom: 1px solid $borderColor;
    padding-bottom: toRem(15px);
    padding-left: toRem(40px);
    margin-bottom: toRem(20px);

    .LabelWithInformationBox {
      label {
        font-size: toRem(13px);
      }
    }
  }

  &__general {
    padding-left: toRem(40px);
    padding-right: toRem(5px);
    h5 {
      color: $secondary;
      font-size: toRem(17px);
      font-weight: 400;
      margin-bottom: toRem(8px);
    }

    span {
      color: $secondary;
      display: block;
      margin-bottom: toRem(15px);
      font-weight: 300;
    }
  }
}
