@import "../../../src/lib/scss/mixins";
@import "../../../src/lib/scss/variables";

.Sidebar {
  left: -100%;
  width: auto;
  display: flex;
  position: fixed;
  top: calc(
    #{$headerHeight} + (var(--providers-count) + var(--directory-count)) * 56px
  );

  background: white;
  transition: $transition1;
  height: calc(100vh - 6.5625rem);

  @include mobile-tablet {
    top: calc(80px + (var(--providers-count) + var(--directory-count)) * 56px);

    height: calc(100vh - 5rem);
  }

  &--adjustable {
    top: calc(
      #{toRem(185px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );

    @include mobile-tablet {
      top: calc(
        #{toRem(144px)} + (var(--providers-count) + var(--directory-count)) * 56px
      );
    }
  }

  @include small-desktop {
    z-index: 1999;
  }

  //this is a quick fix, not the best practice. we re-adjusted the breakpoints and there was a bug with sidebar on 1200px
  //which below command fixes:
  @media (min-width: 1200px) and (max-width: 1200px) {
    top: calc(67px + (var(--providers-count) + var(--directory-count)) * 56px);
  }

  @include desktop {
    left: 0 !important;
  }
  &--visible {
    left: 0;
  }
  &--sub-active {
    .SidebarSubMenu {
      opacity: 1;
      z-index: 1;
      left: toRem(120px);
      visibility: visible;
      @include mobile {
        left: 100px;
        width: toRem(185px);
      }
    }
  }
  &__menu_container {
    height: 100%;
    width: toRem(120px);
    .ps__rail-y {
      z-index: 10;
      opacity: 0.3;
    }
  }
  a {
    color: inherit;
  }
}
