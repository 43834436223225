@import "../../../lib/scss/mixins";

.BillingInfo {
  @include mobile-tablet {
    margin: 0 auto;
  }

  &--loading {
    width: toRem(450px) !important;
    margin: auto 0;
  }

  .BillingInfoCustomerAddress {
    span {
      display: block;
    }
  }

  .BillingInnerCard {
    min-height: unset;
    &__content--text {
      max-width: toRem(135px);
    }
  }
}
