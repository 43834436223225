@import "../../lib/scss/mixins";
@import "../../lib/scss/variables";

.Notifications {
  opacity: 0.5;
  &.show {
    svg {
      color: $mainColor;
    }
  }
  &__icon {
    display: flex;
    position: relative;
    margin-top: toRem(8px);
    img {
      height: toRem(17px);
      margin-right: toRem(-8px);
    }
    &:after {
      color: #adaeb9;
      line-height: 1em;
      text-align: center;
      border-radius: 100%;
      height: toRem(14px);
      min-width: toRem(14px);
      font-size: toRem(10px);
      padding: toRem(0px 3px);
      margin-top: toRem(-10px);
      content: attr(data-count);
      border: toRem(1px) solid #adaeb9;
    }
  }
  button {
    padding: 0;
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;
    &:hover {
      svg {
        color: $mainColor;
      }
    }
  }
  svg {
    transition: $transition1;
  }
  &__badge {
    font-size: toRem(10px) !important;
    padding: toRem(2px 4px) !important;
    top: toRem(-10px) !important;
  }
}
