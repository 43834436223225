@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.PageDescription {
  display: flex;
  border: 1px solid #adaeb9;
  border-radius: toRem(10px);
  padding: toRem(16px);
  width: 60%;
  margin-top: toRem(40px);

  svg {
    width: toRem(35px);
  }

  &__info {
    color: $labelColor;
    font-size: toRem(15px);
  }

  @include mobile-tablet {
    margin-top: toRem(20px);
  }

  @include mobile {
    width: 100%;
  }
}
