@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.UserNavHeader {
  display: flex;
  align-items: center;
  gap: toRem(18px);

  h6 {
    white-space: initial !important;
    min-width: toRem(190px);
    text-align: center;
    @include mobile-tablet {
      min-width: toRem(172px);
      padding: 0.5rem 1rem;
    }
  }
  &__avatar {
    cursor: pointer;
    img {
      width: toRem(49px);
      height: toRem(49px);
      object-fit: cover;
      border-radius: 100%;
    }
  }
  &__name {
    font-size: toRem(16px);
    margin-bottom: 0;
    color: $labelColor;
    @include small-desktop {
      display: none;
    }
  }
  .dropdown-menu {
    transform: translate3d(-125px, 55px, 0px) !important;
  }

  &__link {
    color: #212529;

    &:hover {
      text-decoration: none;
      color: #16181b;
    }
  }
}

@media only screen and (max-width: 767px) {
  .UserNavHeader {
    width: 100%;
    &__avatar {
      width: toRem(49px);
      height: toRem(49px);
      img {
        width: toRem(49px);
        height: toRem(49px);
      }
    }
  }
}
