@import "../../../../lib/scss/mixins";

.OverviewGraphsRow {
  display: grid;
  grid-template-columns: 74.8% repeat(auto-fill, minmax(300px, 1fr));
  gap: toRem(20px);
  margin-top: toRem(25px);

  @media (max-width: 1650px) {
    grid-template-columns: 70% repeat(auto-fill, minmax(275px, 1fr));
  }

  @include mobile-tablet {
    display: unset;
  }

  &__line {
    height: toRem(500px) !important;

    min-height: toRem(500px);

    .Aggregated {
      place-content: flex-end;

      &__buttons {
        font-weight: 400;
        font-size: toRem(12px);
        border: 1px solid #2cd0af;
        color: #2cd0af;
        cursor: pointer;
        min-width: toRem(85px);
        padding: (5px 0);
        text-align: center;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));

        &--day {
          border-top-left-radius: toRem(30px);
          border-bottom-left-radius: toRem(30px);
          border-right: none;
        }

        &--month {
          border-top-right-radius: toRem(30px);
          border-bottom-right-radius: toRem(30px);
        }

        &--active {
          color: #fff;
          background-color: #2cd0af;
        }
      }
    }

    @include mobile-tablet {
      margin-top: toRem(25px);
    }
  }

  &__pie {
    grid-column: 1fr;
    width: 100%;
    height: toRem(500px) !important;
    min-height: toRem(500px);

    @include mobile-tablet {
      margin: toRem(20px 0);
    }
  }
}
