@import "../../../lib/scss/mixins";

.StructureHeader {
  margin-top: toRem(10px);
  &__title {
    display: flex;
    align-items: center;
    padding-bottom: toRem(25px);
    h1 {
      font-size: toRem(24px);
      line-height: 1em;
      margin-right: toRem(12px);
      @include mobile-tablet {
        font-size: toRem(20px);
      }
    }
    @include mobile-tablet {
      padding-top: 0;
      padding-bottom: toRem(15px);
    }
  }
  &--hide-border {
    .StructureHeader {
      &__title {
        border-bottom: none;
        padding-bottom: toRem(10px);
      }
    }
  }
}
