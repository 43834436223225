@import "../../../lib/scss/mixins";

.SearchBox {
  &__form {
    width: toRem(180px);
    position: relative;
    input {
      width: 100%;
      height: toRem(25px);
      outline: none;
      font-size: toRem(14px);
      box-shadow: none;
      padding: toRem(0px 32px) !important;
      background: transparent;
      border: toRem(1px) solid #c5c4c4 !important;
      border-radius: toRem(54px) !important;
    }
    button {
      left: 0;
      width: toRem(30px);
      height: 100%;
      border: none;
      z-index: 4 !important;
      position: absolute !important;
      background: transparent url("../../../assets/icons/search-icon.svg")
        no-repeat center;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__disable {
    z-index: -1;
    user-select: none;
  }

  &__btn {
    &_clear {
      right: 0;
      left: unset !important;
      background: transparent url("../../../assets/icons/close-icon.svg")
        no-repeat center !important;
    }
  }
}
