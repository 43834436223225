@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.VerifyMfa {
  &__info {
    margin-bottom: toRem(30px);

    span {
      font-size: toRem(14px);
      color: $proColor;
    }

    &--app {
      color: #000 !important;
      padding-left: toRem(3px);
    }
  }

  &__buttons {
    margin-top: toRem(40px);
    margin-bottom: toRem(12px);
    justify-content: space-evenly;
    gap: toRem(10px);
    flex-wrap: wrap;

    button {
      flex: 0 1 40%;
    }
    .btn-small {
      padding: toRem(12px) !important;
    }
    .Button {
      max-width: unset;
    }

    @media screen and (max-width: 430px) {
      flex-direction: column-reverse;
    }
  }

  &__totp {
    display: flex;
    flex-direction: column;
    place-items: center;

    .invalid-feedback {
      width: unset;
      margin-right: toRem(205px);

      @include mobile-tablet {
        margin-right: toRem(210px);
      }
    }
  }

  &__singleButton {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    // bottom: 1%;

    @include mobile-tablet {
      bottom: 1%;
    }

    button {
      max-width: unset;
    }
  }

  &__options {
    button {
      padding-left: 0 !important;
      max-width: unset !important;
    }
  }

  &__success {
    gap: toRem(8px);

    span {
      color: $mainColor;
      font-size: toRem(14px);
    }

    svg {
      width: toRem(18px);
    }
  }

  &__input {
    max-width: 70%;

    @include mobile {
      max-width: 100%;
    }
  }

  .PasswordInput {
    &__icon {
      right: 32%;

      @include mobile {
        right: 3%;
      }
    }
  }
}
