@import "../../../../lib/scss/mixins";

.SidebarSubMenuList {
  display: none;
  list-style: none;
  .Icon {
    height: auto;
    width: toRem(19px);
    margin-right: 0.5rem;
    &.people-icon {
      width: toRem(16px);
      margin-right: toRem(11px);
    }
    @include mobile {
      width: toRem(16px);
    }
  }
}
