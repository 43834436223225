@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.TemplateManage {
  &__head {
    &--cancel {
      min-width: toRem(180px) !important;

      @include mobile {
        min-width: toRem(155px) !important;
      }
    }

    &--buttons {
      @include mobile-tablet {
        flex: 1 1 100%;
        justify-content: flex-end;
      }
    }

    @include mobile-tablet {
      flex-wrap: wrap;
      gap: toRem(10px);
    }

    @include mobile {
      justify-content: flex-end !important;
    }
  }

  &__subTitle {
    margin-top: toRem(26px);

    svg {
      width: toRem(28px);
    }

    h6 {
      color: #4d4d4d;
    }
  }

  &__info {
    display: flex;
    gap: toRem(10px);
    border: 1px solid #adaeb9;
    border-radius: toRem(10px);
    padding: toRem(10px);
    width: 60%;

    svg {
      width: toRem(25px);
    }

    span {
      color: $labelColor;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__buttons {
    position: relative;
    .DropdownList {
      li {
        width: 80%;
        margin: 0 auto;
        padding-left: 0;

        h5 {
          color: $labelColor;
          font-size: toRem(14px);
          font-weight: 400;

          @include mobile-tablet {
            font-size: toRem(13px);
          }
        }

        &:first-child {
          border-bottom: 1px solid $borderColor;
        }
      }
    }
  }

  .PageHeader {
    padding-bottom: toRem(2px);

    .StructureHeader {
      padding-bottom: toRem(8px);

      &__title {
        padding-bottom: 0;
      }
    }
  }

  &__room-profile,
  &__desk-profile {
    svg {
      width: toRem(40px);
    }
  }

  .RoomProfileForm {
    .RoomForm {
      &__main {
        &--group {
          flex-direction: column;
          align-items: initial;
          gap: toRem(15px);
        }

        .edit-icon {
          svg {
            width: toRem(20px);
          }
        }
      }

      &__emailReminder {
        display: flex;
        padding-top: toRem(10px);

        span {
          color: $labelColor;
        }

        .InformationBox {
          margin-left: toRem(5px);
        }
      }
    }
  }

  .DeskProfileForm,
  .RoomProfileForm {
    border-top: 2px solid $borderColor;
    padding-top: toRem(10px);
  }

  .display-customLanguage {
    width: 40%;

    @include mobile {
      width: 100%;
    }
  }
}
