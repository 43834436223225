@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CalendarSidebar {
  .PageSidebar {
    min-width: toRem(250px);
  }
  &__container {
    height: 100%;
  }
}
