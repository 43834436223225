@import "../../../lib/scss/mixins";

.DirectorySync {
  @include small-desktop {
    margin-top: toRem(24px) !important;
  }

  &__title {
    max-width: toRem(440px);
    text-align: center;
    margin: 0 auto;
  }

  .PageSidebarHeader {
    margin-bottom: 0;
  }

  .PeopleTable {
    .StructureTable {
      &__content {
        .groups {
          span {
            &:not(:last-child)::after {
              display: inline-block;
              white-space: pre;
              content: ", ";
            }
          }

          @include mobile-tablet {
            @include iconPrefix("teams-icon");
          }
        }
      }

      .IntegrationCheck {
        &.form-group .checkbox_box {
          left: toRem(-15px);
          top: toRem(-6px);

          @include mobile-tablet {
            left: toRem(5px);
          }
        }
      }
    }
  }
}
