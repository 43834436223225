@import "../../../../lib/scss/mixins";

.BillingInnerCard {
  border: 0.0625rem solid #ebebeb;
  box-sizing: border-box;
  border-radius: 0.0625rem;
  padding: 1.25rem;

  @include mobile {
    text-align: center;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(16px);
    line-height: toRem(26px);
    color: #000;
    margin-bottom: toRem(10px);
  }

  &:nth-child(1) {
    border-bottom: none;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(16px);
    margin-bottom: toRem(10px);

    @include mobile {
      flex-direction: column;
    }

    &--text {
      color: #808080;
      font-size: toRem(14px);
      max-width: toRem(270px);

      img {
        max-width: 80px;
        margin-right: toRem(16px);
      }

      .LoadingBox--image {
        width: toRem(100px);
      }
    }
  }

  .Icon {
    height: toRem(65px);
    width: toRem(130px);

    @include mobile {
      width: toRem(70px);
    }
  }

  &.Products {
    img {
      width: toRem(70px);
    }
  }
}
