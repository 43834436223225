@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.FormItem {
  &__header {
    margin-bottom: toRem(15px);

    &--main {
      svg {
        width: toRem(28px);

        @include mobile-tablet {
          width: toRem(25px);
        }
      }
    }

    .LabelWithInformationBox {
      margin-bottom: 0;
      padding-left: toRem(8px);

      label {
        margin-top: 0 !important;
        color: #000;
        font-weight: 400;
        font-size: toRem(18px);
        padding-right: toRem(5px);

        @include mobile-tablet {
          font-size: toRem(14px);
        }
      }

      .InformationBox {
        margin-top: 0;
      }
    }
  }
}
