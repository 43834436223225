@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.DebugInfo {
  min-width: toRem(300px);
  border: 1px solid #dddddd;
  background-color: #fff;
  border-radius: toRem(10px);
  box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
  padding: toRem(16px);
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);

  @include mobile {
    left: 5%;
    transform: translateX(-5%);
  }

  &::before {
    bottom: toRem(-8px);
    left: 45%;
    content: "";
    width: toRem(15px);
    height: toRem(15px);
    margin: auto 0;
    text-align: center;
    position: absolute;
    background: white;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-right: toRem(1px) solid #dddddd;
    border-bottom: toRem(1px) solid #dddddd;

    @include mobile {
      left: 25%;
    }
  }

  .DebugInfoItem__title {
    color: $labelColor;
  }

  h4 {
    color: $labelColor;
    font-size: toRem(15px);
    font-weight: normal;
    font-style: normal;
  }

  ul {
    padding-top: 1rem;
  }

  button {
    border: none;
    background-color: transparent;
    color: rgb(44, 208, 175);
    margin-top: toRem(10px);
  }

  &__outerLink {
    font-size: toRem(10px);
    font-weight: 300;
    line-height: 110%;
  }

  .InputDescription {
    margin-top: unset;
  }
}
