@import "../../../lib/scss/mixins";

.TeamItem {
  background: #fff;
  padding: toRem(15px);
  border-radius: toRem(10px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  h4 {
    font-size: toRem(18px);
    line-height: toRem(8px);
    color: #2cd0af;
    font-weight: 400;
    cursor: pointer;
    text-transform: uppercase;
  }

  span {
    display: block;
  }

  svg {
    width: toRem(10px);
    height: toRem(13px);
  }

  &__header {
    svg {
      width: toRem(26px);
      height: toRem(26px);
    }
  }
}
