@import "../../../../../lib/scss/mixins";

.MeetingSpaceCost {
  top: toRem(17px);
  position: absolute;
  right: toRem(25px);

  &__toggler {
    background-color: #f7f7f7;
    border-radius: toRem(12px);
    padding: toRem(15px 10px);
    gap: toRem(5px);
    cursor: pointer;

    span {
      width: toRem(4.8px);
      height: toRem(4.8px);
      background-color: #808080;
      border-radius: 50%;
    }
  }

  .MeetingSpaceCostContent {
    position: absolute;
    top: toRem(45px);
    right: 0;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: toRem(20px);
    min-width: toRem(250px);
    z-index: 1;

    h3 {
      font-weight: 400;
      font-size: toRem(16px);
      line-height: toRem(22px);
      border-bottom: 1px solid #808080;
      padding-bottom: toRem(10px);
    }

    &__infos {
      span {
        display: block;
        color: #808080;
        font-weight: 400;
        padding-top: toRem(15px);
      }
    }

    &__current {
      font-size: toRem(14px);
      line-height: 125%;
    }

    &__value {
      font-weight: 500;
      font-size: toRem(24px);
      line-height: toRem(28px);
    }

    &__exchange {
      font-size: toRem(12px);
      line-height: toRem(16px);
    }

    &__buttons {
      justify-content: space-around;
      margin-top: toRem(25px);

      Button {
        min-height: unset;
        min-width: toRem(85px);

        span {
          padding: unset !important;
          font-size: toRem(11.5px);
        }
      }

      .btn-small {
        padding: toRem(2px 0 5px 0) !important;
      }
    }
  }
}
