@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.HomeCardList {
  width: 100%;
  display: flex;
  margin-top: auto;
  flex-direction: column;
  &__item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h4 {
      font-size: toRem(16px);
      color: $labelColor;
      line-height: 1.188em;
      @include mobile {
        font-size: toRem(14px);
      }
    }
    &:not(:last-child) {
      margin-bottom: toRem(10px);
    }
  }
  &__line {
    width: 100%;
    height: toRem(4px);
    margin-top: toRem(4px);
    position: relative;
    border-radius: $radius;
    background-color: #f1f5f5;
    .progress {
      top: 0;
      left: 0;
      width: 20%;
      height: 100%;
      position: absolute;
      background-color: $errorColor;
      &.full {
        background-color: $mainColor;
      }
    }
  }

  &__Rooms,
  &__Desks,
  &__Wayfinders {
    width: 45%;

    @include tablet {
      width: 25%;
    }

    .HomeCardList__title {
      @include tablet {
        display: none;
      }
    }
  }

  &__Wayfinders {
    position: absolute;
    right: 0;

    .adjustable {
      @include tablet {
        @include iconPrefix("wayfinders-icon");
      }
    }
  }

  &__Rooms {
    .adjustable {
      @include tablet {
        @include iconPrefix("rooms-icon");
      }
    }
  }

  &__Desks {
    .adjustable {
      @include tablet {
        @include iconPrefix("desk_icon_18x18");
      }
    }
  }
}
