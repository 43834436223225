@import "../../../../../lib/scss/variables";
@import "../../../../../lib/scss/mixins";

.WayFinderDirections {
  max-width: toRem(700px);

  // .card-body {
  //   @include mobile {
  //     overflow-y: scroll;
  //   }
  // }

  table {
    // min-width: toRem(430px);
    th {
      border-top: none;
      color: $secondary;
      font-weight: 400;
      font-size: toRem(14px);
      line-height: toRem(19px);
    }
    thead {
      th {
        border-bottom: 2px solid #ebebeb;
      }
    }
    td {
      border-top: none;
      vertical-align: middle;
    }
    tbody {
      tr {
        border-bottom: 1px solid #ebebeb;
        position: relative;
        td {
          svg {
            width: toRem(30px);
          }
        }
      }
    }

    .DistanceValue {
      display: flex;
      &__value {
        border: 0.5px solid #808080;
        padding: toRem(3px 3px 3px 18px);
      }

      input {
        width: toRem(70px);
        border: 1px solid black;
      }

      &__unit {
        padding-left: toRem(10px);
        color: #808080;
        font-weight: 400;
        font-size: toRem(16px);
        line-height: toRem(26px);
        align-self: center;
      }
    }
  }

  &.card {
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: toRem(10px);
  }

  .WayFinderDirectionTable {
    &__options {
      background-color: #fff;
      border: 1px solid #ebebeb;
      border-radius: toRem(12px);
      padding: toRem(15px);
      margin-top: toRem(5px);
      z-index: 10;
      top: 70%;

      &--buttons {
        margin-top: toRem(8px);
        justify-content: space-around;

        Button {
          min-height: unset;
          min-width: toRem(55px);
          span {
            padding: unset !important;
            font-size: toRem(10px);
          }
        }
      }

      span {
        display: block;
        cursor: pointer;
        padding: toRem(5px 0);
      }

      &--close {
        width: toRem(15px);
        margin-left: auto;
        cursor: pointer;
        margin-bottom: toRem(5px);

        .Icon svg {
          width: toRem(15px);
        }
      }

      &--icons {
        display: flex;
        max-width: toRem(300px);
        justify-content: space-evenly;

        flex-wrap: wrap;
        .Icon {
          margin: toRem(5px);
          cursor: pointer;

          svg {
            width: toRem(35px);
          }
        }
      }
    }

    &__unit {
      top: unset !important;
      span {
        &:hover {
          color: #4fc0aa;
        }
      }
    }

    &__button {
      border: 0.125rem solid $mainColor;
      color: $mainColor;
      cursor: pointer;
      border-radius: toRem(30px);
      padding: toRem(3px 8px);
    }

    // &__status {
    //   &--icon {
    //     position: absolute;
    //     right: toRem(10px);
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }
    // }

    &__active {
      .Icon {
        background-color: $mainColor;
        border-radius: toRem(4px);
      }
    }
  }

  .Pagination {
    position: relative;
    margin-bottom: toRem(18px);
    margin-top: 0 !important;

    @include mobile-tablet {
      margin-bottom: toRem(35px);
    }
  }
}
