@import "./../../../lib/scss/mixins";
@import "./../../../lib/scss/variables";

.StructureTable {
  &__head {
    display: flex;
    padding: toRem(0 30px);
    &__item {
      flex: 1 1 0px;
      font-weight: 300;
      color: $labelColor;
      font-size: toRem(14px);
      span {
        text-align: center;
        display: inline-block;
      }
    }
  }
  &__item {
    position: relative;
    margin-bottom: 0 !important;
    &__column {
      display: flex;
      flex: 1 1 0px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @include mobile-tablet {
        margin-bottom: toRem(10px);
        width: auto !important;
        max-width: none !important;
      }
    }

    // Device columns
    &.device_icon {
      max-width: toRem(70px);
    }
    &.device_name {
      max-width: toRem(185px);
    }
    &.device_app {
      max-width: toRem(175px);
    }
    &.device_hardware {
      max-width: toRem(245px);
    }
    &.device_room {
      max-width: toRem(220px);
    }
    &.device_connection {
      max-width: toRem(120px);
    }
    .Switch {
      &__input {
        margin-left: 0;
      }
    }
  }
  &__content {
    row-gap: toRem(15px);
    display: grid;
    column-gap: toRem(20px);
    grid-template-columns: 1fr;
    @include mobile-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
  &--small {
    @include mobile-tablet {
      margin-bottom: toRem(20px);
    }
    h4 {
      font-weight: 400;
      line-height: 1em;
      font-size: toRem(18px);
      @include mobile-tablet {
        font-size: toRem(16px);
      }
    }
    .list-row {
      min-height: toRem(40px);
      padding: toRem(7px 28px);
      @include mobile-tablet {
        padding: toRem(10px);
      }
    }
    .StructureTable {
      &__head {
        padding: toRem(0 28px);
        @include mobile-tablet {
          display: none;
        }
      }
      &__item {
        @include mobile-tablet {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  @include mobile-tablet {
    .Button {
      min-width: auto;
      font-size: toRem(14px) !important;
      padding: toRem(5px 15px) !important;
    }
  }
}
