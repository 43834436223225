@import "../../../../lib/scss/mixins";

.AddWorkplaceButton {
  &--small {
    margin-left: auto;
    display: flex;
    .btn {
      padding: 0 !important;
      border: none !important;
      background: transparent !important;
      svg {
        width: toRem(17px);
      }
    }
  }
}
