@import "../../../../lib/scss/mixins";

.StructureTable--rooms,
.StructureTable--desks {
  .StructureTable {
    &__head {
      .workplace_icon {
        max-width: toRem(34px);
      }
      .workplace_name {
        max-width: 12%;
      }
      .workplace_calendar,
      .workplace_tags {
        max-width: 24%;
      }
      .workplace_description {
        max-width: 12%;
      }
      .workplace_device,
      .workplace_user {
        max-width: 17%;
        span {
          min-width: toRem(150px);
          text-align: start;
        }
      }
      .workplace_license {
        text-align: end !important;
        max-width: 100% !important;
        span {
          margin-right: toRem(53px);
        }
        p {
          color: gray !important;
        }
      }
      .workplace_delete {
        margin-left: unset !important;
      }
    }
    &__item {
      &__column {
        &.workplace_icon {
          max-width: toRem(44px);
        }
        &.workplace_name {
          max-width: 12%;
          @include small-desktop {
            align-items: center;
            flex-direction: row;
            a {
              margin-right: toRem(15px);
            }
          }
        }
        &.workplace_calendar,
        &.workplace_tags {
          max-width: 24%;
          @include desktop {
            span {
              min-width: toRem(150px);
            }
          }
        }
        &.workplace_description {
          max-width: 12%;
        }
        &.workplace_device {
          max-width: 17%;
          // @include desktop {
          //   span {
          //     min-width: toRem(150px);
          //   }
          // }
        }
        &.workplace_license {
          align-items: flex-end !important;
          max-width: 100% !important;

          .Switch {
            margin-right: toRem(40px);

            @include small-desktop {
              margin-right: unset;
            }
          }
        }
        &.workplace_user {
          max-width: 17%;
        }
        &.workplace_delete {
          margin-left: unset !important;
        }
      }
    }
  }
  .WorkplacePrefixIcon {
    margin-right: 0;
  }
}
