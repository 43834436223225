@import "../../lib/scss/variables.scss";
@import "../../lib/scss/mixins.scss";

.AllReservations {
  @include dynamicScroller;

  .PageHeader {
    position: relative;

    .StructureHeader__title {
      padding-bottom: 0;
    }

    @include mobile-tablet-custom {
      flex-direction: column;
      align-items: flex-start;

      .StructureHeader__title {
        margin-bottom: toRem(6px);
      }
    }
  }

  .AllReservationsDescription {
    width: 50%;

    @include mobile-tablet-custom {
      width: 100%;
    }
  }

  .CalendarSelector {
    min-width: toRem(265px);
  }

  .AllReservationsContent {
    display: flex;
    align-items: flex-start;
    column-gap: toRem(16px);
    overflow-y: scroll;
    padding-bottom: toRem(75px);
    min-height: toRem(420px);

    .AddWorkspaceCalendar {
      min-height: toRem(325px);

      span {
        animation: none;
      }

      .user-rounded {
        svg {
          width: toRem(18px);

          path {
            fill: #808080;
          }
        }
      }

      .rooms-icon,
      .room_icon_18x18 {
        svg {
          path {
            stroke: #808080;
          }
        }
      }

      .ReservationCardHeader {
        border-color: #bfbfbf;
      }

      &__add {
        border: 1px solid #4d4d4d;
        border-radius: toRem(12px);
        display: flex;
        flex-direction: column;
        place-items: center;
        padding: toRem(20px 0);
        margin-top: toRem(20px);
        cursor: pointer;

        h5 {
          font-weight: 400;
          font-size: toRem(22px);
          max-width: 70%;
          color: #4d4d4d;
        }

        .Icon {
          margin-bottom: toRem(10px);
          svg {
            width: toRem(40px);

            path {
              stroke: #4d4d4d;
            }
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .ReservationCard {
      border: 1px solid $borderColor;
      min-width: toRem(340px);
      max-width: toRem(340px);

      &.default-box {
        padding: toRem(22px);
      }

      @include mobile-tablet {
        min-width: toRem(335px);
        max-width: toRem(335px);
      }

      &__head {
        .user-rounded {
          svg {
            width: toRem(18px);

            path {
              fill: gray;
            }
          }
        }
        svg {
          width: toRem(17.6px);
        }
      }
    }

    .ReservationCardHeader {
      border-bottom: 1px solid #808080;
      padding-bottom: toRem(14px);
      margin-bottom: toRem(20px);

      &__active {
        svg {
          path {
            fill: $mainColor;
            stroke: $mainColor;
          }
        }
      }

      &__seats {
        h6 {
          //retina screens
          @media screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
            padding-top: toRem(3px);
          }
        }
      }

      &__favorite {
        position: relative;

        &--tooltip {
          @include childContent;
          left: 50%;
          transform: translate(-50%, 5%);
          border: 1px solid $borderColor;
          min-width: toRem(220px);
          display: none;
          visibility: hidden;
        }

        &:hover {
          .ReservationCardHeader__favorite--tooltip {
            display: block;
            visibility: visible;
          }
        }
      }
    }

    .ReservationCardElement {
      position: relative;
      span {
        color: $labelColor;
      }

      svg {
        width: toRem(22px);
      }

      .location-icon {
        svg {
          width: toRem(14px);
          margin-right: toRem(8px);

          path {
            fill: gray;
          }
        }
      }

      .LocationPath {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: toRem(8px);
      }

      &__tooltip {
        @include childContent;
        border: 1px solid $borderColor;
        min-width: toRem(220px);
        display: none;
        visibility: hidden;
        // top: 100%;
        transform: translateX(10%);

        .LocationPath {
          white-space: normal;
        }
      }

      &:hover {
        .ReservationCardElement__tooltip {
          display: block;
          visibility: visible;
        }
      }
    }

    .AddReservation {
      display: flex;
      align-items: center;

      .divider {
        width: 100%;
        height: toRem(2.5px);
        background-color: $mainColor;
        border-radius: toRem(4px);
      }

      .Icon {
        cursor: pointer;

        svg {
          width: toRem(25px);
        }
      }
    }

    .ReservationEvent {
      position: relative;
      border: 1px solid $borderColor;
      padding: toRem(10px 18px);
      min-height: toRem(85px);

      .actions-icon {
        display: none;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 4.5%;
        transform: translateY(-50%);
        svg {
          width: toRem(4px);
        }
      }

      &__organizer {
        margin-top: toRem(5px);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__title {
        position: absolute;
        bottom: toRem(12px);
        max-width: toRem(200px);

        @include mobile-tablet {
          max-width: toRem(220px);
        }
      }

      &__status {
        font-size: toRem(12px);
        margin-left: toRem(10px);
        white-space: nowrap;

        &--ongoing,
        &--checkOut {
          color: #fff !important;
          border-radius: toRem(5px);
          padding: toRem(0 10px);
        }

        &--ongoing {
          background-color: #ea1d25;
        }
        &--checkOut {
          background-color: $warningColor;
        }
        &--decline {
          color: #ea1d25;
        }
        &--await {
          color: #e7853f;
        }
      }

      &:hover {
        border-color: $mainColor;

        .actions-icon {
          display: block !important;
        }
      }
    }

    .ErrorReservationCard {
      h6 {
        color: $secondary;
        border-bottom: 1px solid #bfbfbf;
        padding-bottom: toRem(7px);
        font-weight: 400;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: toRem(80px);
        color: $proColor;
        font-size: toRem(13px);
        padding-top: toRem(12px);
      }
    }
  }

  &__loader {
    span {
      display: flex;
      gap: toRem(16px);
    }
  }
}
