@import "../../../lib/scss/mixins";

.Devices {
  &__content {
    margin-top: toRem(15px);

    // .child-element {
    //   min-width: toRem(220px);
    //   top: toRem(25px);
    //   right: toRem(-5px);

    //   @include mobile {
    //     right: 0;
    //     left: toRem(-50px);
    //   }
    //   span {
    //     font-size: toRem(14px);
    //     padding-bottom: toRem(15px);
    //   }

    //   .LabelWithInformationBox {
    //     label {
    //       font-size: toRem(13px);
    //     }
    //   }
    // }
  }

  .StructureTable {
    // Device columns
    @include mobile-tablet {
      margin-bottom: toRem(30px);
      &__head {
        display: none;
      }
      &__item {
        padding: toRem(15px);
        padding-bottom: toRem(5px);
        flex-direction: column;
        align-items: flex-start;
        max-width: none !important;
        &__column {
          margin-bottom: toRem(10px);
          max-width: none !important;
        }
      }
    }
    .device_icon {
      max-width: toRem(70px);
      @include mobile-tablet {
        display: none;
      }
    }
    .device_name {
      max-width: toRem(195px);
      @include mobile-tablet {
        h4 {
          font-size: toRem(22px);
        }
      }
    }
    .device_app {
      text-align: center;
      display: flex;
      align-items: center;
      place-content: center;
      max-width: toRem(195px);
      @include mobile-tablet {
        @include iconPrefix("apps-icon");
      }
    }
    .device_app_version {
      text-align: center;
    }
    .device_hardware {
      text-align: center;
      max-width: toRem(245px);
      @include mobile-tablet {
        @include iconPrefix("hardware-icon");
      }
    }
    .device_description {
      @include mobile-tablet {
        @include iconPrefix("description-icon");
      }
    }
    .device_app_version {
      @include mobile-tablet {
        @include iconPrefix("settings_profiles_icon");
      }
    }

    .device_assign {
      max-width: toRem(220px);
      @include mobile-tablet {
        // @include iconPrefix("rooms-icon");
        h4 {
          margin-right: toRem(10px);
        }
      }

      .Icon__assign {
        padding-right: toRem(3px);
        svg {
          width: toRem(20px);
        }
      }
    }
    .device_connection {
      max-width: toRem(80px);

      .offline-device {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: toRem(13.5px);

          @include mobile-tablet {
            font-size: toRem(11px);
          }
        }
      }

      .approve-icon {
        margin-left: toRem(9px);
      }

      svg {
        width: toRem(23px);
      }
      @include mobile-tablet {
        top: 15px;
        right: 15px;
        position: absolute;
        svg {
          width: toRem(18px);
        }
      }
    }
    .device_delete {
      @include mobile-tablet {
        right: 15px;
        bottom: 15px;
        margin-bottom: 0;
        position: absolute;
      }
    }
    h3 {
      font-weight: 400;
      font-size: toRem(16px);
      padding-right: toRem(25px);
      @include mobile-tablet {
        padding-bottom: toRem(12px);
      }
    }
    h4 {
      font-weight: 400;
      font-size: toRem(14px);
    }
  }

  &__room {
    @include mobile-tablet {
      display: flex;
      @include iconPrefix("rooms-icon");
    }
  }

  &__wayfinder {
    @include mobile-tablet {
      display: flex;

      @include iconPrefix("wayfinders-icon");
    }
  }
}
