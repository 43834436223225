@import "../../../lib/scss/mixins";

.AccountActivated {
  padding-right: toRem(33px);
  @include mobile-tablet {
    padding-right: 0;
  }
  &__platforms {
    display: flex;
    margin-top: toRem(40px);
    justify-content: space-between;
    @include mobile-tablet {
      max-width: toRem(400px);
    }
    li {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  &__qr-code {
    margin-top: toRem(40px);
    img {
      width: toRem(77px);
    }
  }
  p,
  a {
    color: #000000;
    line-height: toRem(26px);
    font-size: toRem(16px);
  }
  .btn {
    width: 100%;
    margin-top: toRem(55px);
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: toRem(30px);

      a {
        &:hover {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }

  a {
    color: #2cd0af !important;
  }
}
