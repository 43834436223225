@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.EditSettings {
  padding: toRem(30px);
  margin-top: toRem(15px);
  border-radius: $radius;
  background-color: white;
  @include mobile-tablet {
    margin-bottom: toRem(20px);
  }
  &--split {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    .FormSection {
      padding: toRem(30px);
      border-radius: $radius;
      margin-bottom: toRem(20px);
      background-color: white;
    }
  }
  &--flexible {
    form {
      display: flex;
      row-gap: toRem(20px);
      flex-wrap: wrap;
      column-gap: toRem(40px);
      margin-bottom: toRem(20px);
      @include tablet {
        column-gap: toRem(20px);
      }
    }
    .FormSection {
      margin-bottom: 0;
    }
  }
  & > h3 {
    font-size: toRem(18px);
    line-height: 1em;
    font-weight: 300;
    color: #808080;
    margin-bottom: toRem(12px);
  }
  .btn {
    &-small {
      max-width: toRem(230px);
    }
  }

  .RoomForm {
    &__reporting {
      cursor: pointer;
      margin-top: toRem(20px);
      svg {
        width: toRem(18px);
      }
    }

    &__main {
      .css-2b097c-container {
        width: 25%;

        @include mobile-tablet {
          width: 50%;
        }

        @include mobile {
          width: 100%;
        }
      }

      .edit-icon {
        svg {
          width: toRem(18px);
        }
      }

      &--group {
        @include mobile {
          display: block;
        }
      }

      &--backgroundImage {
        flex: 0 0 calc(100% - 180px);
      }

      &--bwFilter {
        padding-top: toRem(25px);
        padding-left: toRem(10px);
        margin-bottom: 0;
        min-width: toRem(180px);
        margin-left: auto;

        @include mobile {
          padding-top: toRem(10px);
          padding-left: 0;
        }

        span {
          color: #808080 !important;
        }
        .checkbox_box {
          top: toRem(5px);
        }
      }
    }

    &__emailReminder {
      display: flex;
      padding-top: toRem(10px);

      span {
        color: #808080 !important;
      }

      .InformationBox {
        margin-left: toRem(5px);
      }
    }
  }

  .UserInfoMeta {
    position: absolute;
    margin-left: 0;
    top: toRem(-50px);
    display: flex;
    column-gap: toRem(8px);

    .SubscriptionStatus {
      display: flex;
      align-items: center;
      height: toRem(20px);
      margin-left: 0;
      padding-bottom: toRem(4px);
    }

    &__company {
      display: flex;
      color: $labelColor;
      font-weight: 300;
      padding-left: toRem(8px);
      padding-top: toRem(2px);
      border-left: 1px solid $borderColor;
    }

    @include default-laptop {
      top: toRem(-55px);
    }
  }

  .AccountForm {
    &__profilePicture {
      margin-top: 0 !important;
      color: #000 !important;
      font-weight: 400;
      font-size: toRem(18px);
      padding-right: toRem(5px);
      padding-bottom: toRem(10px);

      @include mobile-tablet {
        font-size: toRem(14px);
      }
    }

    .UploadImage {
      &__field {
        width: toRem(50px);
        height: toRem(50px);
      }
    }
  }
}
