@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.FloorSettingsPopupForm {
  margin-top: toRem(10px);

  &__group {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: toRem(16px);

    input {
      width: 18%;
    }

    span {
      color: #808080;
      max-width: toRem(95px);
    }

    label {
      color: #4d4d4d;
    }

    &--value {
      position: absolute;
      right: toRem(12px);
      top: toRem(10.5px);
    }

    svg {
      width: toRem(18px);
      margin-right: toRem(10px);
    }
  }

  .divider {
    width: 100%;
    height: toRem(1px);
    background-color: $borderColor;
    margin-bottom: toRem(25px);
    margin-top: toRem(12px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
