@import "../../../../lib/scss/variables";
@import "../../../../lib/scss/mixins";

.TimeZoneSettingsModal {
  .form-group {
    margin-bottom: toRem(24px);
  }

  .form-check {
    max-width: unset;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: toRem(48px);

    .Switch__input {
      margin-left: 0;
      margin-top: toRem(8px);
    }
  }
}
