@import "../../../lib/scss/mixins";

.PageHeader {
  margin-top: toRem(24px);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-bottom: toRem(10px);
  justify-content: space-between;
  border-bottom: toRem(3px) solid #ebebeb;
  &--hide-border {
    border-bottom: none;
    padding-bottom: toRem(10px);
  }
}
