@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.WorkplacesContent {
  flex: 1;
  width: 100%;
  display: flex;
  margin-top: toRem(20px);
  flex-direction: column;
  @include mobile-tablet {
    padding: toRem(0 15px);
  }
  &__header {
    margin-bottom: toRem(15px);
  }
  &__main {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: toRem(20px);
    justify-content: center;
  }
}
