@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.BookingsSearchHeader {
  @include mobile-tablet {
    flex-direction: column;
    align-items: flex-start !important;

    &__subHead {
      display: block !important;
      width: 100%;
    }
  }
  h1 {
    font-size: toRem(24px);
    line-height: 1em;
    margin-right: toRem(12px);
    @include mobile-tablet {
      font-size: toRem(20px);
    }
  }

  svg {
    width: toRem(20px);
  }

  &__header {
    @include mobile-tablet {
      flex: 1;
      justify-content: space-between;
      margin-bottom: toRem(12px);
      width: 100%;
    }

    .Button {
      display: none !important;

      @include mobile {
        display: flex !important;
        background-color: transparent;

        span {
          margin-left: toRem(15px);
        }
      }
    }
  }

  &__inputs {
    gap: toRem(16px);
    background-color: #fff;
    border-radius: toRem(10px);
    border: 1px solid $borderColor;
    padding: toRem(7px 25px);
    margin-left: toRem(5px);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;

    svg {
      width: toRem(14px);
      margin-right: toRem(5px);

      @include mobile-tablet {
        path {
          fill: #fff !important;
        }
      }
    }

    &--location {
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @media (max-width: 1320px) and (min-width: 1200px) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    @include mobile-tablet {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: $secondary;
      color: #fff;
      margin-left: 0;
    }
  }

  //icons:
  .bookings-search {
    padding-bottom: toRem(12px);
    padding-right: toRem(8px);

    @include mobile-tablet {
      display: none;
    }
  }

  .search-right-arrow {
    svg {
      width: toRem(22px);
    }

    @include mobile-tablet {
      display: none;
    }
  }

  .location-reserve {
    svg {
      width: toRem(13px);
      path {
        fill: #000;
      }
    }
  }
}
