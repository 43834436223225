@import "../../../lib/scss/mixins";

.AddInfoModal {
  top: toRem(120px);

  @include tablet {
    top: toRem(90px);
  }

  .modal-header {
    border: none;
    padding: unset;
    display: unset;

    svg {
      width: toRem(13px);
    }
  }

  .modal-content {
    padding: toRem(16px 16px 0 16px);
    border: none;
  }

  &__header {
    display: flex;
    place-content: center;
    padding-top: toRem(25px);

    svg {
      width: toRem(35px);
      max-width: toRem(35px) !important;
    }
  }

  h6 {
    font-weight: 400;
    font-size: toRem(22px);
    line-height: toRem(33px);
    padding-top: toRem(15px);
    text-align: center;
  }

  .modal-body {
    max-width: 75%;
    margin: auto;
    text-align: center;

    span {
      color: #adaeb9;
      font-weight: 300;
      font-size: toRem(14px);
    }
  }

  .modal-footer {
    justify-content: center;
    padding-top: toRem(12px);
  }
}
