@import "../../../lib/scss/mixins";

.ConnectProviders {
  #signIn {
    margin-right: toRem(15px);
  }

  @include mobile {
    flex-direction: column;

    #signIn {
      margin-right: 0;
      margin-bottom: toRem(12px);
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: toRem(4px);
    border: 1px solid #dadce0;
    font-size: 14px;
    font-family: "Google Sans", arial, sans-serif;
    font-weight: 500;
    line-height: toRem(16px);
    min-height: toRem(47px);
    position: relative;
    min-width: toRem(240px);
    width: toRem(250px);
    background-color: #fff;
    transition: color 0.2s ease;
    color: #3a3a3a;

    @include mobile-tablet {
      min-height: toRem(39px);
    }

    @include mobile {
      width: toRem(230px);
      min-width: toRem(230px);
    }
  }

  &__connect {
    button {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084),
        0px 1px 1px rgba(0, 0, 0, 0.168);
      border: 0.1rem solid #f1f1f1;
      color: #757575;
      min-width: toRem(220px);
      width: toRem(220px);
      min-height: toRem(40px);
      border-radius: toRem(2px);
      font-size: toRem(15px);
    }
  }

  &__google,
  &__office {
    height: toRem(20px);
    width: toRem(17px);
    left: toRem(10px);
    position: absolute;

    &::before {
      background-repeat: no-repeat;
      background-size: 100%;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &__google {
    &::before {
      background-image: url("../../../assets/icons/google_wp.svg");
    }
  }

  &__office {
    height: toRem(18px);
    width: toRem(18px);
    &::before {
      background-image: url("../../../assets/icons/microsoft-logo.svg");
    }
  }
}
