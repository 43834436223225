@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.FilterBox {
  position: relative;
  cursor: pointer;
  &__header {
    display: flex;
    align-items: center;
    .Icon {
      margin-right: toRem(6px);
    }
    svg {
      width: toRem(17px);
    }
    h4 {
      margin: 0;
      font-size: toRem(16px);
      color: $labelColor;
    }
  }

  &__active {
    h4 {
      color: $mainColor;
    }

    svg {
      path {
        stroke: $mainColor;
      }
    }
  }
}
