@import "../../../lib/scss/mixins";

.FloorEmptyMap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30vh;

  @include small-desktop {
    .Button {
      min-height: unset !important;
    }
  }
}
