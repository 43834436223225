@import "../../../lib/scss/mixins";

.ReAuthModal {
  top: toRem(120px);

  max-width: toRem(700px) !important;

  @include tablet {
    top: toRem(90px);
  }

  @include mobile {
    max-width: toRem(550px) !important;
  }

  .modal-header {
    border: none;
    padding: unset;
    display: unset;

    svg {
      width: toRem(13px);
    }
  }

  .modal-content {
    padding: toRem(16px 16px 0 16px);
    border: none;
  }

  &__header {
    display: flex;
    place-content: center;
    padding-top: toRem(25px);

    svg {
      width: toRem(40px);
      max-width: toRem(40px) !important;
      path {
        fill: #808080;
      }
    }

    &--subTitle {
      color: #808080;
      font-size: toRem(17px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }

  h6 {
    font-weight: 400;
    font-size: toRem(22px);
    line-height: toRem(33px);
    padding-top: toRem(15px);
    text-align: center;
  }

  .modal-footer {
    justify-content: center;
    padding-top: toRem(12px);
  }
}
