@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.MarkerStatus {
  text-align: center;
  border: 1px solid $proColor;
  border-radius: toRem(20px);
  place-content: center;
  padding: toRem(5.5px 28px);
  display: inline-flex;
  align-items: center;

  svg {
    width: toRem(12px) !important;
    margin-right: toRem(8px);
  }

  .green-rounded {
    svg {
      width: toRem(10px) !important;
    }
  }
  .red-rounded {
    svg {
      width: toRem(9px) !important;
    }
  }
}
