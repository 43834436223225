@import "../../../../lib/scss/mixins";

.AddDeskProfile {
  .ProfilesForm {
    background-color: #fff;
    box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.1);
    padding: toRem(20px);
    border-radius: toRem(10px);

    &__type {
      border-top: 1px solid #ebebeb;
      padding-top: toRem(16px);

      svg {
        width: toRem(25px);

        path {
          stroke: #808080;
        }
      }

      h6 {
        color: #808080;
        font-weight: 300;
      }
    }

    .RoomForm__main--group {
      flex-direction: column;
      align-items: initial;
      gap: toRem(15px);
    }

    .css-2b097c-container {
      width: 100%;
    }
  }
}
