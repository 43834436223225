@import "../../../lib/scss/variables";
@import "../../../lib/scss/mixins";

.AuthenticationPage {
  width: 100%;
  background: linear-gradient(to bottom, rgba(2, 2, 2, 0.7), rgba(2, 2, 2, 0.7)),
    url("../../../assets/images/authentication-layout-bg.png") no-repeat center;
  background-size: cover !important;
  h2,
  p {
    color: white;
  }
  &--full {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: white;
  }
  &__wrapper {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    &__content {
      display: flex;
      overflow: hidden;
      width: toRem(1135px);
      min-height: toRem(440px);
      border-radius: toRem(15px);
      box-shadow: $defaultBoxShadow;
      @include mobile {
        width: 100%;
        padding: toRem(40px 20px);
        flex-direction: column;
      }
      @include mobile-tablet {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        padding: toRem(40px 20px);
        flex-direction: column;
      }
    }
  }
  &__background {
    width: 40%;
    padding: toRem(40px);
    background: linear-gradient(
        to bottom,
        rgba(2, 2, 2, 0.7),
        rgba(2, 2, 2, 0.7)
      ),
      url("../../../assets/images/authentication-sidebar-bg.jpg") no-repeat
        center;
    background-size: cover;
    position: relative;

    img {
      position: absolute;
      top: 45%;
      left: 45%;
      transform: translate(-50%);
      width: toRem(330px);
    }
    @include mobile-tablet {
      display: none;
    }
    h2 {
      font-size: toRem(36px);
      font-weight: 300;
      margin-bottom: toRem(8px);
      line-height: 1.25em;
      text-transform: none;
      @include mobile {
        font-size: toRem(24px);
      }
    }
    p {
      font-size: toRem(24px);
      font-weight: 300;
      margin-bottom: 0;
      line-height: 1.25em;
      @include mobile {
        font-size: toRem(18px);
      }
    }
  }
  &__form {
    width: 60%;
    display: flex;
    position: relative;
    background: white;
    padding: toRem(40px);
    flex-direction: column;
    @include mobile-tablet {
      width: 100%;
      padding: toRem(25px);
      border-radius: toRem(15px);
      box-shadow: $defaultBoxShadow;
    }
    &__logo {
      min-height: toRem(38px);
      margin-bottom: toRem(10px);
      position: relative;
      img {
        width: toRem(140px);
      }
    }
    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      form {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      @include mobile-tablet {
        margin-bottom: toRem(16px);
      }
    }
  }
  &__form-title {
    color: #000;
    font-size: toRem(24px);
    line-height: 1em;
    font-weight: 300;
    margin-top: toRem(24px);
    margin-bottom: toRem(12px);
    @include mobile {
      font-size: toRem(18px);
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    margin-top: auto;
    padding-top: 1rem;
    align-items: center;
    button {
      margin-right: 1rem;
    }
    @include mobile {
      align-items: center;
      flex-direction: column;
      button {
        width: 100%;
        margin-right: 0;
        margin-bottom: toRem(20px);
      }
      .btn-outline-primary {
        width: 100%;
      }
    }
  }
  &__info {
    color: #000000;
    user-select: none;
    left: toRem(40px);
    line-height: 125%;
    position: absolute;
    bottom: toRem(12px);
    font-size: toRem(14px);
  }

  &__register {
    svg {
      width: toRem(55px);
      path {
        fill: #808080;
      }
    }

    .RegisterCaution {
      margin-top: toRem(35px);

      h3 {
        color: #000;
        font-size: toRem(22px);
        line-height: 1em;
        font-weight: 300;
      }

      span {
        font-size: toRem(16px);
      }

      &__buttons {
        @include fullWidthTwoButtons;
        margin-top: toRem(30px);

        @media (max-width: 450px) {
          gap: toRem(16px) !important;
        }
      }
    }
  }

  &__formInfo {
    gap: toRem(6px);
    margin-bottom: toRem(20px);

    span {
      color: #4d4d4d;

      @include mobile {
        font-size: toRem(12px);
      }
    }
    svg {
      width: toRem(16px);
      path {
        fill: rgb(44, 208, 175);
      }
    }
  }
}
