@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.FloorContent {
  display: flex;
  margin-top: toRem(32px);
  grid-gap: 2rem;

  @include mobile-tablet {
    flex-direction: column;
  }

  .DeleteMarkerButton,
  .AddMarkerButton {
    button {
      width: toRem(25px);
      height: toRem(25px);
      min-width: auto;
      position: relative;
      border-radius: 100%;
      text-indent: -9999px;
      min-height: unset;

      &::before {
        top: 50%;
        left: 50%;
        content: "";
        width: toRem(14px);
        height: toRem(14px);
        position: absolute;
        display: inline-block;
        transform: translate(-50%, -50%);
        background-size: contain !important;
      }
    }
  }

  .DeleteMarkerButton {
    button {
      border-color: #ebebeb;

      &::before {
        background: url("../../../assets/icons/minus.svg") no-repeat center;
      }
    }
  }

  .AddMarkerButton {
    button {
      &::before {
        background: url("../../../assets/icons/plus-icon.svg") no-repeat center;
      }
    }
  }

  &__plan {
    flex: 0 0 75%;

    .LoadingBox {
      position: absolute;
      top: 0;
    }

    &--hide {
      .leaflet-draw {
        visibility: hidden;
        display: none;
      }
    }

    &--hideDraw {
      .leaflet-draw-toolbar-top {
        display: none;
        visibility: hidden;
      }
    }

    &--hideSection {
      .leaflet-draw-edit-edit,
      .leaflet-draw-edit-remove {
        display: none;
        visibility: hidden;
      }
    }

    .leaflet-editing-icon {
      border-radius: 50%;
      border: 5px solid #156c79;
      background: white;
      width: toRem(18px) !important;
      height: toRem(18px) !important;
      // opacity: 1 !important;
    }

    .leaflet-draw-actions a {
      background-color: #4d4d4d;
      font-family: "Sofia Pro", "Open Sans", sans-serif;

      &:hover {
        background-color: #7c7c7c;
        color: #2cd0af;
        text-decoration: none;
      }
    }

    .leaflet-right .leaflet-draw-actions li:last-child a {
      border-radius: 0 4px 4px 0;
    }

    .leaflet-interactive {
      stroke-dasharray: none;
      // stroke-width: 3;
    }

    .leaflet-popup-content-wrapper {
      min-width: toRem(320px);
      font-family: "Sofia Pro", "Open Sans", sans-serif;

      .leaflet-popup-content {
        margin-top: toRem(30px);
        margin-bottom: toRem(20px);
      }
    }

    .leaflet-container a.leaflet-popup-close-button {
      top: toRem(18px);
      right: toRem(27px);
      font-size: 0;
      padding: toRem(5px);
      padding-top: toRem(7px);
      border-radius: toRem(5px);
      width: toRem(27px);
      height: toRem(27px);

      @include iconPrefix("close-icon");

      &::before {
        width: toRem(13.5px);
        height: toRem(13.5px);
        margin-right: 0;
        margin-top: 0;
      }

      // span {
      //   display: block;
      //   border-radius: toRem(5px);
      //   height: toRem(27px);
      //   width: toRem(27px);
      //   padding-top: toRem(12px);
      //   line-height: 0.3rem;

      &:hover {
        background-color: rgba(#adaeb9, 0.15);
      }
    }

    img[alt="PoiMarker"] {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px 0px;
    }
  }

  &__children {
    flex: 1;

    &--header {
      svg {
        width: toRem(25px);
        margin-right: toRem(10px);
      }

      h5 {
        font-weight: 400;
        font-size: toRem(18px);
      }
    }

    @include mobile-tablet {
      margin-bottom: toRem(18px);
    }

    .FloorChildrenDefaultList {
      &__empty {
        background-color: #fff;
        height: toRem(75px);
        border-radius: toRem(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: toRem(0px 0px 20px) rgba(0, 0, 0, 0.05);
        font-size: toRem(18px);

        @include small-desktop {
          font-size: toRem(16px);
        }
      }
    }

    .LoadingBox {
      min-height: toRem(70px) !important;
      margin-bottom: toRem(16px);
    }

    .StructureTable {
      &__content {
        @include small-desktop {
          grid-template-columns: 1fr;
        }
        @include mobile-tablet {
          grid-template-columns: repeat(2, 1fr);
        }
        @include mobile {
          grid-template-columns: 1fr;
        }
      }
      .Button {
        @include mobile-tablet {
          font-size: unset !important;
          padding: unset !important;
        }
      }
      &__head {
        .add {
          text-align: end;
        }
        .name {
          position: relative;
          &::before {
            left: toRem(-20px);
            top: 50%;
            height: toRem(12px);
            width: toRem(13px);
            content: "";
            position: absolute;
            transform: translateY(-50%);
            background: url("../../../assets/icons/chevron-down.svg") no-repeat
              center;
          }
        }
        .add,
        .freeUntil {
          @include mobile-tablet {
            display: none;
          }
        }

        .location {
          display: none;
        }
      }

      &__item {
        height: toRem(47px);
      }
    }

    .list-row {
      padding: toRem(9.6px 30px);
      position: relative;

      .add {
        place-items: end;
      }

      .name {
        span {
          display: flex;
          align-items: center;
          text-wrap: nowrap;
        }
        .primary--text {
          path {
            fill: #2cd0af;
          }
        }

        .Icon {
          width: toRem(18px);
          margin-left: toRem(8px);
        }

        &__adjust {
          margin-bottom: toRem(14px);
        }
      }

      .location {
        position: absolute;
        color: #adaeb9;
        font-size: toRem(12.5px);
        font-style: normal;
        font-weight: 400;
        top: toRem(21px);
      }
    }

    &--bookings {
      .StructureTable {
        &__head {
          padding-right: 0 !important;

          @include small-desktop-custom {
            .freeUntil,
            .add {
              display: none;
            }
          }

          @include large-desktop-custom {
            .name {
              flex: 1 1 40%;
            }
          }

          // .freeUntil {
          //   text-align: end;
          // }
        }

        &__item {
          .name {
            flex: none;
            width: 42%;

            @include large-desktop-custom {
              flex: 1 1 40%;
            }

            @include small-desktop-custom {
              width: 75%;
            }
          }

          .add {
            margin-right: toRem(18px);

            @include small-desktop-custom {
              margin-right: unset;
            }
          }

          @include small-desktop-custom {
            flex-direction: column;
            align-items: flex-start;
            height: toRem(70px);

            .freeUntil {
              flex-direction: row;

              div {
                padding-left: toRem(5px);
                line-height: toRem(18px);
              }
              &::before {
                content: "Free until";
                font-weight: 300;
                color: #808080;
                font-size: 0.875rem;
              }
            }

            .add {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: toRem(28px);
            }
          }

          padding-right: toRem(8px);
        }
      }
    }

    &--view {
      .StructureTable {
        &__item {
          &__column {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
