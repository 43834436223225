@import "../../../../lib/scss/mixins";

.SubscriptionRow {
  border: toRem(1px) solid #ebebeb;
  box-sizing: border-box;
  border-radius: toRem(1px);
  padding: toRem(20px);
  position: relative;
  min-height: toRem(115px);

  @include mobile {
    padding: unset;
    padding-top: toRem(16px);
  }

  &:nth-child(2) {
    border-bottom: none;
  }

  &__rows {
    max-width: toRem(850px);
    display: flex;
    // justify-content: space-between;

    @include mobile {
      flex-direction: column;
      text-align: center;
    }
  }

  &__row {
    min-width: toRem(275px);
    @include mobile-tablet {
      padding-bottom: toRem(18px);
    }

    @include mobile {
      min-width: unset !important;
    }

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: toRem(16px);
      line-height: toRem(26px);
      color: #000;
    }

    &--content {
      color: #808080;
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(25px);

      .SubscriptionItemsCount {
        display: flex;
        justify-content: space-between;
        max-width: toRem(105px);

        @include mobile {
          margin: 0 auto;
        }
      }
    }

    .span-block {
      width: 80%;
      display: block;

      &--dual {
        width: 60%;
      }

      @include mobile {
        width: unset;
      }
    }
  }

  .NoAddons {
    @include mobile {
      justify-content: center;
    }
    svg {
      width: toRem(20px);
      margin-right: toRem(7px);
    }
  }

  &__plans {
    position: absolute;
    bottom: toRem(9px);
    cursor: pointer;
    color: #f2c34a;
    border: 1px solid #f2c34a;
    font-weight: 400;
    font-size: toRem(13px);
    line-height: toRem(16px);
    border-radius: toRem(12px);
    padding: toRem(2px 9px);

    &:hover {
      color: #f2c34a;
      text-decoration: none;
    }

    @include mobile {
      bottom: unset;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -75%);
    }

    &--adjust {
      @include mobile {
        margin-top: toRem(25px);
      }
    }
  }

  .Period {
    @include mobile {
      padding-top: toRem(30px);
    }
  }
}
