@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.HomeDetailsCard {
  h3 {
    font-size: toRem(24px);
    line-height: 1em;
    font-weight: 400;
    margin-bottom: toRem(10px);

    a {
      color: inherit !important;
      text-decoration: none !important;
    }

    @include mobile-tablet {
      font-size: toRem(18px);
    }
  }
  &__sub-title {
    display: flex;
    padding-bottom: toRem(4px);
    border-bottom: toRem(1px) solid $borderColor;
    h5 {
      flex: 1;
      font-size: toRem(16px);
      color: $labelColor;
      font-style: normal;
      font-weight: normal;
      line-height: 1.625em;
      @include mobile-tablet {
        font-size: toRem(14px);
      }
      &.total {
        flex: none;
        padding-left: toRem(5px);
        margin-left: auto;
      }
    }
  }
  &__list {
    width: 100%;
    margin-top: toRem(10px);
    li {
      display: flex;

      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: toRem(8px);
      }
      &.placeholder {
        display: flex;
        padding: toRem(40px 10px 20px);
        align-items: center !important;
        justify-content: center !important;
        p {
          font-size: toRem(16px);
          margin-bottom: 0;
          color: $secondary;
        }
      }
    }
    a,
    span,
    h5 {
      flex: 1;
      font-size: toRem(16px);
      font-style: normal;
      font-weight: normal;
      line-height: 1.625em;
      color: $labelColor !important;
      &:not(:last-child) {
        padding-right: toRem(5px);
      }
      @include mobile-tablet {
        font-size: toRem(14px);
      }
    }
    span,
    a {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }
  &--bottom {
    margin-bottom: toRem(20px);
  }
}
