@import "../../../lib/scss/mixins";

.SubscriptionWrapper {
  display: flex;
  flex-direction: column;
  @include tablet {
    display: grid;
    column-gap: toRem(20px);
    grid-column: 1 / 4;
    grid-template-columns: repeat(2, 1fr);
  }
}
