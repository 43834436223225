@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.root {
  display: flex;
  align-items: center;
  column-gap: toRem(12px);
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  color: $secondary;
  transition: all 0.3s;

  &:hover {
    background-color: rgba(0,0,0,.05);
    color: $proColor;
  }

  svg {
    width: toRem(24px);
    color: inherit;
  }

  span {
    font-size: toRem(16px);
  }
}
