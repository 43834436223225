@import "../../../lib/scss/mixins";

.PeopleActions {
  position: relative;
  & > .Icon {
    padding: toRem(0 5px);
    svg {
      width: toRem(4px);
    }
  }
}
