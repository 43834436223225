@import "../../../lib/scss/mixins";

.CustomLanguageModal {
  max-width: toRem(715px) !important;
  margin-top: toRem(80px) !important;
  @include dynamicScroller;

  @include mobile {
    margin-top: toRem(40px) !important;
  }

  .modal-content {
    border: none;
  }

  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__name {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: toRem(16px);
  }
}
