@import "../../../../lib/scss/mixins";
@import "../../../../lib/scss/variables";

.SelectAllIntegrations {
  svg {
    width: toRem(10px);
  }

  .IntegrationAllCheck {
    &.form-group .checkbox_box {
      top: toRem(-15px);
      background: url("../../../../assets/icons/checkbox-green-empty.svg")
        no-repeat center;
    }
  }

  &__check {
    border: 2px solid $mainColor;
    border-radius: toRem(100px);
    padding: toRem(1px 5px);
  }

  &__selector {
    @include childContent;
  }
}
