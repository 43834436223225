.EnableSSO {
  &__switch {
    .form-check {
      width: 100%;
      max-width: unset;
    }

    .LabelWithInformationBox {
      flex: 1;
      margin-right: 20px;

      label {
        font-size: toRem(14px);
        line-height: 125%;
        max-width: 85%;
      }
    }
  }
}
