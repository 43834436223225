@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.WorkingHours {
  h6 {
    color: $secondary;
    font-weight: 400;
    font-size: toRem(14px);
  }

  @include mobile-tablet {
    text-align: center;
  }

  button {
    color: $mainColor;
    background-color: unset;
    border: none;
  }

  svg {
    cursor: pointer;
    width: toRem(13px);
  }

  .WorkingHoursForm {
    &__warning {
      span {
        font-size: toRem(12px);
        color: #adaeb9;
      }

      @include mobile-tablet {
        justify-content: center;
      }
    }
    svg {
      width: toRem(20px);
    }

    &__selectors {
      input {
        cursor: pointer;
        border: 1px solid $borderColor;
        color: $labelColor;
      }

      @include mobile-tablet {
        flex-direction: column;
      }
    }

    .form-group .InformationBox {
      position: absolute;
      top: 0;
      right: toRem(-25px);

      &__content {
        width: toRem(185px);
      }
    }

    .form-group {
      .checkbox_label {
        span {
          color: $labelColor;
        }
      }
    }

    &__redirect {
      padding-top: toRem(10px);

      a {
        color: $labelColor;
        font-weight: 300;

        svg {
          width: toRem(16px);
        }
      }

      span {
        cursor: pointer;
      }
    }

    @include timePicker;

    .disabled-arrow {
      .react-datepicker__input-container {
        &::after {
          display: none;
        }
      }
    }
  }
}
