@import "../../../lib/scss/mixins";

.CenteredInformationBox {
  margin-top: toRem(30px);
  min-height: auto;
  @include mobile-tablet {
    margin-top: toRem(10px);
  }
  .CenteredBox {
    justify-content: flex-start;
    &__modal {
      width: 100%;
      height: 100%;
    }
  }
  h3 {
    font-weight: 400;
    font-size: toRem(20px);
    @include mobile-tablet {
      font-size: toRem(16px);
      text-align: center;
    }
  }
  svg {
    width: toRem(30px);
    @include mobile-tablet {
      width: toRem(20px);
    }
  }
}
