@import "../../../lib/scss/mixins";

.ResourceFilter {
  display: flex;
  // user-select: none;
  position: relative;
  justify-content: flex-end;
  margin-bottom: toRem(15px);
  &__wrapper {
    // z-index: -1;
    display: flex;
    position: relative;
    align-items: center;
    // &:after {
    //   top: 0;
    //   left: 0;
    //   z-index: 5;
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    // }
  }

  &__subTitle {
    @include mobile-tablet {
      font-size: toRem(18px);
    }
  }

  @include mobile-tablet {
    margin-bottom: toRem(20px);
  }
  .FilterBox {
    margin-right: toRem(20px);
  }

  .FilterContent {
    @include small-desktop {
      flex-direction: column;
      // align-items: start;
      gap: toRem(16px);
    }
  }

  .FilterHeader {
    @include small-desktop {
      flex-direction: column;

      h5 {
        margin-bottom: toRem(7px);
      }
    }
  }
}
