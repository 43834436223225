@import "../../lib/scss/mixins";

.OrganizationMain {
  .ResourceNavigation {
    padding-bottom: toRem(27.4px);

    @include mobile-tablet {
      padding-bottom: 0;
    }
  }

  .LoadingBox {
    margin-top: toRem(35px);
  }

  @include small-desktop {
    padding-bottom: toRem(24px);
  }
}
