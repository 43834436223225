@import "../../lib/scss/mixins";

.Home {
  &__boxes {
    display: grid;
    grid-gap: toRem(20px);
    margin-top: toRem(25px);
    grid-template-columns: repeat(3, 1fr);
    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
    .LoadingBox--chart,
    .ChartBox {
      grid-column: 1 / 3;
      @include mobile {
        grid-column: initial;
      }
      @include tablet {
        grid-column: 1 / 4;
      }

      &__insightsError {
        color: #808080;
      }
    }
  }

  .Workspaces {
    position: relative;
    .HomeCardList {
      position: relative;
    }
  }
}
