@import "../../../../lib/scss/mixins";

.ManagedPartnerForm {
  .BillingInnerCard {
    &:nth-child(1) {
      border-bottom: 0.0625rem solid #ebebeb;
    }

    &__content--text {
      max-width: unset;
    }
  }

  input {
    margin-bottom: toRem(16px);
  }

  ul {
    padding-left: toRem(20px);

    @include mobile {
      text-align: start;
    }

    li {
      list-style: disc;
    }
  }
}
