@import "../../../../lib/scss/variables";
@import "../../../../lib/scss/mixins";

.PaginationSelect {
  display: flex;
  margin-left: auto;
  align-items: flex-start;
  &__list {
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    border-radius: toRem(15px);
    border: toRem(2px) solid $mainColor;
    li {
      display: flex;
      max-height: 0;
      margin-right: 0;
      overflow: hidden;
      align-items: center;
      padding: toRem(0 8px);
      justify-content: flex-start;
      span {
        line-height: 1em;
        font-size: toRem(16px);
      }
      .Icon {
        margin-top: toRem(3px);
        margin-left: toRem(5px);
        transition: $transition1;
      }
      &.active {
        display: flex;
        color: $mainColor;
        max-height: 9999px;
        justify-content: center;
        padding: toRem(5px 8px);
        background: transparent !important;
        span {
          color: $mainColor !important;
        }
      }
    }
    &--toggled {
      li {
        max-height: 9999px;
        padding: toRem(5px 8px);
        &.selected,
        &:hover {
          background: $mainColor;
          span {
            color: white;
          }
        }
      }
    }
  }
  &__title {
    line-height: 1em;
    color: $labelColor;
    margin-top: toRem(6px);
    font-size: toRem(16px);
    margin-right: toRem(10px);
  }
  .arrow-icon {
    width: toRem(8px);
  }
}
