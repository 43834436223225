@import "../../../../lib/scss/variables";
@import "../../../../lib/scss/mixins";

.PaginationList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: auto;
  position: relative;
  padding: toRem(0 35px);
  &__item {
    display: flex;
    cursor: pointer;
    user-select: none;
    width: toRem(30px);
    height: toRem(30px);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    transition: $transition1;
    border: toRem(1px) solid transparent;
    &:not(:last-child) {
      margin-right: toRem(10px);
    }
    &:hover {
      color: $mainColor;
      border-color: $mainColor;
      svg {
        fill: $mainColor;
      }
    }
    &.active {
      color: white;
      background: $mainColor;
      border-color: $mainColor;
      svg {
        fill: $mainColor;
      }
    }
    &--previous {
      left: 0;
      border-color: #ebebeb;
      transform: rotate(-180deg);
    }
    &--next {
      right: 0;
      border-color: #ebebeb;
    }
    &--dots {
      cursor: default;
      align-items: center;
      justify-content: center;
      border: none !important;
      color: $labelColor !important;
    }
    &--arrow {
      position: absolute;
      .chevron {
        width: toRem(12px);
      }
      svg {
        fill: #808080;
        transition: $transition1;
      }
      &:hover {
        color: white;
        background: $mainColor;
        border-color: $mainColor;
        svg {
          fill: white;
        }
      }
    }
  }
}
