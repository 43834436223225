@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.CancelAccount {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $borderColor;
  background-color: unset;
  border-radius: toRem(10px);
  height: toRem(64px);
  padding: 0 toRem(40px);
  transition: $transition1;

  &:hover {
    border-color: $errorColor;
    span {
      color: $errorColor;
    }
  }

  span {
    transition: $transition1;
    font-size: toRem(14px);
    cursor: pointer;
    line-height: 1em;
    font-weight: 300;
    color: $labelColor;
    font-style: normal;
  }
}
