@import "../../../../../lib/scss/mixins";
@import "../../../../../lib/scss/variables";

.BulkDesksForm {
  &__button {
    place-content: end;
    gap: toRem(5px);
    cursor: pointer;
    margin-bottom: toRem(8px);

    span {
      color: #adaeb9;
      font-weight: 400;
      font-size: toRem(13px);
      line-height: toRem(16px);
    }

    .Icon svg {
      width: toRem(22px);
    }
  }

  .add-button-solid {
    circle {
      fill: #2cd0af;
    }
  }

  &__inner {
    border-radius: $radius;
    transition: $transition1;
    border: toRem(1px) solid $borderColor;
    padding: toRem(20px);
    overflow: unset;
    margin-top: toRem(10px);
    margin-bottom: toRem(22px);
  }

  &__creds {
    gap: toRem(16px);

    :last-child {
      flex: 1;
    }
  }
}
