@import "../../../../lib/scss/mixins";

.EditWorkplaceWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  &__route {
    display: flex;
    margin-top: toRem(15px);
    flex-direction: column;
    @include mobile-tablet {
      margin-top: toRem(10px);
    }
  }

  // &.EditWayFinder {
  //   @include mobile {
  //     .ResourceNavigation {
  //       &__link:nth-child(4) {
  //         padding-top: toRem(16px);
  //       }
  //     }
  //   }
  // }
}
