@import "../../../../lib/scss/mixins";

.PageSidebarHeader {
  display: flex;
  align-items: center;
  h3 {
    line-height: 1em;
    font-size: toRem(18px);
    margin-right: toRem(7px);
    @include mobile {
      font-size: toRem(16px);
    }
  }
  .AddWorkplaceButton {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .AddWorkplaceButton--open {
    opacity: 1;
  }
  &:hover {
    .AddWorkplaceButton {
      opacity: 1;
    }
  }
}
