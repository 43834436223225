@import "../../../lib/scss/mixins";

.Floors {
  .PageSidebar {
    // top: toRem(228px);
    top: calc(
      #{toRem(120px)} + (var(--providers-count) + var(--directory-count)) * 56px
    );

    @include small-desktop {
      top: calc(
        #{toRem(213px)} + (var(--providers-count) + var(--directory-count)) * 56px
      );
    }

    @include mobile-tablet {
      max-height: calc(100dvh - 4.5rem);
      top: calc(
        #{toRem(82px)} + (var(--providers-count) + var(--directory-count)) * 56px
      );
      z-index: 9999 !important;
    }
  }
  .PageSidebar__mobile_toggler {
    @include desktop {
      display: flex;
    }
  }

  .SidebarOverlay {
    @include desktop {
      display: unset;
    }
  }

  .FloorPickerButton {
    .floors-icon {
      position: unset;
    }
  }

  .UploadImage {
    .adjustable-button {
      @include mobile-tablet {
        .btn::before {
          filter: invert(1);
        }

        svg {
          display: none;
        }
      }
    }
  }
}
