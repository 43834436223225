@import "../../../lib/scss/mixins";
@import "../../../lib/scss/variables";

.DoubleButton {
  position: relative;

  &__secondButton {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #23a68c;
    border-top-right-radius: toRem(36px);
    border-bottom-right-radius: toRem(36px);
    width: toRem(38px);
    display: flex;
    place-content: center;
    place-items: center;

    svg {
      width: toRem(7px);
    }
  }

  .Button {
    min-width: toRem(180px);

    span {
      padding-right: toRem(35px);
    }

    @include mobile {
      min-width: toRem(155px);
    }
  }

  &.DropdownList {
    top: 0;
  }
}
